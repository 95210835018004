import React, { useState } from 'react'
import axiosClient from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import Modal from '../../Modal';
import { useEffect } from 'react';
import { useWorkOrdersContext } from '../../../hooks/useWorkOrdersContext';


const AddWorkOrder = ({ isOpen, setOpen, user }) => {
    const notifySuccess = () => toast.success("Work Order created");
    const {dispatch} =  useWorkOrdersContext();
    const [quantity, setQuantity] = useState("");
    const [product_id, setProductId] = useState("");
    const [wareHouse_id, setWareHouse] = useState("");
    // const [reference_number, setReferenceNumber] = useState("");
    const [wareHouses, setWareHouses] = useState(null);
    const [products, setProducts] = useState(null);
    const handleSubmit = (e) => {
        e.preventDefault();

        const workData = { 
            "quantity": quantity,
            "warehouse": wareHouse_id,
            "product": product_id,
            // "reference_number": reference_number
        }
       
        axiosClient.post("workorder", workData).then(
            async (response) => {
                if (response.status == 201) {
                    setOpen(false);
                    // dispatch({
                    //     type: "CREATE_WORK_ORDER",
                    //     payload: response.data
                    // })
                   
                    // setWorkOrder(response.data);

                    window.location.reload(false);
                    notifySuccess();
                } else Promise.reject();
                // window.location.reload(false);
            }
            
        ).catch((error) => {
            console.error("Order error", error);
        })

    }
    useEffect(() =>{

        const fetchWareHouses = async () => {
           await axiosClient.get('warehouse').then((response) => {
            if (response.status === 200) {
                setWareHouses(response.data)
            }
           }).catch(() => {
            toast.error("Ware Houses could not be fetched")
           })
        }

        const fetchProducts = async () => {
            await axiosClient.get('product').then((response) => {
                if(response.status === 200){
                    setProducts(response.data)
                }
            }).catch(() => {
                toast.error("Products not loading")
            });
        }

        if(user){
            fetchWareHouses();
            fetchProducts();
        }

    }, [user])
    return (
        <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead="Add Worker Order">
            <form onSubmit={e => handleSubmit(e)} >
                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label className="text-sm font-medium text-gray-900 block mb-2">Outlet</label>
                        <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                        </svg>
                        <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="wareHouse_id" value={wareHouse_id} onChange={(e) => setWareHouse(e.target.value)} >
                            <option>Select</option>
                            {
                               wareHouses?.map((warehouse_id, index) => (
                                    <option key={index} className="block w-full" value={warehouse_id?.id}>{warehouse_id?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                {/* product */}

                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                        </svg>
                        <label className="text-sm font-medium text-gray-900 block mb-2">Product</label>
                        <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="product_id" value={product_id} onChange={(e) => setProductId(e.target.value)} >
                            <option>Select</option>
                            {
                               products?.map((product_id, index) => (
                                    <option key={index} className="block w-full" value={product_id?.id}>{product_id?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label htmlFor="quantity" className="text-sm font-medium text-gray-900 block mb-2">Quantity</label>
                        <input type="number" id="quantity" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="Quantity" required name="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                    </div>
                </div>

                {/* <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label htmlFor="reference_number" className="text-sm font-medium text-gray-900 block mb-2">Reference Number</label>
                        <input type="text" id="reference_number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="reference_number" required name="reference_number" value={reference_number} onChange={(e) => setReferenceNumber(e.target.value)} />
                    </div>
                </div> */}

                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <button className="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button">
                            <p>Save</p></button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default AddWorkOrder