import Modal from "../Modal";
import { useState } from 'react';
import { toast } from 'react-toastify';
import axiosClient from "../../axios";
// import { useGiftCardContext } from "../../hooks/useGiftCardContext";
const AddGiftCard = ({ user, setOpen, isOpen }) => {
    const [value, setValue] = useState('');
    const [expiry_date, setExpiryDate] = useState('');
    // const { dispatch } = useGiftCardContext();
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            toast.error("you need to log in")
            return;
        }

        const data = {
            "value": value,
            "expiry_date": expiry_date
        }

        

        axiosClient.post("/gift-card/create", data).then((response) => {
         
            if (response.status === 201) {
                // setValue('');
                // setExpiryDate('');
                toast.success("Gift Card added");
                // dispatch({
                //     type: "CREATE_GIFTCARD",
                //     payload: response.data.data
                // })

                setOpen(false);
            }


        }).catch(() => {
            toast.error("Something wrong, please try in few mins");
        })
    }
    return (
        <>
            <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead="New Giftcard" >
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="text-sm font-medium text-gray-900 block mb-2">Amount</label>
                                <input onChange={(e) => setValue(e.target.value)} value={value} type="text" name="value" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required placeholder="50000" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="expiry_date" className="text-sm font-medium text-gray-900 block mb-2">Expiration</label>
                                <input onChange={(e) => setExpiryDate(e.target.value)} value={expiry_date} type="date" name="expiry_date" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                        </div>
                        <div className="items-center p-6 border-t border-gray-200 rounded-b">
                            <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                        </div>
                    </div>

                </form>
            </Modal>
        </>
    )
}

export default AddGiftCard