import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useMaterialContext } from '../../hooks/useMaterialContext'
import Modal from '../Modal';

const AddMaterial = ({ user, isOpen, setOpen }) => {
    const { dispatch } = useMaterialContext();
    const [inputs, setInputs] = useState(['']);

    const handleChange = (e, index) => {
        const newInputs = [...inputs];
        newInputs[index] = e.target.value;
        setInputs(newInputs);
    };

    const handleAddInput = () => {
        setInputs([...inputs, '']);
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };


    useEffect(() => {
        setInputs(['']);
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        // Prepare form data
        const formData = inputs.map(input => ({ name: input }));


        axiosClient.post("material", formData).then((response) => {
            if (response.status === 201) {
                setOpen(false)
                toast.success("Material created added")
                dispatch({
                    type: "CREATE_MATERIAL",
                    payload: response.data
                })
            }
        }).catch((error) => {
            toast.error("something wrong")
        })

    }


    return (
        <>
            <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead="Add Materials">
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        {inputs.map((input, index) => (
                            <div className="grid grid-cols-12 gap-6" key={index}>
                                <div className="col-span-8 sm:col-span-8">
                                    {/* <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2"></label> */}
                                    <input value={input.name}
                                        onChange={e => handleChange(e, index)} type="text" name="wareHouseName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                    {/* <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Warehouse</label> */}
                                    <input type="button" onClick={() => handleRemoveInput(index)} value="x" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" />
                                </div>
                            </div>
                        ))}
                        <div className="items-center p-6 border-t border-gray-200 rounded-b">
                            <button onClick={handleAddInput} className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="button">Add</button>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddMaterial