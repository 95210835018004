import { useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useWareHousesContext } from "../../hooks/useWareHouseContext";
const ListOfWareHouses = ({user, setModalContent, setOpen}) => {
    const { dispatch, wareHouses} =  useWareHousesContext();
    const handleDeleteClick = async (warehouse_id) => {
        await axiosClient.delete('warehouse/delete/'+warehouse_id).then((result) =>{
            if (result.status === 200) {
                toast.success("WareHouse Deleted");
                dispatch({
                    type: "DELETE_WARE_HOUSE",
                    payload: result.data,
                })
                
            }
        }).catch((error) => {
            toast.error("Something wrong");
        })
    }
    useEffect(() => {
        const fetchWareHouses = async () => {
            await axiosClient.get("warehouse").then(function (response) {
                
                if (response.status === 200) {
                    dispatch({
                        type: "SET_WARE_HOUSES",
                        payload: response.data
                    })
                }
            }).catch(() => { 
                    toast.error("something wrong")
            })
        }
        if(user){
            fetchWareHouses()
        }
    }, [dispatch, user])
    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Name
                                        </th>
                                        
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                       wareHouses && wareHouses?.map((warehouse, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {warehouse?.name}
                                                </td>
                                                
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <ul className="flex items-center justify-center">
                                                        {/* <li className='py-1 px-2.5' data-modal-toggle="user-modal" onClick={() => goToCustomer(cust)}><FaEye /></li> */}
                                                        <li className='py-1 px-2.5' onClick={() => { setOpen(true); setModalContent(warehouse) }}><FaEdit /></li>
                                                        <li className='py-1 px-2.5' onClick={() => handleDeleteClick(warehouse?.id)}><FaTrash /></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListOfWareHouses