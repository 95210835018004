import React from 'react'

const Header = ({category, title, buttonName, openModal}) => {
  return (
    <div className='mb-10'>
      <div className='flex items-start justify-between p-5'>
          <div>
          <p className='text-gray-400'>
        {category}
      </p>
      <p className='text-3xl font-extrabold tracking-tight text-slate-900'>{title}</p>
          </div>
          <div>
          <div className="items-center p-6  rounded-b">
            <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit" onClick={openModal}>{buttonName}</button>
          </div>
          </div>
      </div>
      
    </div>
   
  )
}

export default Header