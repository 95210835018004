import { FaSpinner } from "react-icons/fa"

const Spinner = () => {
  return (
    <div className="flex justify-center items-center h-96">
      <div className="text-gray-300 text-center text-5xl">
      
         Loading
      </div>
    </div>
  )
}

export default Spinner