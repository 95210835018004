import { useReducer } from "react";
import { createContext } from "react";

export const WareHousesContext = createContext();

export const WareHousesReducer = (state, action) => {
    switch (action.type) {
        case "SET_WARE_HOUSES":
            return {
                wareHouses: action.payload
            }
        case "CREATE_WARE_HOUSE":
            return {
                wareHouses: [action.payload, ...state.wareHouses]
            }
        case "DELETE_WARE_HOUSE":
            return {
                wareHouses: state.wareHouses.filter((wareHouse) => wareHouse.id !== action.payload.id)
            }
        case "UPDATE_WARE_HOUSE":
            const updatedWareHouse = action.payload;
            const wareHouses = state.wareHouses.map(wareHouse =>
                wareHouse.id === updatedWareHouse.id ? updatedWareHouse : wareHouse
            );
            return {
                wareHouses
            }
        default:
            return state
    }
}



export const WareHousesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(WareHousesReducer, {
        wareHouses: null
    });
    const contextValue = { wareHouses: state.wareHouses, dispatch };
    return (
        <WareHousesContext.Provider value={contextValue}>
            {children}
        </WareHousesContext.Provider>
    );
};
