import { useContext } from "react"
import { GiftCardContext } from "../contexts/GiftCard/GIftCardContext"

export const useGiftCardContext = () => {
    const context = useContext(GiftCardContext);
    if (!context) {
        throw Error("context must be used inside a ContextProvider")
    }

    return context
}