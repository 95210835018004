import { createContext, useContext, useEffect, useState } from "react";
import axiosClient from "../axios";

const WareHouseContext = createContext();

export const WareHouseProvider = ({children}) => {
    const [wareHouse, setWareHouse] = useState();

    const [wareHouseLoading, setWareHouseLoading] = useState(false);

    useEffect(() => {
        checkLogin();
    }, []);


    const checkLogin = async () =>{
        const token = localStorage.getItem("token");
        setWareHouseLoading(true);

        if(token){
            axiosClient.get('/warehouse').then((response) => {
                const wareHouseData = response.data;
                setWareHouse(wareHouseData);
                setWareHouseLoading(false);
            });
        }else{
            setWareHouse(null);
            setWareHouseLoading(false);
        }
    }

    return (
        <WareHouseContext.Provider value={{wareHouse, setWareHouse, wareHouseLoading, setWareHouseLoading}}>
            {children}
        </WareHouseContext.Provider>
    );
};

export const useWareHouseContext = () => useContext(WareHouseContext);