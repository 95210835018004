import { createContext, useContext, useEffect, useState } from "react";
import axiosClient from "../axios";

const PaymentAccountContext = createContext();

export const PaymentAccountProvider = ({children}) => {
    const [paymentAccount, setPaymentAccount] = useState();

    const [paymentAccountLoading, setPaymentAccountLoading] = useState(false);
    // const [paymentAccount] = use
    useEffect(() => {
        checkLogin();
    }, []);


    const checkLogin = async () =>{
        const token = localStorage.getItem("token");
        setPaymentAccountLoading(true);

        if(token){
            axiosClient.get('/payment-account').then((response) => {
                const paymentAccountData = response.data;
                setPaymentAccount(paymentAccountData);
                setPaymentAccountLoading(false);
            });
        }else{
            setPaymentAccount(null);
            setPaymentAccountLoading(false);
        }
    }

    return (
        <PaymentAccountContext.Provider value={{paymentAccount, setPaymentAccount, paymentAccountLoading, setPaymentAccountLoading}}>
            {children}
        </PaymentAccountContext.Provider>
    );
};

export const usePaymentAccountContext = () => useContext(PaymentAccountContext);