import { useNavigate } from "react-router-dom"
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { Header } from "../../components";
import { useOrderCategoriesContext } from "../../hooks/useOrderCategoriesContext";
import OrderCayegoriesList from "../../components/Orders/OrderCayegoriesList";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useState } from "react";
import EditOrderCategory from "../../components/Modals/Order/EditOrderCategory";
import AddOrderCategory from "../../components/Modals/Order/AddOrderCategory";

const OrderCategory = () => {
  const {user} = useAuthContext();
  const [isOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isOpenAddOrderCategory, setOpenAddOrderCategory] = useState(false);

  const openModal = () => {
    setOpenAddOrderCategory(true);
  }

  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Order Category" buttonName="New" openModal={openModal}/>
      <OrderCayegoriesList user={user} setOpen={setOpen} setModalContent={setModalContent}/>
      <AddOrderCategory isOpenAddOrderCategory={isOpenAddOrderCategory} setOpenAddOrderCategory={setOpenAddOrderCategory} user={user}/>
      <EditOrderCategory modalContent={modalContent} setOpen={setOpen} isOpen={isOpen} />
    </div>
  )
}

export default OrderCategory