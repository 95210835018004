import {  useEffect, useCallback } from 'react';
import { useVendorContext } from '../../hooks/useVendorsContext';
import axiosClient from '../../axios';
import Spinner from '../Spinner';
import AddVendorModal from './AddVendorModal';
import { toast } from 'react-toastify';

const VendorsList = ({ isModalOpen, setIsModalOpen }) => {

    const { vendors, dispatch } = useVendorContext();
    const closeModal = () => setIsModalOpen(false);
  
    const fetchVendors = useCallback(async () => {
        try {
            const response = await axiosClient.get(`expense/vendors`);
            if (response?.status === 200) {
                dispatch({
                    type: 'SET_VENDORS',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch vendors:', error);
        }
    }, [dispatch]);

    useEffect(() => {

        fetchVendors();
    }, [dispatch]);

    if (!vendors) {

        return <Spinner />;
    }

    const handleVendorsCreated = () => {
        toast.success("Vendors created!")
        fetchVendors();
        closeModal();
    };

    return (
        <div>
            <div className="flex flex-col mt-4">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">S/N</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Name</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Business Name</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Email</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Phone</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Address</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {vendors?.map((vendor, index) => (
                                        <tr className="border-b" key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{vendor?.name}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{vendor.business_name}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{vendor.email}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{vendor.phone}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{vendor.address}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <AddVendorModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                onVendorsCreated={handleVendorsCreated}
            />
        </div>
    )
}

export default VendorsList;