import { useRef } from "react";
import axiosClient from "../../../axios";
import Modal from "../../Modal";
import { toast } from "react-toastify";

const EditCuttingTicket = ({ modalContent, setOpenEdit, openEdit}) => {
  // const refOrder = useRef(null);
  const refCompleteDate = useRef(null);
  const refStartDate = useRef(null);
  // const refPurpose = useRef(null);
  // const refQuantity = useRef(null);
  // const refUserId = useRef(null);

  const handleSubmit = async (e, cutting_ticket_id) => {
    e.preventDefault();
    const cuttingTicketData = {
      "start_date": refStartDate.current.value,
      "complete_date": refCompleteDate.current.value,
      // "order":refOrder.current.value,
      // "purpose": refPurpose.current.value,
      // "quantity": refQuantity.current.value,
      // "user_id": refUserId.current.value,
    }

    await axiosClient.put(`cutting-ticket/${cutting_ticket_id}`,cuttingTicketData).then( async(response) => {
        if(response.status === 200){
          setOpenEdit(false);
          // console.log("RESD", response.data);
          toast.success("Cutting Ticket Updated")
        }
    }).catch((error) => {
      console.error("Order error", error);
    })
  }
  return (
    <>
      <Modal isOpen={openEdit} close={() => setOpenEdit(false)} modalhead={modalContent?.reference_number}>
      <form onSubmit={e => handleSubmit(e, modalContent?.id)} >
          <div className="p-6 space-y-6">

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="first-name" className="text-sm font-medium text-gray-900 block mb-2">Start Date</label>
                {/* <input type="text" name="first_name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.first_name} onChange={(e) => setfirstName(e.target.value)} /> */}
                <input type="date" name="start_date"  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refStartDate} defaultValue={modalContent?.start_date} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="complete_date" className="text-sm font-medium text-gray-900 block mb-2">Complete Date</label>
                {/* <input type="text" name="last_name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.last_name} onChange={(e) => setLastName(e.target.value)} /> */}
                <input type="date" name="complete_date"  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refCompleteDate} defaultValue={modalContent?.complete_date} />
              </div>
              {/* <div className="col-span-6 sm:col-span-3">
                <label htmlFor="order" className="text-sm font-medium text-gray-900 block mb-2">Email</label>
                <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input"  ref={refOrder} defaultValue={modalContent?.order?.id}   >
                            <option>Select</option>
                            {
                                orders?.map((orde, index) => (
                                    <option key={index} className="block w-full" value={orde?.id}>{orde?.reference_number}</option>
                                ))
                            }
                        </select>
                
              </div> */}
              {/* <div className="col-span-6 sm:col-span-3">
                <label htmlFor="phone-number" className="text-sm font-medium text-gray-900 block mb-2">Phone Number</label>
               <input type="number" name="mobile_number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" ref={refPhone} defaultValue={modalContent?.mobile_number} required />
              </div> */}

            </div>
          </div>
          <div className="items-center p-6 border-t border-gray-200 rounded-b">
            <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save all</button>
          </div>
        </form>
      </Modal>

    </>
  )
}

export default EditCuttingTicket