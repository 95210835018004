import { useContext } from "react"
import { toast } from "react-toastify";
import { FinishedProductsContext } from "../contexts/Accounts/Stocks/FinishedProductContext";

export const useFinishedProductContext = () => {
    const context = useContext(FinishedProductsContext);

    if (!context) {
        // throw Error("useBanksContext must be used inside a BanksContextProvider")
        toast.error("UseFinishedProductContext must be used");
    }

    return context;
}