import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axiosClient from '../../../axios';
import { useWareHousesContext } from '../../../hooks/useWareHouseContext'
import Modal from '../../Modal'

const AddWareHouse = ({ user, isOpenAddWareHouse, setOpenAddWareHouse, }) => {
    const { dispatch } = useWareHousesContext();
    const [wareHouseName, setWareHouseName] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            toast.error("you must log in");
            return;
        }

        const data = {
            "name": wareHouseName
        }



        axiosClient.post("/warehouse", data).then((response) => {
            if (response.status === 201) {
                setWareHouseName("");
                toast.success("Warehouse added")
                dispatch({
                    type: "CREATE_WARE_HOUSE",
                    payload: response.data
                })
                setOpenAddWareHouse(false);
            }
        }).catch((err) => {
            toast.error("Something wrong, please try in few mins")
        });
    }
    return (
        <>
            <Modal isOpen={isOpenAddWareHouse} close={() => setOpenAddWareHouse(false)} modalhead="New WareHouse" >
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Warehouse</label>
                                <input onChange={(e) => setWareHouseName(e.target.value)} value={wareHouseName} type="text" name="wareHouseName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddWareHouse