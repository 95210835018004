import { useContext } from "react"
import { WareHousesContext } from "../contexts/WareHouse/WareHousesContext"

export const useWareHousesContext = () => {
    const context = useContext(WareHousesContext);
     if (!context) {
        throw Error("useWareHousesContext must be used inside a WareHousesContextProvider")
    }

    return context;
}