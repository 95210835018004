import { useContext } from "react"
import { toast } from "react-toastify";
import { RoleContext } from "../contexts/Role/RoleContext"

export const useRoleContext = () => {
    const context = useContext(RoleContext);

    if (!context) {
        toast.error("UseRoleContext must be used");
    }

    return context;
}