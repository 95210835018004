import { useState, useEffect } from 'react';
import  { FaSignInAlt } from "react-icons/fa";
import { useSignIn} from "../../hooks/useSignIn.js";
import { ToastContainer, toast } from 'react-toastify';
// import { useSelector, useDispatch} from "react-redux";
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { login, reset } from "../../features/auth/authSlice";
// import Spinner from '../../components/Spinner';

import axiosClient from '../../axios';
import { useStateContext } from '../../contexts/ContextProvider';


const Login = () => { 
  const { setCurrentUser } = useStateContext();
  const {signIn, error, isLoading} = useSignIn();
  const [formData, setFormData] = useState({
    isSubmitting: false,
    email: '',
    password: '',
  });
  const { isSubmitting, email, password} = formData;
  const notifyError = () => toast.error(error);
  const handleError = (error) => {
    alert(error);
    setFormData({
      ...formData,
      isSubmitting: false
    });
  };

  if(error){
    notifyError()
  }
  const handleRegistration = async (ev) =>{
    ev.preventDefault();

    if(isValid){
      setFormData({...formData, isSubmitting: true});
      // const params = {
      const  params =  {
          email,
          password
        };
      // };

      await signIn(params.email, params.password);
     
    }else {
      handleError("Please fill out all fields");
      setFormData({ ...formData });
    }
  };
  const isValid = email && password;
  
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            {/* <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            /> */}
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in</h2>
            
          </div>
          {/* <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={handleRegistration}> */}
          <form onSubmit={handleRegistration}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
             

              <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit" disabled={isLoading}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <FaSignInAlt className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                {isLoading ? "Loading..." : "Login"}
              </button>
              {/* {error && <div className="font-medium text-red-600">{error}</div>} */}
            
              {/* <ToastContainer /> */}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login