import { useState } from "react"
import axiosClient from "../axios";
// import { AuthContext } from "../contexts/Auth/AuthContext"
import { useAuthContext } from "./useAuthContext";


export const useSignIn = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext();

    const signIn = async (email, password) => {
        setIsLoading(true);
        setError(null);


        const response = await  axiosClient.post("login", {
            "email": email,
            "password": password
        }).then((response) => {
            // console.log("SUCCESS_GOOD", response.data);
            const { user, token} = response.data;
            if(response.status == 200){
                // save the user to local Storage
                localStorage.setItem('user',JSON.stringify(response.data));

                // update Auth Context
                dispatch({type: "LOGIN", payload: response.data})
                setIsLoading(false);


            }
        }).catch(function (error){
            setIsLoading(false);
            setError(error?.response?.data?.message);
            console.log("ERROR_BIG",error);
        })

       
    }
    return  { signIn, isLoading, error}
}