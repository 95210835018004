import React, { useEffect } from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosClient from '../../axios';
import OneCustomerList from '../../components/Customer/OneCustomerList';
import CustomerOrderHistory from '../../components/Customer/CustomerOrderHistory';
const ViewCustomer = () => {
    const params = useParams();
    const [customerDetails, setCustomerDetails] = useState(null);
    // const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axiosClient.get(`customer/${params.id}`)
            .then((response) => setCustomerDetails(response.data))
            .catch((error) => console.error(error.message))
            .finally(() => setLoaded(true));
    }, [])


    return (
        <div className='m-2 md:m-10 p-2 md:p-10  rounded-3xl'>
            {/* <div>ViewCustomer</div>
            <h2> {params.id}</h2> */}
            <div className="grid grid-cols-3 gap-6">
                <div className="bg-white shadow-md   p-2 md:p-10  rounded-2xl">
                    <div className="lg:col-span-2  lg:border-gray-200 lg:pr-8">
                        <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{customerDetails?.first_name + " " + customerDetails?.last_name}</h1>
                        <div className='border-t border-gray-200 pt-4'>
                            <p className='font-medium text-gray-900'>Email Address</p>
                            <p className='mt-2 text-sm text-gray-500'>{customerDetails?.email}</p>
                        </div>
                        <div className='border-t border-gray-200 pt-4'>
                            <p className='font-medium text-gray-900'>Home Address</p>
                            <p className='mt-2 text-sm text-gray-500'>{customerDetails?.address?.street + " " + customerDetails?.address?.local_government + " " + customerDetails?.address?.city + " " + customerDetails?.address?.state + " " + customerDetails?.address?.country}</p>
                        </div>
                        <div className='border-t border-gray-200 pt-4'>
                            <p className='font-medium text-gray-900'>Phone Number</p>
                            <p className='mt-2 text-sm text-gray-500'>{customerDetails?.mobile_number}</p>
                        </div>
                    </div>

                </div>
                <div className="bg-white shadow-md   p-2 md:p-10  rounded-2xl col-span-2 overflow-hidden">
                    {/* <OneCustomerList  customerDetails={customerDetails}/> */}

                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <CustomerOrderHistory orders={customerDetails} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCustomer