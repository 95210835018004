
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useWebsiteOrdersContext } from '../../hooks/useWebsiteOrdersContext';
import { useEffect } from 'react';
import MoneyFormat from '../Utils/MoneyFormat';
import DateFormat from '../Utils/DateFormat';
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
const ListOfWebsiteOrders = () => {
    let navigate = useNavigate();

    const { dispatch, websiteOrders } = useWebsiteOrdersContext();
    useEffect(() => {
        const fetchWebsiteOrders = async () => {
            await axiosClient.get("/fh/website-orders").then((response) => {


                if (response.status === 200) {
                    dispatch({
                        type: "SET_WEBSITE_ORDERS",
                        payload: response.data.results
                    })
                }
            }).catch(() => {
                toast.error("website orders not loading...")
            })
        }

        fetchWebsiteOrders();


    }, [dispatch]);

    const goToWebsiteOrderDetails = (id) => {
        id ? navigate(`/order/website/${id}`) : toast.error("No Id provided");
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Name
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Product
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Status
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Date
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Action
                                        </th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {websiteOrders?.map((web_order, index) => {
                                        const { id, customer_name, status, currency, web_orders, createdAt } = web_order || {};
                                        return (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {customer_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {web_orders?.map((product, i) => (
                                                        <div key={i}>
                                                            <p>{product.product_name} </p>
                                                            <p><MoneyFormat amount={product.amount} currency={currency} /></p>
                                                            <p>{product.product_code} </p>
                                                        </div>

                                                    ))}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{status} </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"><DateFormat date={createdAt} /> </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <ul className="flex items-center justify-center">
                                                        <li className='py-1 px-2.5' onClick={() => goToWebsiteOrderDetails(id)}><FaEye /></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListOfWebsiteOrders