import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useWorkOrderContext } from '../../../contexts/WorkOrder/WorkOrderProvider';
import { Header } from '../../../components';
import AddWorkOrder from '../../../components/Modals/WorkOrder/AddWorkOrder';
// import { useWareHouseContext } from '../../../contexts/WareHouseProvider';
// import { useProductContext } from '../../../contexts/ProductProvider';
import EditWorkOrder from '../../../components/Modals/WorkOrder/EditWorkOrder';
import WorkOrderList from '../../../components/WorkOrders/WorkOrderList';
import { useAuthContext } from '../../../hooks/useAuthContext';
const WorkOrderIndex = () => {
  let navigate = useNavigate();
  const [isOpenWorkorder, setOpenWorkorder] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const {user} = useAuthContext();
 
  const [modalContent, setModalContent] = useState('');
  const goToWorkOrderDetails =  (workorderId) => {
    navigate(`/workorder/${workorderId}`);
  }

  // const {wareHouse, wareHouseLoading} = useWareHouseContext();
  // const { product} = useProductContext();
  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
  }

  // const { workOrder, setWorkOrder } = useWorkOrderContext();
  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Work Order"  buttonName="Add Work Order" openModal={  openModal }/>
      <WorkOrderList goToWorkOrderDetails={goToWorkOrderDetails}  setModalContent={setModalContent} setOpenWorkorder={setOpenWorkorder} user={user}/>
      <AddWorkOrder isOpen={isOpen} setOpen={closeModal}  user={user} />
      {/* 
     
      <EditWorkOrder modalContent={modalContent}  isOpenWorkorder={isOpenWorkorder}  setOpenWorkorder={setOpenWorkorder} warehouseId={wareHouse} productId={product}/> */}
    </div>
  )
}

export default WorkOrderIndex