import { createContext, useReducer } from "react";


export const VendorsContext = createContext();
export const VendorsReducer = (state, action) => {
    switch (action.type) {
        case "SET_VENDORS":
            return {
                vendors: action.payload
            }

            break;

        default:

            return state;
            break;
    }
}

export const VendorsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(VendorsReducer, {
        vendors: []
    })

    return (
        <VendorsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </VendorsContext.Provider>
    )
}