import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import axiosClient from '../../axios';
import { useMaterialContext } from '../../hooks/useMaterialContext';
import AddProductMaterial from '../Materials/AddProductMaterial';

const ProductMaterials = ({ productMaterials, productId, showProduct }) => {
    const [isOpen, setOpen] = useState(false);
    const { materials, dispatch: setMaterialDispatch } = useMaterialContext();
    const openAddMaterial = () => {
        setOpen(true);
    }

    useEffect(() => {
        const listMaterials = async () => {
            await axiosClient.get("/material").then((response) => {
                if (response.status === 200) {
                    setMaterialDispatch({
                        type: "SET_MATERIAL",
                        payload: response.data
                    })
                }
            })
        }
        listMaterials();

    },
        [setMaterialDispatch],
    )

    return (
        <>
            <button onClick={openAddMaterial}
                className="
						focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800
						text-base
						flex
						items-center
						justify-center
						leading-none
						text-white
						bg-gray-800
					
						py-4
						hover:bg-gray-700
					"
            >

                ADD MATERIAL
            </button>
            <div className="overflow-hidden">
                <table className="min-w-full">
                    <thead className="border-b">
                        <tr>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                s/n
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                Name
                            </th>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                Quantity
                            </th>


                        </tr>
                    </thead>
                    <tbody>
                        {
                            productMaterials?.map((pm, index) => (
                                <tr className="border-b" key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>

                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {pm?.material?.name}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {pm?.quantity}
                                    </td>


                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <AddProductMaterial isOpen={isOpen} setOpen={setOpen} materials={materials} productId={productId} showProduct={showProduct} />
        </>
    )
}

export default ProductMaterials