import React from 'react'
import { Header } from '../../components'
import PaymentList from '../../components/Accounts/PaymentList'

const Payments = () => {
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="Payments" />
            <PaymentList />
        </div>
    )
}

export default Payments