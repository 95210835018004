import { useContext } from "react"
import { toast } from "react-toastify";
import { StaffContext } from "../contexts/Staff/StaffContext"

export const useStaffContext = () => {
    const context = useContext(StaffContext);

    if (!context) {
        // throw Error("useBanksContext must be used inside a BanksContextProvider")
        toast.error("UseStaffContext must be used");
    }

    return context;
}