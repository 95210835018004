import { toast } from "react-toastify";
import axiosClient from "../../axios";
import Modal from "../../components/Modal"


const ChangeCuttingTicketStatus = ({ isOpen, setOpen, options, statusCtk, setStatusCtk, formData, ctkId, fetchCuttingTicket }) => {
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            "work_order_id": formData.work_order_id,
            "product_id": formData.product_id,
            "quantity": formData.quantity,
            "cutting_ticket_status": statusCtk
        }

        axiosClient.post(`/stock/${ctkId.id}`, data).then((response) => {
            if (response.status === 200) {
                console.log("RESPONSE----", response);
                fetchCuttingTicket();
                toast.success("status changed")
                setOpen(false);

            }
        }).catch((error) => {
            // console.log("ERRROR-----", error?.response?.data?.message)
            toast.error(error?.response?.data?.message)
        })
    }
    const handleChange = (e) => {
        setStatusCtk(e.target.value);
    }

    return (
        <>
            <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead="Change Status">

                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <select className="pl-6 py-2 bg-white outline-none" aria-label="New select example" value={statusCtk} onChange={handleChange} >

                                    {options.map((optionz) => (

                                        <option value={optionz.value} key={optionz.label}>{optionz.label}</option>

                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default ChangeCuttingTicketStatus