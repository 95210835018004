import { useState } from "react";
import { Header } from "../../components"
import { useCustomerContext } from "../../contexts/CustomerProvider";
import { useOrderCategoryContext } from "../../contexts/OrderCategoryProvider";
import { useProductContext } from "../../contexts/ProductProvider";
import axiosClient from "../../axios";
import { useOrderContext } from "../../contexts/OrderProvider";

const Invoice = () => {
    // 
    const [mobile_number, setMobileNumber] = useState("");
    const [customer_id, setCustomerId] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [currency, setCurrency] = useState("NGN");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [local_government, setLocalGovernment] = useState("");
    const [street, setStreet] = useState("");
    const [postal_code, setPostalCode] = useState("");
    const [order_category_id, setCategory] = useState("");



    // 
    const [orderItems, setorderItems] = useState([
        { product: "", quantity: "" }
    ]);
    const { customer, customerLoading } = useCustomerContext();
    const { product } = useProductContext();
    const { orderrCategory, setOrderCategory } = useOrderCategoryContext();
    const {order, setOrder} = useOrderContext();

    const handleAddFields = () => {
        setorderItems([...orderItems, { product: "", quantity: "" }]);
    }

    const handleRemoveFields = (index) => {
        const values = [...orderItems];
        values.splice(index, 1);
        setorderItems(values);
    }

    const handleChangeInput = (index, event) => {
        const values = [...orderItems];
        values[index][event.target.name] = event.target.value;
        setorderItems(values);
        console.log("values", values);
    }

    // const handleError = (error) => {
    //     alert(error);
    //     setFormData({
    //       ...formData,
    //       isSubmitting: false
    //     });
    //   };

    const handleSubmit = (e) => {
        e.preventDefault();
        const orderData = {
            "orderItems": orderItems.map(element => ({ quantity: element.quantity, product_id: element.product })),
            "mobile_number": mobile_number,
            "orderDate": orderDate,
            "deliveryDate": deliveryDate,
            "customer_id": customer_id,
            "order_category_id": order_category_id,
            "currency": currency,
            "shipping": {
                "street": street,
                "city": city,
                "local_government": local_government,
                "state": state,
                "country": country,
                "postal_code": postal_code
            }
        }
        console.log("orderdatas:", orderData);

        axiosClient.post("order", orderData).then(
            async(response) => {
                setOrder(response.data);
            }
        ).catch((error) => {
            // handleError("An error occurred");
            console.error("Order error", error);
        })


    }

    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="Invoice" />
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            {/* start */}
                            <form onSubmit={handleSubmit}>
                                <section className="bg-coolGray-50 py-4">
                                    <div className="container px-4 mx-auto">
                                        <div className="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
                                            <div className="pb-6 border-b border-coolGray-100">
                                                <div className="flex flex-wrap items-center justify-between -m-2">
                                                    <div className="w-full md:w-auto p-2">
                                                        <h2 className="text-coolGray-900 text-lg font-semibold">Invoice Order</h2>
                                                        <p className="text-xs text-coolGray-500 font-medium">Lorem ipsum dolor sit amet</p>
                                                    </div>
                                                    <div className="w-full md:w-auto p-2">
                                                        <div className="flex flex-wrap justify-between -m-1.5">
                                                            <div className="w-full md:w-auto p-1.5">
                                                                <button className="flex flex-wrap justify-center w-full px-4 py-2 font-medium text-sm text-coolGray-500 hover:text-coolGray-600 border border-coolGray-200 hover:border-coolGray-300 bg-white rounded-md shadow-button" type="button">
                                                                    <p>Cancel</p>
                                                                </button>
                                                            </div>
                                                            <div className="w-full md:w-auto p-1.5">
                                                                <button className="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button">
                                                                    <p>Save</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    <div className="flex flex-wrap -m-3">
                                                        <div className="w-full md:w-1/3 p-3">
                                                            <p className="text-sm text-coolGray-800 font-semibold">Customer</p>
                                                        </div>
                                                        {/* <div className="w-full md:w-1/3 p-3">
                                                        <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input" type="text" placeholder="John" />
                                                    </div> */}
                                                        <div className="w-full md:w-1/3 p-3">
                                                            <div className="relative">
                                                                <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                                                                </svg>
                                                                <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="customer_id" value={customer_id}  onChange={(e) => setCustomerId(e.target.value)} >
                                                                    {/* <option>United States</option>
                                                                <option>Poland</option>
                                                                <option>France</option> */}
                                                                    {
                                                                        customer.map((cust) => (
                                                                            <option value={cust?.id}>{cust?.first_name + " " + cust?.last_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:w-1/3 p-3">
                                                            <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input" type="text" placeholder="Mobile Number" name="mobile_number" value={mobile_number} onChange={(e) => setMobileNumber(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    <div className="flex flex-wrap -m-3">
                                                        <div className="w-full md:w-1/3 p-3">
                                                            <p className="text-sm text-coolGray-800 font-semibold">Order Date</p>
                                                        </div>
                                                        <div className="w-full md:flex-1 p-3">
                                                            <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input" type="date" name="orderDate" value={orderDate} onChange={(e) => setOrderDate(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    <div className="flex flex-wrap -m-3">
                                                        <div className="w-full md:w-1/3 p-3">
                                                            <p className="text-sm text-coolGray-800 font-semibold">Delivery Date</p>
                                                        </div>
                                                        <div className="w-full md:flex-1 p-3">
                                                            <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input" type="date" name="deliveryDate" value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    <div className="flex flex-wrap -m-3">
                                                        <div className="w-full md:w-1/3 p-3">
                                                            <p className="text-sm text-coolGray-800 font-semibold">Category</p>
                                                        </div>
                                                        <div className="w-full md:flex-1 p-3">
                                                            <div className="relative">
                                                                <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                                                                </svg>
                                                                <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="order_category_id" value={order_category_id} onChange={(e) => setCategory(e.target.value)}>
                                                                    {
                                                                        orderrCategory.map((orderCat) => (
                                                                            <option selected value={orderCat.id} >{orderCat.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    <div className="flex flex-wrap -m-3">
                                                        <div className="w-full md:w-1/3 p-3">
                                                            <p className="text-sm text-coolGray-800 font-semibold">Currency</p>
                                                        </div>
                                                        <div className="w-full md:flex-1 p-3">
                                                            <div className="relative">
                                                                <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                                                                </svg>
                                                                <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="currency" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                                                    <option value="NGN">Naira</option>
                                                                    <option value="USD">Dollar</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    <div className="flex flex-wrap -m-3">
                                                        {/* <div className="w-full md:w-1/3 p-3">
                                                        <p className="text-sm text-coolGray-800 font-semibold">Street</p>
                                                    </div> */}
                                                        <div className="w-full md:flex-1 p-3">
                                                            <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                {/* <p className="px-4 text-base text-coolGray-500 font-normal">https://</p> */}
                                                                <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="text" placeholder="Street" name="street" value={street} onChange={(e) => setStreet(e.target.value)} />
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:flex-1 p-3">
                                                            <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                {/* <p className="px-4 text-base text-coolGray-500 font-normal">https://</p> */}
                                                                <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="text" placeholder="local government" name="local_government" value={local_government} onChange={(e) => setLocalGovernment(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    <div className="flex flex-wrap -m-3">
                                                        {/* <div className="w-full md:w-1/3 p-3">
                                                        <p className="text-sm text-coolGray-800 font-semibold">Domain</p>
                                                    </div> */}
                                                        <div className="w-full md:flex-1 p-3">

                                                            {/* <p>gr</p> */}
                                                            <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                {/* <p className="px-4 text-base text-coolGray-500 font-normal">flex.co/</p> */}
                                                                <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="text" placeholder="State" name="state" value={state} onChange={(e) => setState(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:flex-1 p-3">
                                                            <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                {/* <p className="px-4 text-base text-coolGray-500 font-normal">flex.co/</p> */}
                                                                <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="text" placeholder="Country" name="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:flex-1 p-3">

                                                            {/* <p>gr</p> */}
                                                            <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                {/* <p className="px-4 text-base text-coolGray-500 font-normal">flex.co/</p> */}
                                                                <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="text" placeholder="City" name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:flex-1 p-3">
                                                            <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                {/* <p className="px-4 text-base text-coolGray-500 font-normal">flex.co/</p> */}
                                                                <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="text" placeholder="Postal Code" name="postal_code" value={postal_code} onChange={(e) => setPostalCode(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-6 border-b border-coolGray-100">
                                                <div className="w-full md:w-9/12">
                                                    {
                                                        orderItems.map((orderItem, index) => (

                                                            <div className="flex flex-wrap -m-3" key={index}>
                                                                <div className="w-full md:flex-1 p-3">
                                                                    <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                        {/* <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="text" placeholder="Product" name="product" /> */}
                                                                        <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" onChange={event => handleChangeInput(index, event)} name="product" value={orderItem.product}>
                                                                            <option>Choose a bag</option>
                                                                            {
                                                                                product.map((prod) => (
                                                                                    <option value={prod?.id}>{prod?.name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full md:flex-1 p-3">
                                                                    <div className="flex items-center focus-within:border-green-500 overflow-hidden border border-coolGray-200 rounded-lg shadow-input">
                                                                        <input className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none border-l" type="number" placeholder="Quantity" name="quantity" value={orderItem.quantity} onChange={event => handleChangeInput(index, event)} />
                                                                    </div>
                                                                </div>
                                                                <div className="w-full md:w-auto p-3">
                                                                    {(orderItems.length !== 1) ? <button onClick={handleRemoveFields} type="button" className="flex flex-wrap justify-center w-full px-4 py-2 bg-red-500 hover:bg-red-600 font-medium text-sm text-white border border-red-500 rounded-md shadow-button">
                                                                        <p>Remove</p>
                                                                    </button> : ''}
                                                                </div>
                                                            </div>

                                                        ))
                                                    }
                                                    <div className="w-full md:w-auto p-1.5">
                                                        <button onClick={() => handleAddFields()} type="button" className="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button">
                                                            <p>Add New</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </section>
                            </form>

                            {/* end */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice