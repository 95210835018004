import { useReducer } from "react";
import { createContext } from "react";

export const WorkOrdersContext = createContext();

export const WorkOrdersReducer = (state, action) => {
    switch (action.type) {
        case "SET_WORK_ORDERS":
            return {
                workOrders: action.payload
            }
        case "CREATE_WORK_ORDER" :
            return {
                workOrders: [action.payload, ...state.workOrders]
            }
        case "DELETE_WORK_ORDER":
            return {
                workOrders: state.workOrders.filter((workOrder) => workOrder.id !== action.payload.id)
            }
        default:
            return state
    }
}

export const WorkOrdersContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(WorkOrdersReducer, {
        workOrders: null
    })

    return (
        <WorkOrdersContext.Provider value={{...state, dispatch}}>
            {children}
        </WorkOrdersContext.Provider>
    )
}