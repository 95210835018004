import { useState } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../../axios";
import { useOrderCategoriesContext } from "../../../hooks/useOrderCategoriesContext";
import Modal from "../../Modal"

const AddOrderCategory = ({ user, isOpenAddOrderCategory, setOpenAddOrderCategory }) => {

    const [orderCategoryName, setOrderCategoryName] = useState("");
    const [code, setCode] = useState("");
    const { dispatch } = useOrderCategoriesContext();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            toast.error("you must be logged in ")
            return
        }

        const orderCategory = {
            "name": orderCategoryName,
            "code": code
        }
        axiosClient.post('order-category', orderCategory).then((response) => {
            if (response.status) {
                toast.success("Order Category Saved")
                dispatch({
                    type: "CREATE_ORDER_CATEGORY",
                    payload: response.data
                })

            }
        }).catch(() => {
            toast.error("there is a problem, try later");
        }).finally(() => {
            setOpenAddOrderCategory(false)
            setOrderCategoryName('');
            setCode('');
        }

        )

    }
    return (
        <>
            <Modal isOpen={isOpenAddOrderCategory} close={() => setOpenAddOrderCategory(false)} modalhead="New Order Category" >
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="text-sm font-medium text-gray-900 block mb-2">Order Category Name</label>
                                <input onChange={(e) => setOrderCategoryName(e.target.value)} value={orderCategoryName} type="text" name="orderCategoryName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="text-sm font-medium text-gray-900 block mb-2">Code</label>
                                <input onChange={(e) => setCode(e.target.value)} value={code} type="text" name="code" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddOrderCategory