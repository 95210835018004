import { useContext } from "react";

import { WorkOrdersContext} from "../contexts/WorkOrder/WorkOrdersContext"
export const useWorkOrdersContext = () => {
    const context = useContext(WorkOrdersContext)

    if (!context) {
        throw Error("useWorkOrdersContext must be used inside a WorkOrdersContextProvider")
    }

    return context
}