import React from 'react'
import { Header } from '../../components'
import StockList from '../../components/Stock/StockList'

const Stock = () => {
  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header title="Stock"  buttonName="FH"/>
        <StockList />
    </div>
  )
}

export default Stock