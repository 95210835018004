import React, { useCallback, useState, useEffect } from 'react';
import axiosClient from '../../axios';
import { toast } from 'react-toastify';
import { useFinishedProductContext } from '../../hooks/useFinishedProductsContext';
import { useNavigate } from 'react-router-dom';

const POS = () => {
  const { finished_Products, dispatch } = useFinishedProductContext();
  const [banks, setBanks] = useState([]);
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    selectedBank: '',
    mode: '',
    currency: 'NGN',
    discount: 0,
  });
  const [selectedProduct, setSelectedProduct] = useState('');
  const [lineItems, setLineItems] = useState([]);
  const [barcodeInput, setBarcodeInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();



  const fetchBanks = async () => {
    try {
      const response = await axiosClient.get('/payment-account');
      if (response.status === 200) {
        setBanks(response.data);
      }
    } catch {
      toast.error('Something went wrong while fetching banks.');
    }
  };

  const fetchProducts = useCallback(async () => {
    try {
      const { data, status } = await axiosClient.get('/finished-products/unpaginated/index');
      if (status === 200) {
        dispatch({
          type: 'SET_FINISHED_PRODUCTS_UNPAGINATED',
          payload: data,
        });
      }
    } catch (error) {
      toast.error('Sorry, could not load products');
    }
  }, [dispatch]);

  useEffect(() => {
    fetchProducts();
    fetchBanks();
  }, [fetchProducts]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  useEffect(() => {
    const handleBarcodeInput = (event) => {
      if (event.key === 'Enter') {
        const product = finished_Products.find((p) => p.code === barcodeInput.trim());
        if (product) {
          setSelectedProduct(product);
          setIsModalOpen(true); // Open modal with product details
        } else {
          toast.error('Product not found!');
        }
        setBarcodeInput(''); // Clear barcode input after scanning
      }
    };

    window.addEventListener('keydown', handleBarcodeInput);

    return () => window.removeEventListener('keydown', handleBarcodeInput);
  }, [barcodeInput, finished_Products]);




  const addLineItem = () => {
    if (!selectedProduct) {
      toast.error('Please select a product.');
      return;
    }

    const existingItem = lineItems.find((item) => item.code === selectedProduct.code);
    if (existingItem) {
      toast.error('Product already added. Adjust quantity if needed.');
      return;
    }

    const price = formData.currency === 'NGN' ? selectedProduct.item.price_ngn : selectedProduct.item.price_usd;
    const newLineItem = {
      name: selectedProduct.item.name,
      price,
      quantity: 1,
      code: selectedProduct.code,
      id: selectedProduct.id,
    };

    setLineItems([...lineItems, newLineItem]);
    setIsModalOpen(false); // Close modal after adding the product
    setSelectedProduct(''); // Reset product selection
  };


  const updateLineItemQuantity = (code, quantity) => {
    setLineItems((prevItems) =>
      prevItems.map((item) => (item.code === code ? { ...item, quantity } : item))
    );
  };

  const removeLineItem = (code) => {
    setLineItems((prevItems) => prevItems.filter((item) => item.code !== code));
  };

  const submitOrder = async () => {
    if (!formData.userName || !formData.email || !formData.selectedBank || !formData.mode) {
      toast.error('Please fill all required fields.');
      return;
    }

    if (lineItems.length === 0) {
      toast.error('Please add at least one product.');
      return;
    }

    try {
      const posItems = lineItems.map(({ price, id, quantity }) => ({ price, id, quantity }));
      const data = {
        pos_items: posItems,
        customer_name: formData.userName,
        customer_email: formData.email,
        mode_of_payment: formData.mode,
        currency: formData.currency,
        payment_account_id: formData.selectedBank,
        discount: formData.discount,
      };

      const response = await axiosClient.post('pos', data);
      if (response.status === 201) {
        toast.success('Order submitted successfully');
        setLineItems([]);
        setFormData({
          userName: '',
          email: '',
          selectedBank: '',
          mode: '',
          currency: 'NGN',
          discount: 0,
        });
        fetchProducts();
        navigate(`/customer-reciept/${response.data.data}`);
      }
    } catch (error) {
      toast.error('Sorry, could not submit order');
    }
  };

  const calculateTotal = () => {
    const subtotal = lineItems.reduce((acc, { price, quantity }) => acc + price * quantity, 0);
    const discountAmount = (formData.discount / 100) * subtotal;
    return subtotal - discountAmount;
  };

  return (
    <div className="m-2 md:m-10 p-4 md:p-10 bg-white rounded-3xl">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center">Sales Interface</h1>
        {/* Rest of your code for input fields, product selection, and line items */}

        <div className="flex flex-wrap mb-6">
          <div className="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
            <label htmlFor="userName" className="block mb-2 font-bold text-gray-700">
              Customer Name
            </label>
            <input
              id="userName"
              name="userName"
              type="text"
              className="w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={formData.userName}
              onChange={handleInputChange}
              placeholder="Enter customer name"
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
            <label htmlFor="email" className="block mb-2 font-bold text-gray-700">
              Customer Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              className="w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter customer email"
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
            <label htmlFor="selectedBank" className="block mb-2 font-bold text-gray-700">
              Bank
            </label>
            <select
              id="selectedBank"
              name="selectedBank"
              value={formData.selectedBank}
              onChange={handleInputChange}
              className="w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" disabled>
                Select Bank
              </option>
              {banks.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.bank_name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 px-2 mb-4">
            <label htmlFor="mode" className="block mb-2 font-bold text-gray-700">
              Mode of Payment
            </label>
            <select
              id="mode"
              name="mode"
              value={formData.mode}
              onChange={handleInputChange}
              className="w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" disabled>
                Select Mode
              </option>
              <option value="POS">POS</option>
              <option value="Transfer">Transfer</option>
              <option value="Cash">Cash</option>
            </select>
          </div>
        </div>
        <div className="flex flex-wrap mb-6 items-end">
          <div className="w-full md:w-1/3 px-2 mb-4">
            <label htmlFor="currency" className="block mb-2 font-bold text-gray-700">
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
              className="w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="NGN">Naira (₦)</option>
              <option value="USD">Dollar ($)</option>
            </select>
          </div>
          <div className="w-full md:w-1/3 px-2 mb-4">
            <label htmlFor="discount" className="block mb-2 font-bold text-gray-700">
              Discount (%)
            </label>
            <input
              id="discount"
              name="discount"
              type="number"
              min="0"
              max="100"
              className="w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={formData.discount}
              onChange={handleInputChange}
              placeholder="Enter discount percentage"
            />
          </div>
          <div className="w-full md:w-1/3 px-2 mb-4">
            <label htmlFor="product" className="block mb-2 font-bold text-gray-700">
              Product
            </label>
            <select
              id="product"
              value={selectedProduct.code || ''}
              onChange={(e) => {
                const product = finished_Products.find((p) => p.code === e.target.value);
                setSelectedProduct(product);
              }}
              className="w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" disabled>
                Select Product
              </option>
              {finished_Products &&
                finished_Products.map((product) => (
                  <option key={product.code} value={product.code}>
                    {product.code} - {product.item.name} -{' '}
                    {formData.currency === 'NGN'
                      ? `₦${product.item.price_ngn}`
                      : `$${product.item.price_usd}`}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-full md:w-auto px-2 mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6"
              onClick={addLineItem}
            >
              Add Product
            </button>
          </div>
        </div>
        <div className="overflow-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="text-left py-2 px-3 bg-gray-100 border-b border-gray-200">Product</th>
                <th className="text-left py-2 px-3 bg-gray-100 border-b border-gray-200">
                  Product Code
                </th>
                <th className="text-left py-2 px-3 bg-gray-100 border-b border-gray-200">Price</th>
                <th className="text-left py-2 px-3 bg-gray-100 border-b border-gray-200">
                  Quantity
                </th>
                <th className="text-left py-2 px-3 bg-gray-100 border-b border-gray-200">Total</th>
                <th className="text-left py-2 px-3 bg-gray-100 border-b border-gray-200">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {lineItems.map((item) => (
                <tr key={item.code} className="border-gray-200 border-b">
                  <td className="py-2 px-3">{item.name}</td>
                  <td className="py-2 px-3">{item.code}</td>
                  <td className="py-2 px-3">
                    {formData.currency === 'NGN' ? `₦${item.price}` : `$${item.price}`}
                  </td>
                  <td className="py-2 px-3">
                    <input
                      disabled
                      type="number"
                      min="1"
                      className="w-16 py-1 px-2 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      value={item.quantity}
                      onChange={(e) => updateLineItemQuantity(item.code, parseInt(e.target.value))}
                    />
                  </td>
                  <td className="py-2 px-3">
                    {formData.currency === 'NGN'
                      ? `₦${(item.price * item.quantity).toFixed(2)}`
                      : `$${(item.price * item.quantity).toFixed(2)}`}
                  </td>
                  <td className="py-2 px-3">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                      onClick={() => removeLineItem(item.code)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
              {lineItems.length === 0 && (
                <tr>
                  <td colSpan="6" className="text-center py-4">
                    No products added. Please add products to proceed.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-6 flex flex-col md:flex-row justify-between items-center">
          <h2 className="text-2xl font-bold mb-4 md:mb-0">
            Total:{' '}
            {formData.currency === 'NGN'
              ? `₦${calculateTotal().toFixed(2)}`
              : `$${calculateTotal().toFixed(2)}`}
          </h2>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded"
            onClick={submitOrder}
          >
            Submit Order
          </button>
        </div>
        <input
          type="text"
          value={barcodeInput}
          onChange={(e) => setBarcodeInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const product = finished_Products.find((p) => p.code === barcodeInput.trim());
              if (product) {
                setSelectedProduct(product);
                setIsModalOpen(true);
              } else {
                toast.error('Product not found!');
              }
              setBarcodeInput(''); // Clear barcode input after scanning
            }
          }}
          autoFocus
          style={{ position: 'absolute', top: '-1000px' }} // Hidden input field
        />



        {/* Modal for showing selected product */}
        {isModalOpen && selectedProduct && (
          <div className="modal fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-4">
              <h2 className="text-2xl font-bold mb-4">Product Details</h2>
              <p><strong>Name:</strong> {selectedProduct.item.name}</p>
              <p><strong>Code:</strong> {selectedProduct.code}</p>
              <p><strong>Price:</strong> {formData.currency === 'NGN' ? `₦${selectedProduct.item.price_ngn}` : `$${selectedProduct.item.price_usd}`}</p>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                onClick={addLineItem}
              >
                Add Product
              </button>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default POS;
