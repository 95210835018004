import React, {
    createContext,
    useContext,
    useState
} from "react";
import axiosClient from "../axios";

const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false
}

export const ContextProvider = ({
    children
}) => {
    // auth
    const [currentUser, setCurrentUser] = useState();
    const [authLoading, setAuthLoading] = useState(false);

    React.useEffect(() => {
        checkLogin();
    }, []);

    const checkLogin = () => {
        const token = localStorage.getItem("token");

        setAuthLoading(true);

        if(token){
            axiosClient.get('/user/me').then((response) => {
                const user = response.data ;
                // console.log("current user", user);
                 setCurrentUser(user);
                setAuthLoading(false);
               

                // if(user){
                //     console.log("use:",user);
                //     setCurrentUser(user);
                // }
            });
        }else{
            setCurrentUser(null);
            setAuthLoading(false);
         
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        setCurrentUser(null);
    }

    const testData = "test data";
// old
    const [activeMenu, setActiveMenu] = useState(true);

    const [isClicked, setIsClicked] = useState(initialState);
    const handleClick = (clicked) => {
        setIsClicked({
            ...initialState,
            [clicked]: true
        });
    }
    const [screenSize, setScreenSize] = useState(undefined);
    return ( <
        StateContext.Provider value = {
            {
                activeMenu,
                setActiveMenu,
                isClicked,
                setIsClicked,
                handleClick,
                screenSize,
                setScreenSize,
                // new
                currentUser,
                setCurrentUser,
                checkLogin,
                setAuthLoading,
                authLoading,
                handleLogout,
                testData
            }
        } >
        {
            children
        } </StateContext.Provider>
    )
}
export const useStateContext = () => useContext(StateContext);