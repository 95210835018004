import { useState, useEffect, useCallback } from 'react';
import ExpenseFilter from './ExpenseFilter';
import ExpenseSort from './ExpenseSort';
import Pagination from './Pagination';
import DateFormat from '../Utils/DateFormat';
import { FaEye } from 'react-icons/fa';
import axiosClient from '../../axios';
import { useExpenseContext } from '../../hooks/useExpensesContext';
import { useVendorContext } from '../../hooks/useVendorsContext.js';
import Spinner from '../Spinner';
import { useExpenseCategory } from '../../hooks/useExpenseCategories.js';
import { useWareHousesContext } from '../../hooks/useWareHouseContext.js';
import AddExpenseModal from './AddExpenseModal.jsx';
import { toast } from 'react-toastify';
import MoneyFormat from '../Utils/MoneyFormat.jsx';
import React from 'react';

const ExpenseList = () => {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState('monthly');
    const { expenses, dispatch } = useExpenseContext();
    const { vendors, dispatch: set_dispatch } = useVendorContext();
    const { expenseCategories, dispatch: set_expense_categories } = useExpenseCategory();
    const { wareHouses, dispatch: set_warehouses } = useWareHousesContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const fetchVendors = useCallback(async () => {
        try {
            const response = await axiosClient.get(`expense/vendors`);
            if (response?.status === 200) {
                set_dispatch({
                    type: 'SET_VENDORS',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch vendors:', error);
        }
    }, [set_dispatch]);

    const fetchWareHouses = useCallback(async () => {
        try {
            const response = await axiosClient.get(`warehouse`);

            if (response?.status === 200) {
                set_warehouses({
                    type: 'SET_WARE_HOUSES',
                    payload: response?.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch warehouses:', error);
        }
    }, [set_warehouses]);

    const fetchExpenseCategories = useCallback(async () => {
        try {
            const response = await axiosClient.get(`expense/categories`);

            if (response?.status === 200) {
                set_expense_categories({
                    type: 'SET_EXPENSE_CATEGORIES',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch expense categories:', error);
        }
    }, [set_expense_categories]);

    const fetchExpenses = useCallback(async () => {
        const queryParams = new URLSearchParams({
            sort,
            expense_category_id: filters.expenseCategory || '',
            vendor_id: filters.vendor || '',
            warehouse_id: filters?.warehouse || '',
            page: currentPage,
        });

        try {
            const response = await axiosClient.get(`expense/fetch?${queryParams}`);

            if (response?.status === 200) {
                dispatch({
                    type: 'SET_EXPENSES',
                    payload: response.data.data.data,
                });
                setTotalPages(response.data.data.totalPages);
            }
        } catch (error) {
            console.error('Failed to fetch expenses:', error);
        }
    }, [sort, filters, currentPage, dispatch]);

    useEffect(() => {
        fetchWareHouses();
        fetchExpenseCategories();
        fetchVendors();
        fetchExpenses();
    }, [dispatch, filters, sort, currentPage]);

    if (!expenses) {
        return <Spinner />;
    }

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1);
    };

    const handleSortChange = (newSort) => {
        setSort(newSort);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleExpenseCreated = () => {
        toast.success("Expense created!")
        fetchExpenses(); // Refresh the expense data
        closeModal();
    };

    return (
        <div>

            <div className="flex justify-between items-center">
                <ExpenseFilter onFilterChange={handleFilterChange} vendors={vendors} expenseCategories={expenseCategories} warehouses={wareHouses} />
                <ExpenseSort onSortChange={handleSortChange} />
                <button
                    onClick={openModal}
                    className="bg-blue-500 text-white p-2 rounded"
                >
                    Add Expense
                </button>
            </div>

            <div className="flex flex-col mt-6">
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="space-y-6">
                {expenses.map((expense, index) => (
                    <div
                        key={expense.id || index}
                        className={`bg-white border border-gray-300 rounded-lg shadow-sm p-4 ${
                            index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                        }`}
                    >
                        {/* Main Expense Details */}
                        <div className="mb-4">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        {["S/N", "Purpose", "Warehouse", "Staff", "Total", "Date"].map((header, idx) => (
                                            <th
                                                key={idx}
                                                className="text-sm font-semibold text-gray-700 px-6 py-3 text-left uppercase tracking-wide"
                                            >
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-6 py-4 text-sm font-medium text-gray-900">{index + 1}</td>
                                        <td className="px-6 py-4 text-sm text-gray-800 break-words">{expense.purpose}</td>
                                        <td className="px-6 py-4 text-sm text-gray-800">{expense.warehouse?.name}</td>
                                        <td className="px-6 py-4 text-sm text-gray-800">{expense.staff}</td>
                                        <td className="px-6 py-4 text-sm text-gray-800">
                                            <MoneyFormat amount={expense?.total} />
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-800">
                                            <DateFormat date={expense.createdAt} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* Nested Expense Items */}
                        <div className="bg-white p-4 border-t border-gray-300 rounded-md">
                            <h3 className="text-sm font-semibold text-gray-700 mb-2">Expense Items</h3>
                            <table className="w-full border border-gray-300 rounded-md">
                                <thead className="bg-gray-200">
                                    <tr>
                                        {["Item", "Category", "Quantity", "Amount"].map((subHeader, subIdx) => (
                                            <th
                                                key={subIdx}
                                                className="text-left px-4 py-2 text-sm font-medium text-gray-700"
                                            >
                                                {subHeader}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {expense.items.map((item, itemIndex) => (
                                        <tr
                                            key={itemIndex}
                                            className={`border-t ${
                                                itemIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                                            }`}
                                        >
                                            <td className="px-4 py-2 text-sm text-gray-800">{item?.sub_title ?? "-"}</td>
                                            <td className="px-4 py-2 text-sm text-gray-800">{item.category.name}</td>
                                            <td className="px-4 py-2 text-sm text-gray-800">{item.quantity}</td>
                                            <td className="px-4 py-2 text-sm text-gray-800">
                                                <MoneyFormat amount={item?.amount} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
</div>



            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />

            <AddExpenseModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                vendors={vendors}
                expenseCategories={expenseCategories}
                onExpenseCreated={handleExpenseCreated}
            />

        </div>
    );
};

export default ExpenseList;
