import { useReducer } from "react";
import { createContext } from "react";

export const OrderCategoriesContext = createContext();

export const orderCategoriesReducer = (state, action) => {
    switch (action.type) {
        case "SET_ORDER_CATEGORIES":
            return {
                orderCategories: action.payload
            }
        case "CREATE_ORDER_CATEGORY" :
            return {
                orderCategories: [action.payload, ...state.orderCategories]
            }
        case "DELETE_ORDER_CATEGORY" : 
            return {
                orderCategories: state.orderCategories.filter((orderCategory) => orderCategory.id !== action.payload.id)
            }
        case "UPDATE_ORDER_CATEGORY" :
            return {
                orderCategories: state.orderCategories.filter((orderCategory) => orderCategory.id == action.payload.id)
            }
        default: 
            return state
    }
}

export const OrderCategoriesContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(orderCategoriesReducer, {
        orderCategories: null
    })    
    return (
        <OrderCategoriesContext.Provider value={{...state, dispatch}}>
            {children}
        </OrderCategoriesContext.Provider>
    )
}