import React, { useCallback, useEffect } from 'react'
import { useExpenseCategory } from '../../hooks/useExpenseCategories';
import AddExpenseCategory from './AddExpenseCategory';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';
import axiosClient from '../../axios';

const ExpenseCategoryList = ({ isModalOpen, setIsModalOpen }) => {

    const { expenseCategories, dispatch } = useExpenseCategory()
    const closeModal = () => setIsModalOpen(false);

    const fetchExpenseCategories = useCallback(async () => {
        try {
            const response = await axiosClient.get(`expense/categories`);

            if (response?.status === 200) {
                dispatch({
                    type: 'SET_EXPENSE_CATEGORIES',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch expense categories:', error);
        }
    }, [dispatch]);

    useEffect(() => {

        fetchExpenseCategories();
    }, [dispatch]);

    if (!expenseCategories) {

        return <Spinner />; 
    }

    const handleExpenseCategoryCreated = () => {
        toast.success(" Expense Category created!")
        fetchExpenseCategories();
        closeModal(); 
    };
    return (
        <div>
            <div className="flex flex-col mt-4">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">S/N</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Name</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Code</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {expenseCategories?.map((expenseCategory, index) => (
                                        <tr className="border-b" key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{expenseCategory?.name}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{expenseCategory?.code}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
           

            <AddExpenseCategory
                isOpen={isModalOpen}
                closeModal={closeModal}

                onExpenseCategoryCreated={handleExpenseCategoryCreated}

            />
        </div>
    )
}

export default ExpenseCategoryList