import { useContext } from "react"
import { OrderCategoriesContext } from "../contexts/Orders/OrderCategoriesContext"

export const useOrderCategoriesContext = () => {
    const context = useContext(OrderCategoriesContext);

    if (!context) {
        throw Error(" useOrderCategoriesContext must be used inside a OrderCategoriesContextProvider")
    }

    return context
}