function MoneyFormat({ amount = 0, currency = "NGN" }) {
  const format = currency === "USD" ? "en-US" : "en-NG";
  const formattedAmount = new Intl.NumberFormat(format, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).format(amount);

  return <span>{formattedAmount}</span>;
}

export default MoneyFormat;
