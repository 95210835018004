import { useContext } from "react"
import { WebsiteOrdersContext } from "../contexts/WebsiteOrder/WebsiteOrdersContext"

export const useWebsiteOrdersContext = () => {
    const context = useContext(WebsiteOrdersContext);

    if (!context) {
        throw Error("useWebsiteOrdersContext must be used inside a WebsiteOrdersContextProvider")
    }

    return context;
}