

const Femi = ({ data }) => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div className="p-4 bg-white rounded-lg shadow flex items-center justify-center sm:justify-start">
                <svg className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 mr-2 sm:mr-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M3 4v16c0 .552.448 1 1 1h16c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1H4c-.552 0-1 .448-1 1z" />
                    <path d="M16 2H8a2 2 0 00-2 2v14a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z" />
                    <path d="M12 18h.01" />
                </svg>
                <div>
                    <p className='font-bold text-gray-400'>Customers</p>
                    <p className='text-2xl font-bold text-blue-500'>{data?.customer_count}</p>
                </div>
            </div>
            <div className="p-4 bg-white rounded-lg shadow flex items-center justify-center sm:justify-start">
                <svg className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 mr-2 sm:mr-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M3 4v16c0 .552.448 1 1 1h16c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1H4c-.552 0-1 .448-1 1z" />
                    <path d="M16 2H8a2 2 0 00-2 2v14a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z" />
                    <path d="M12 18h.01" />
                </svg>
                <div>
                    <p className='font-bold text-gray-400'>Staff</p>
                    <p className='text-2xl font-bold text-blue-500'>{data?.staff_count}</p>
                </div>
            </div>
            <div className="p-4 bg-white rounded-lg shadow flex items-center justify-center sm:justify-start">
                <svg className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 mr-2 sm:mr-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M3 4v16c0 .552.448 1 1 1h16c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1H4c-.552 0-1 .448-1 1z" />
                    <path d="M16 2H8a2 2 0 00-2 2v14a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z" />
                    <path d="M12 18h.01" />
                </svg>
                <div>
                    <p className='font-bold text-gray-400'>Banks</p>
                    <p className='text-2xl font-bold text-blue-500'>{data?.banks_count}</p>
                </div>
            </div>

            <div className="p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-medium mb-2">Total Orders</h2>
                <p className="text-2xl font-bold text-yellow-500">{data?.order_count}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-medium mb-2">Pending Orders</h2>
                <p className="text-2xl font-bold text-yellow-500">{data?.pending_orders}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-medium mb-2">Completed Orders</h2>
                <p className="text-2xl font-bold text-green-500">{data?.completed_orders}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow flex items-center justify-center sm:justify-start">
                <svg className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 mr-2 sm:mr-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M3 4v16c0 .552.448 1 1 1h16c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1H4c-.552 0-1 .448-1 1z" />
                    <path d="M16 2H8a2 2 0 00-2 2v14a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z" />
                    <path d="M12 18h.01" />
                </svg>
                <div>
                    <p className='font-bold text-gray-400'>Cutting Ticket</p>
                    <p className='text-2xl font-bold text-blue-500'>{data?.cutting_ticket_count}</p>
                </div>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-medium mb-2">Pending Cutting Ticket</h2>
                <p className="text-2xl font-bold text-yellow-500">{data?.pending_cutting_ticket_count}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-medium mb-2">Completed Cutting Ticket</h2>
                <p className="text-2xl font-bold text-green-500">{data?.completed_cutting_ticket_count}</p>
            </div>

            <div className="p-4 bg-white rounded-lg shadow flex items-center justify-center sm:justify-start">
                <svg className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 mr-2 sm:mr-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M3 4v16c0 .552.448 1 1 1h16c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1H4c-.552 0-1 .448-1 1z" />
                    <path d="M16 2H8a2 2 0 00-2 2v14a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z" />
                    <path d="M12 18h.01" />
                </svg>
                <div>
                    <p className='font-bold text-gray-400'>Work Orders</p>
                    <p className='text-2xl font-bold text-blue-500'>{data?.work_orders}</p>
                </div>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-medium mb-2">Pending Work Orders</h2>
                <p className="text-2xl font-bold text-yellow-500">{data?.pending_work_order}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-medium mb-2">Completed Workorders</h2>
                <p className="text-2xl font-bold text-green-500">{data?.completed_work_order}</p>
            </div>

            {/* <div className="p-4 bg-white rounded-lg shadow">
              <h2 className="text-lg font-medium mb-2">Total Amount in POS</h2>
              <p className="text-2xl font-bold text-green-500">{data?.pos_payment}</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
              <h2 className="text-lg font-medium mb-2">Total Amount Paid Orders</h2>
              <p className="text-2xl font-bold text-green-500">{data?.order_payment}</p>
            </div> */}
        </div>
    )
}

export default Femi