import { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import axiosClient from "../../axios";
import JsPDF from 'jspdf';
import ChangeCuttingTicketStatus from "./ChangeCuttingTicketStatus";
import { toast } from 'react-toastify';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TwoColumnPdf from "../../components/pdfs/ctk";
import DateFormat from "../../components/Utils/DateFormat";
import StatusButton from "../../components/Utils/StatusButton";
import Loading from "../../components/Utils/Loading";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import BarcodeToPrint from "./BarcodeToPrint";

const OneCuttingTicket = () => {

  const barcodeRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => barcodeRef.current,
    documentTitle: 'Barcode',
    pageStyle: `
      @page {
        size: 50mm 25mm; /* Correct paper size */
        margin: 0;
      }
      @media print {
        body {
          margin: 0;
          /* Ensure no scaling or rotation */
        }
      }
    `,
  });
  
  


  const generatePDF = () => {
    const report = new JsPDF('l', 'pt', 'a4');
    report.setFontSize(8);
    report.html(document.querySelector('#report')).then(() => {
      report.save(`${cuttingTicket?.workorder?.reference_number}.pdf`);
    });
  };

  const params = useParams();
  const [cuttingTicket, setCuttingTicket] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [statusCtk, setStatusCtk] = useState(null);

  const openStatusModal = () => {
    
    setOpen(true);
  };

  const options = [
    { label: "Pending", value: "pending" },
    { label: "Started", value: "started" },
    { label: "Completed", value: "completed" },
    { label: "Cancelled", value: "cancelled" },
  ];

  const formData = {
    work_order_id: cuttingTicket?.work_order_id,
    product_id: cuttingTicket?.workorder?.productWorkOrder?.id,
    quantity: cuttingTicket?.quantity,
  };

  const fetchCuttingTicket = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data, status } = await axiosClient.get(`cutting-ticket/${params.id}`);
      if (status === 200) {
        setCuttingTicket(data);
        setStatusCtk(data.status);
      } else {
        toast.error("An error occurred while fetching cutting ticket");
      }
    } catch (error) {
      toast.error("An error occurred while fetching cutting ticket");
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchCuttingTicket();
  }, [fetchCuttingTicket]);

  return (
    <section className="pt-10 md:pt-10 pb-24 bg-gray-100">
      <Loading isLoading={isLoading} />
      {!isLoading && cuttingTicket && (
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4 mb-20" id='report'>
            <div className="w-full xl:w-1/2 px-4 mb-16 xl:mb-0">
              <div className="flex flex-col h-full items-start px-6 sm:px-12 pt-16 pb-24 bg-white rounded-2xl">
                <div className="w-full flex flex-wrap pb-2 border-b border-gray-200">
                  <StatusButton status={cuttingTicket?.status} />
                  <small className="px-6">{cuttingTicket?.reference_number}</small>
                </div>
                <div className="flex items-center">
                  <img className="object-scale-down w-96" src={cuttingTicket?.workorder?.productWorkOrder?.image} alt={cuttingTicket?.reference_number} />
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>WorkOrder <span className='mt-1 text-sm text-gray-500'>{cuttingTicket?.workorder?.reference_number}</span></p>
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>Purpose  <span className='mt-1 text-sm text-gray-500'>{cuttingTicket?.purpose}</span></p>
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>Customer <span className='mt-1 text-sm text-gray-500'>{cuttingTicket?.workorder?.salesOrder?.customer?.first_name} {cuttingTicket?.workorder?.salesOrder?.customer?.last_name}</span></p>
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>Code -</p>
                  {cuttingTicket?.fp?.map((code, index) => (
                    <div key={index}>
                      <BarcodeToPrint barcodeData={code?.code} ref={barcodeRef} />
                    </div>
                  ))}
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>Product  <span className='mt-1 text-sm text-gray-500'>{cuttingTicket?.workorder?.productWorkOrder?.name}</span></p>
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>Quantity <span className='mt-1 text-sm text-gray-500'>{cuttingTicket?.quantity}</span></p>
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>Duration <span className='mt-1 text-sm text-gray-500'><DateFormat date={cuttingTicket?.start_date} /> to <DateFormat date={cuttingTicket?.complete_date} /></span></p>
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <p className='font-medium text-gray-900'>Staff in charge <span className='mt-1 text-sm text-gray-500'>{cuttingTicket?.user?.first_name} {cuttingTicket?.user?.last_name}</span></p>
                </div>
                <div className="w-full pb-2 border-b border-gray-200">
                  <PDFDownloadLink document={<TwoColumnPdf data={cuttingTicket} />} fileName="order_details.pdf">
                    {({ loading }) => (loading ? 'Generating PDF...' : <p className='font-medium text-red-900'>DOWNLOAD</p>)}
                  </PDFDownloadLink>
                </div>
              
                <button
                  onClick={handlePrint}
                  className="mt-4 text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5"
                >
                  Print Barcode
                </button>
              </div>
            </div>
            <div className="w-full xl:w-1/2 px-4">
              <div className="px-6 sm:px-12 pt-16 pb-24 bg-white rounded-2xl">
                <div className="sm:flex justify-between items-center mb-10 pb-2 border-b border-gray-200">
                  <h4 className="font-heading text-2xl mb-4 sm:mb-0">Material Sheet - {cuttingTicket?.reference_number}</h4>
                  <button className="text-gray-500 border border-gray-300 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="button" onClick={openStatusModal}>{cuttingTicket?.status}</button>
                </div>
                <div className="overflow-x-auto px-4">
                  <table className="table-auto w-full min-w-max">
                    <thead>
                      <tr>
                        <th></th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Material</th>
                    
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Quantity</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      { cuttingTicket?.workorder?.productWorkOrder?.productMaterial?.map((item, index) => (
                        <tr className="border-b" key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{index + 1}</td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{item?.material?.name}</td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">-</td>
                       
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ChangeCuttingTicketStatus
      ctkId={params} 
        isOpen={isOpen}
        setOpen={setOpen}
        options={options}
        statusCtk={statusCtk}
        setStatusCtk={setStatusCtk}
     
        formData={formData}
        fetchCuttingTicket={fetchCuttingTicket}
      />
     
    </section>
  );
};

export default OneCuttingTicket;
