import React, { useEffect, useState } from 'react';
import axiosClient from '../../axios';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = [
    '#0088FE', 
    '#00C49F', 
    '#FFBB28', 
    '#FF8042', 
    '#FF6633', 
    '#D2C94C', 
    '#67B7D1', 
    '#F24E1E', 
    '#A4508B', 
    '#F5C03D'
];

const ExpenseChart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [warehouseId, setWarehouseId] = useState(2);
    const [period, setPeriod] = useState('yearly');
    const [warehouses, setWarehouses] = useState([]);

    // Fetch warehouses for the select dropdown
    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const response = await axiosClient.get('warehouse');
                setWarehouses(response.data);
            } catch (error) {
                // console.error('Error fetching warehouses:', error);
            }
        };

        fetchWarehouses();
    }, []);

    // Fetch expense data for the pie chart
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axiosClient.get('overview/analytics/expenses', {
                    params: {
                        period: period || 'yearly',
                        warehouse_id: warehouseId || undefined,
                    },
                });
                
                const formattedData = response.data.data.map(item => ({
                    category: item.category,
                    totalAmount: parseFloat(item.totalAmount), // Convert string to number
                }));

                // console.log('Formatted Data:', formattedData);
                setData(formattedData); // Set formatted data
            } catch (error) {
                setError('Failed to fetch data');
                // console.error('Error fetching expense data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [warehouseId, period]);

    const handleWarehouseChange = (e) => {
        setWarehouseId(e.target.value);
    };

    const handlePeriodChange = (e) => {
        setPeriod(e.target.value);
    };

    const formatAmount = (amount) => {
        return `₦${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };

    return (
        <div className="w-full max-w-full mx-auto p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-center mb-4">Expense Analytics</h2>

            <div className="mb-4">
                <label htmlFor="warehouse" className="block mb-2 text-sm font-medium text-gray-700">
                    Select Warehouse
                </label>
                <select
                    id="warehouse"
                    value={warehouseId}
                    onChange={handleWarehouseChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
                >
                    <option value="">All Warehouses</option>
                    {warehouses.map((warehouse) => (
                        <option key={warehouse.id} value={warehouse.id}>
                            {warehouse.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-4">
                <label htmlFor="period" className="block mb-2 text-sm font-medium text-gray-700">
                    Select Period
                </label>
                <select
                    id="period"
                    value={period}
                    onChange={handlePeriodChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
                >
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="weekly">Weekly</option>
                </select>
            </div>

            {loading ? (
                <p className="text-center text-blue-500">Loading...</p>
            ) : error ? (
                <p className="text-center text-red-500">{error}</p>
            ) : data.length === 0 ? (
                <p className="text-center text-gray-500">No data to load</p>
            ) : (
                <ResponsiveContainer width="100%" height={400}> {/* Set height for better visibility */}
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="totalAmount"
                            nameKey="category"
                            cx="50%"
                            cy="50%"
                            outerRadius={90} 
                            innerRadius={60} 
                            fill="#8884d8"
                            label={({ name, value }) => `${name}: ${formatAmount(value)}`} 
                            labelStyle={{ fontSize: '12px', fill: '#555' }}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip
                            formatter={(value) => [formatAmount(value), 'Total Amount']} 
                            contentStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '8px' }}
                            itemStyle={{ color: '#fff' }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default ExpenseChart;
