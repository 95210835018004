import React, { useState } from 'react';
import { Header } from '../../components';
// import { useCuttingTicketContext } from '../../contexts/CuttingTicketProvider';
import { useNavigate } from 'react-router-dom';
import AddCuttingTicket from '../../components/Modals/CuttingTicket/AddCuttingTicket';
import CuttingTicketList from '../../components/CuttingTickets/CuttingTicketList';
import EditCuttingTicket from '../../components/Modals/CuttingTicket/EditCuttingTicket';

const ViewCuttingTicket = () => {
    let navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const openModal = () => {
        setOpen(true);
      }
      const closeModal = () => {
        setOpen(false);
      }
    
 const goToCuttingTicket = (cutting_ticket) => {
   navigate(`/cutting-ticket/${cutting_ticket.id}`);
 }

  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
    <Header title="Cutting Ticket"  buttonName="New" openModal={ openModal }/>
    <CuttingTicketList  goToCuttingTicket={goToCuttingTicket} setOpenEdit={setOpenEdit} setModalContent={setModalContent}/>
    <AddCuttingTicket isOpen={isOpen} setOpen={closeModal} /> 
    <EditCuttingTicket   modalContent={modalContent} setOpenEdit={setOpenEdit} openEdit={openEdit}/>
</div>
  )
}

export default ViewCuttingTicket