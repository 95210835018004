import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { Header } from "../../components";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useProductsContext } from "../../hooks/useProductsContext";

const CreateProduct = () => {
  const { user } = useAuthContext();
  const { dispatch} = useProductsContext();
  const navigate = useNavigate();
  const  [productName, setProductName] = useState("");
  const  [productCode, setProductCode] = useState("");
  const  [productSize, setProductSize] = useState("");
  const  [productUSD, setProductUSD] = useState("");
  const  [productNGN, setProductNGN] = useState("");
  const  [productImage, setProductImage] = useState("");
  const  [productDescription, setProductDescription] = useState("");

  const handleSubmit = async (e) => {
        e.preventDefault();

        if(!user){
          toast.error('you must be logged in');
          return
        }

        const product = {
          name: productName,
          description: productDescription,
          code: productCode,
          size: productSize,
          image: productImage,
          price_usd: productUSD,
          price_ngn:productNGN
        }

        const respon = axiosClient.post('/product', product).then(function (response) {
          console.log(response.data);
          if(response.status === 201){
            setProductName("");
            setProductCode("");
            setProductSize("");
            setProductUSD("");
            setProductNGN("");
            setProductImage("");
            setProductDescription("");

            dispatch({
              type: "CREATE_PRODUCT",
              payload: response.data
            })

            toast.success("Product has been");

            setTimeout(function(){
              navigate(`/product/${response.data.id}`)
            }, 5000);
        }}).catch((error) => {
          toast.error("something's wrong, try contact Mike");
          console.log("errror to bad", error);
        })
        
  }
  return (
    <>
          <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header   title="Create Product"/>
    <div className="block p-6 rounded-lg shadow-lg bg-white ">
  <form onSubmit={handleSubmit}>
    <div className="grid grid-cols-2 gap-4">
      <div className="form-group mb-6">
        <input type="text" 
        onChange={(e) => setProductName(e.target.value)} value={productName}
        className="form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          aria-describedby="" placeholder="Product Name" required/>
      </div>
      <div className="form-group mb-6">
        <input type="text"
         onChange={(e) => setProductCode(e.target.value)} value={productCode}
         className="form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
          aria-describedby="" placeholder="Product Code"/>
      </div>
    </div>
    {/* price */}
    <div className="grid grid-cols-2 gap-4">
      <div className="form-group mb-6">
        <input type="number" 
        onChange={(e) => setProductUSD(e.target.value)} value={productUSD}
        className="form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          aria-describedby="" placeholder="USD price" required/>
      </div>
      <div className="form-group mb-6">
        <input type="number"
         onChange={(e) => setProductNGN(e.target.value)} value={productNGN}
         className="form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
          aria-describedby="" placeholder="NGN price"/>
      </div>
    </div>

    {/* size and image */}
    <div className="grid grid-cols-2 gap-4">
      <div className="form-group mb-6">
        <input type="text" 
        onChange={(e) => setProductSize(e.target.value)} value={productSize}
        className="form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          aria-describedby="" placeholder="Product Size" required/>
      </div>
      <div className="form-group mb-6">
        <input type="url"
         onChange={(e) => setProductImage(e.target.value)} value={productImage}
         className="form-control
          block
          w-full
          px-3
          py-1.5
          text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
          aria-describedby="" placeholder="Product Image Link"/>
      </div>
    </div>





    <div className="form-group mb-6">
      <textarea  
       onChange={(e) => setProductDescription(e.target.value)} value={productDescription}
      className="form-control block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput125"
        placeholder="description"/>
    </div>
    {/* <div className="form-group mb-6">
      <input type="password" className="form-control block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput126"
        placeholder="Password"/>
    </div> */}
    {/* <div className="form-group form-check text-center mb-6">
      <input type="checkbox"
        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
        id="exampleCheck25" checked/>
      <label className="form-check-label inline-block text-gray-800" for="exampleCheck25">Subscribe to our newsletter</label>
    </div> */}
    <button type="submit" className="
      w-full
      px-6
      py-2.5
      bg-blue-600
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out">Save Product</button>
  </form>
</div>
</div>
    </>
  )
}

export default CreateProduct