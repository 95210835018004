import React from 'react'

const SuperPartials = ({ links, NavLink, handleCloseSidebar, activeLink, normalLink}) => {
    return (
        <div className='mt-10'>
            {links.map((item) => (

                <div key={item.title}>
                    <p className='text-gray-400 m-3 mt-4 uppercase'> {item.title}</p>
                    {item.links.map((link) => (
                        <NavLink
                            to={`/${link.title}`}
                            key={link.name}
                            onClick={handleCloseSidebar}
                            className={({ isActive }) =>
                                isActive ? activeLink : normalLink}
                        >
                            {link.icon}
                            <span className='capitalize'>
                                {link.name}
                            </span>
                        </NavLink>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default SuperPartials