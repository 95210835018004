import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../../axios";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCustomersContext } from "../../../hooks/useCustomersContext";
import { useOrderCategoriesContext } from "../../../hooks/useOrderCategoriesContext";
import { useProductsContext } from "../../../hooks/useProductsContext";
import { useOrdersContext } from "../../../hooks/useOrderContext";
import { useNavigate } from "react-router-dom";
import AddCustomer from "../../../components/Modals/Customer/AddCustomer";

const AddSalesOrder = () => {
  const [isOpenAddcustomer, setOpenAddcustomer] = useState(false);
  const date = new Date();
  const futureDate = date.getDate() + 3;
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString('en-CA');
  let navigate = useNavigate();
  const { user } = useAuthContext();
  const { customers, dispatch: setCust } = useCustomersContext();
  const { products, dispatch: setProd } = useProductsContext();
  const { dispatch: setOrders } = useOrdersContext();
  const { orderCategories, dispatch: setOrderCate } = useOrderCategoriesContext();
  const [mobile_number, setMobileNumber] = useState("");
  const [customer_id, setCustomerId] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [currency, setCurrency] = useState("NGN");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [source, setSource] = useState("");
  const [local_government, setLocalGovernment] = useState("");
  const [street, setStreet] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [order_category_id, setCategory] = useState("");
  const [notes, setNotes] = useState("");
  const [vatFee, setVat] = useState('');
  const [discount, setDiscount] = useState('');
  const [shippingFee, setShippingFee] = useState('');
  const [extras, setExtras] = useState('');

  const [selectedUser, setSelectedUser] = useState(null);
  const [submitting, setsubmitting] = useState(false);
  const [wareHouses, setWareHouses] = useState(null);
  const [wareHouse_id, setWareHouse] = useState("");
  const handleChange = (e) => {
    setCustomerId(e.target.value);
    const selectedId = parseInt(e.target.value, 10);
    const selectedUser = customers.find(user => user.id === selectedId);
    setSelectedUser(selectedUser);
    // console.log("SELECTED USER", selectedUser);
  };


  const openAddCustomerModal = () => {
    setOpenAddcustomer(true)
  }

  const [orderItems, setorderItems] = useState([
    { product: "", quantity: 1 }
  ]);

  const handleAddFields = () => {
    setorderItems([...orderItems, { product: "", quantity: "" }]);
  }

  const handleRemoveFields = (index) => {
    const values = [...orderItems];
    values.splice(index, 1);
    setorderItems(values);
  }

  // const handleChangeInput = (index, event) => {
  //   const values = [...orderItems];
  //   values[index][event.target.name] = event.target.value;
  //   setorderItems(values);
  //   console.log("values", values);
  // }
  // const handleChangeInput = (index, event) => {
  //   const values = [...orderItems];
  //   values[index][event.target.name] = event.target.value;

  //   if (event.target.name === "quantity" || event.target.name === "product") {
  //     const product = products.find((p) => p.id === Number(values[index].product));
  //     if (product) {
  //       values[index].total_ = (Number(values[index].quantity) * product.price_ngn).toFixed(2);
  //     } else {
  //       values[index].total_ = "";
  //     }
  //   }

  //   setorderItems(values);
  //   // console.log("values", values);
  // };

  const handleChangeInput = (index, event) => {
    const { name, value } = event.target;
    const values = [...orderItems];
    values[index][name] = value;

    if (name === "quantity" || name === "product") {
      const product = products.find((p) => p.id === Number(values[index].product));
      values[index].total_ = product ? (Number(values[index].quantity) * (currency === "USD" ? product.price_usd : product.price_ngn)).toFixed(2) : "";
    }

    setorderItems(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setsubmitting(true);

    const orderData = {
      "orderItems": orderItems.map(element => ({ quantity: element.quantity, product_id: element.product })),
      "mobile_number": mobile_number,
      "source": source,
      "deliveryDate": deliveryDate,
      "customer_id": customer_id,
      "order_category_id": order_category_id,
      "currency": currency,
      "shipping_fee": shippingFee,
      "extras": extras,
      "warehouse": wareHouse_id,
      "notes": notes,
      "discount": discount,
      "vat": vatFee,
      "shipping": {
        "street": selectedUser?.address?.street,
        "city": selectedUser?.address?.city,
        "local_government": selectedUser?.address?.local_government,
        "state": selectedUser?.address?.state,
        "country": selectedUser?.address?.country,
        "postal_code": selectedUser?.address?.postal_code
      }
    }
    // console.log("orderdatas:", orderData);

    await axiosClient.post("order", orderData).then((response) => {
      if (response.status === 201) {
        toast.success("Order has been noted")
        navigate(`/order/sales/${response.data.id}`)
      }
    }).catch((error) => {
      toast.error(error.message)
      console.error("Order error", error);
    })

  }

  useEffect(() => {
    const fetchCustomers = async () => {
      await axiosClient.get('customer').then(function (response) {
        // handle success
        if (response.status === 200) {
          setCust({
            type: "SET_CUSTOMERS",
            payload: response.data
          })
        }
      })
        .catch(function (error) {
          console.log(error);
        })
    }

    const fetchProducts = async () => {
      await axiosClient.get('product').then(function (response) {
        // handle success
        if (response.status === 200) {
          setProd({
            type: "SET_PRODUCTS",
            payload: response.data
          })
        }
      })
        .catch(function (error) {
          console.log(error);
        })
    }

    const fetchOrderCategories = async () => {
      await axiosClient.get('order-category').then(function (response) {
        // handle success
        if (response.status === 200) {
          setOrderCate({
            type: "SET_ORDER_CATEGORIES",
            payload: response.data
          })
        }
      })
        .catch(function (error) {
          console.log(error);
        })
    }

    const fetchWareHouses = async () => {
      await axiosClient.get('warehouse').then((response) => {
        if (response.status === 200) {
          setWareHouses(response.data)
        }
      }).catch(() => {
        toast.error("Ware Houses could not be fetched")
      })
    }



    if (user) {
      fetchCustomers();
      fetchProducts();
      fetchOrderCategories();
      fetchWareHouses();
    }
  }, [setCust, user, setProd, setOrderCate]);
  return (
    <>
      <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        {/* <Header   title="Create Order"/> */}
        <div className="block p-6 rounded-lg shadow-lg bg-white ">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group mb-6">
                <select required title="Please select a customer"
                  name="customer_id" value={customer_id} onChange={(e) => handleChange(e)}
                  className="form-control block w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-input"
                  aria-describedby="" placeholder="Customer Name">
                  <option value="" >
                    -- Select customer --
                  </option>

                  {
                    customers && customers?.map((customer) => (
                      <option key={customer?.id} value={customer?.id}>{customer?.first_name + " " + customer?.last_name}</option>
                    ))
                  }
                  <option onClick={openAddCustomerModal}>ADD CUSTOMER</option>
                </select>
              </div>
              <div className="form-group mb-6">
                <input type="text"
                  name="mobile_number"
                  value={selectedUser?.mobile_number} onChange={(e) => setMobileNumber(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="Mobile Number" />
              </div>
            </div>
            {/* order date and delivery */}
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group mb-6">
                <label>Source</label>
                <select
                  required title="Please select an option"

                  name="source"
                  value={source} onChange={(e) => setSource(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                >
                  <option value=''>-- Select Source --
                  </option>

                  <option value="Ikoyi">Ikoyi</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Ibadan">Ibadan</option>
                  <option value="Website">Website</option>
                  <option value="WhatsApp">WhatsApp</option>


                </select>
              </div>
              <div className="form-group mb-6">
                <label>Delivery Date</label>
                <input type="date" required
                  name="deliveryDate"
                  value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
              </div>

            </div>

            {/* size and image */}
            <div className="grid grid-cols-3 gap-4">
              <div className="form-group mb-6">
                <select
                  required title="Please select an option"
                  name="order_category_id"
                  value={order_category_id} onChange={(e) => setCategory(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                >
                  <option value=''>-- Select Order Category --
                  </option>
                  {
                    orderCategories?.map((orderCategory) => (
                      <option key={orderCategory?.id} value={orderCategory?.id}>{orderCategory?.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group mb-6">
                <select
                  required title="Please select an option"
                  name="currency"
                  value={currency} onChange={(e) => setCurrency(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="Product Image Link" >
                  <option value="NGN">Naira</option>
                  <option value="USD">Dollar</option>
                </select>
              </div>

              {/* Warehouse */}
              <div className="form-group mb-6">
                <select
                  required title="Please select a warehouse"
                  value={wareHouse_id} onChange={(e) => setWareHouse(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                >
                  <option value="">Select a Warehouse</option>
                  {
                    wareHouses?.map((warehouse_id, index) => (
                      <option key={index} className="block w-full" value={warehouse_id?.id}>{warehouse_id?.name}</option>
                    ))
                  }
                </select>
              </div>

            </div>





            {/* <div className="form-group mb-6">
              <textarea

                className="form-control block
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput125"
                placeholder="description" />
            </div> */}

            {/* start */}
            {selectedUser && (
              <>
                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group mb-6">
                    <input
                      name="street"
                      value={selectedUser?.address?.street} onChange={(e) => setStreet(e.target.value)}
                      className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-input"
                      aria-describedby="" placeholder="Street" required />

                  </div>
                  <div className="form-group mb-6">
                    <input type="text"
                      name="local_government"
                      value={selectedUser?.address?.local_government} onChange={(e) => setLocalGovernment(e.target.value)}
                      className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-describedby="" placeholder="local Government" />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group mb-6">
                    <input
                      name="state"
                      value={selectedUser?.address?.state} onChange={(e) => setState(e.target.value)}
                      className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-input"
                      aria-describedby="" placeholder="State" required />

                  </div>
                  <div className="form-group mb-6">
                    <input type="text"
                      name="country"
                      value={selectedUser?.address?.country} onChange={(e) => setCountry(e.target.value)}
                      className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-describedby="" placeholder="Country" />
                  </div>
                </div>



                <div className="grid grid-cols-2 gap-4">
                  <div className="form-group mb-6">
                    <input
                      name="city"
                      value={selectedUser?.address?.city} onChange={(e) => setCity(e.target.value)}
                      className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-input"
                      aria-describedby="" placeholder="City" required />

                  </div>
                  <div className="form-group mb-6">
                    <input type="text"
                      name="postal_code"
                      value={selectedUser?.address?.postal_code} onChange={(e) => setPostalCode(e.target.value)}
                      className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      placeholder="Postal Code" />
                  </div>
                </div>


                <div className="py-6 border-t border-coolGray-100">
                  {/* product item  start */}

                  {
                    orderItems.map((orderItem, index) => (
                      <div className="grid grid-cols-5 gap-4">
                        <div className="form-group mb-6">
                          <select
                            required title="Please select an option"
                            onChange={event => handleChangeInput(index, event)} name="product" value={orderItem.product}
                            className="form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-input"
                            aria-describedby="" r>
                            <option value="" >
                              -- Select Product --
                            </option>
                            {
                              products?.map((product) => (
                                <option key={product?.id} value={product?.id}>{product?.name}</option>
                              ))
                            }
                          </select>

                        </div>
                        <div className="form-group mb-6">
                          <input type="text"
                            required
                            name="quantity"
                            value={orderItem.quantity} onChange={event => handleChangeInput(index, event)}
                            className="form-control
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Quantity" />
                        </div>

                        {/* total  starts*/}
                        <div className="form-group mb-6">
                          <input type="text"
                            required
                            name="total_"
                            value={orderItem.total_}
                            className="form-control
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Total_" />
                        </div>
                        {/* total ends*/}


                        <div className="form-group mb-6">
                          {(orderItems.length !== 1) ? <button onClick={handleRemoveFields} type="button" className="flex flex-wrap justify-center w-full px-4 py-2 bg-red-500 hover:bg-red-600 font-medium text-sm text-white border border-red-500 rounded-md shadow-button">
                            <p>Remove</p>
                          </button> : ''}
                        </div>
                        <div className="w-full md:w-auto p-1.5">
                          <button onClick={() => handleAddFields()} type="button" className="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button">
                            <p>Add New</p>
                          </button>
                        </div>
                      </div>
                    ))
                  }


                  {/* product item end */}
                </div>
              </>
            )}

            <div className="form-group mb-6">
              <textarea
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-describedby="" placeholder="Notes" ></textarea>
            </div>

            {/* Extra charges */}
            <div className="grid grid-cols-4 gap-4">
              <div className="form-group mb-6">
                <input
                  type="number"
                  name="discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-input"
                  aria-describedby="" placeholder="discount" />

              </div>
              <div className="form-group mb-6">
                <input type="number"
                  name="vatFee"
                  value={vatFee}
                  onChange={(e) => setVat(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="VAT" />
              </div>
              <div className="form-group mb-6">
                <input type="number"
                  name="shipping_fee"
                  value={shippingFee}
                  onChange={(e) => setShippingFee(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="Shipping Fee" required />
              </div>

              {/* extras start */}
              <div className="form-group mb-6">
                <input type="number"
                  name="extras"
                  value={extras}
                  onChange={(e) => setExtras(e.target.value)}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="Extras" required />
              </div>
              {/* extras end*/}
            </div>


            <button type="submit" disabled={submitting} className="
w-full
px-6
py-2.5
bg-blue-600
text-white
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-blue-700 hover:shadow-lg
focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-blue-800 active:shadow-lg
transition
duration-150
ease-in-out">Save Order</button>
          </form>
        </div>
      </div>
      <AddCustomer isOpenAddcustomer={isOpenAddcustomer} setOpenAddcustomer={setOpenAddcustomer} />

    </>

  )
}

export default AddSalesOrder