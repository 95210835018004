import React from 'react'
import { useState } from 'react';
import { Header } from '../../components';
import AddRole from '../../components/Role/AddRole';
import ListRoles from '../../components/Role/ListRoles';
import { useAuthContext } from '../../hooks/useAuthContext'

const FetchAllRole = () => {
    const { user } = useAuthContext();
    const [createBank, setCreateBank] = useState(false);
    const openModal = () => {
        setCreateBank(true);
    }
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="ROLES" buttonName="CREATE ROLE" openModal={openModal} />
            <ListRoles user={user} />
            <AddRole setCreateBank={setCreateBank} createBank={createBank} user={user} />
        </div>
    )
}

export default FetchAllRole