import { useNavigate } from "react-router-dom";
import { Header} from '../components';
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import {OrderProvider, useOrderContext } from '../contexts/OrderProvider';
const Orders = () => {
  let navigate = useNavigate();
  const {order, orderLoading} = useOrderContext();
  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category="Page" title="Orders" />
      <div className="flex flex-col">
  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead className="border-b">
            <tr>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                s/n
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
               Reference 
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
               Customer
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                status
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Delivery Date
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                {/* Action */}
              </th>
            </tr>
          </thead>
          <tbody>
            {
              order?.map((orde, index) =>(
                <tr className="border-b" key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index+1}</td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                 {orde?.reference_number}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {orde?.customer?.first_name + ' '+ orde?.customer?.last_name}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {orde?.status}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {orde?.deliveryDate}
                </td>
                
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                 <ul className="flex items-center justify-center">
                    <li className='py-1 px-2.5' ><FaEye /></li>
                    <li  className='py-1 px-2.5'><FaEdit /></li>
                    <li  className='py-1 px-2.5'><FaTrash /></li>
                 </ul>
                </td>
              </tr>
              ))
            } 
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Orders