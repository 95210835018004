
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
// import { useAuthContext } from "../../hooks/useAuthContext";
import { useCustomersContext } from "../../hooks/useCustomersContext";
const CustomerTable = ({ customers, goToCustomer, setOpen, setModalContent }) => {
    const { dispatch } = useCustomersContext();
 
    const handleDeleteClick = async(customer_id) => {
      const response = await axiosClient.delete('customer/delete/'+customer_id).then((result) => {
        if (result.status === 200) {
            toast.success("customer deleted");
            dispatch({
                type: "DELETE_CUSTOMER", payload: result.data
            })
        }
      }).catch((err) => {
        toast.error("something wrong");
      })
    
    }
    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Name
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Gender
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customers && customers?.map((cust, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {cust?.first_name + " " + cust?.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {cust?.gender}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <ul className="flex items-center justify-center">
                                                        <li className='py-1 px-2.5' data-modal-toggle="user-modal" onClick={() => goToCustomer(cust)}><FaEye /></li>
                                                        <li className='py-1 px-2.5' onClick={() => { setOpen(true); setModalContent(cust)}}><FaEdit /></li>
                                                        <li className='py-1 px-2.5' onClick={() => handleDeleteClick(cust?.id)}><FaTrash /></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerTable