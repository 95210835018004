import React, { useEffect, useState } from 'react';
import { Header } from '../../components';
import Receipt from '../../components/pdfs/Reciept';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import axiosClient from '../../axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const Reciepted = () => {
    const params = useParams();
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchReciept = async () => {
            setLoaded(false);
            try {
                const { data, status } = await axiosClient.get(`pos/customer-receipt/${params.id}`);
                if (status === 200) {
                    setData(data);
                    setLoaded(true);
                } else {
                    setError(true);
                    toast.error('Failed to fetch receipt.');
                }
            } catch (err) {
                setError(true);
                toast.error('An error occurred while fetching receipt.');
            } finally {
                setLoaded(true);
            }
        };

        fetchReciept();
    }, [params]);

    if (error) {
        return (
            <div className="flex justify-center items-center h-full">
                <h4 className="text-lg text-red-600 font-semibold">An error occurred. Please try again.</h4>
            </div>
        );
    }

    return (
        <div className="m-4 md:m-10 p-4 md:p-10 bg-white rounded-lg shadow-lg">
            <Header title="Print Receipt" />

            {loaded ? (
                data ? (
                    <div className="flex flex-col items-center">
                        {/* PDF Viewer */}
                        <div className="w-full lg:w-3/4 xl:w-2/3 mt-6">
                            <PDFViewer className="w-full h-[500px] lg:h-[700px] rounded-lg border-2 border-gray-200 shadow-md">
                                <Receipt data={data} />
                            </PDFViewer>
                        </div>

                        {/* Download Button */}
                        <div className="mt-6">
                            <PDFDownloadLink document={<Receipt data={data} />} fileName="receipt.pdf">
                                {({ loading }) =>
                                    loading ? (
                                        <button className="px-6 py-3 bg-gray-300 text-white rounded-md font-semibold" disabled>
                                            Loading...
                                        </button>
                                    ) : (
                                        <button className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition font-semibold shadow-lg">
                                            Download Receipt
                                        </button>
                                    )
                                }
                            </PDFDownloadLink>
                        </div>
                    </div>
                ) : (
                    <h4 className="text-center text-gray-500 mt-8">No receipt data available. Please wait...</h4>
                )
            ) : (
                <div className="flex justify-center items-center h-full">
                    <svg
                        className="animate-spin h-8 w-8 text-blue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default Reciepted;
