import React from 'react';

const OrderWorkOrderTable = ({ data }) => {
    return (
        <div className="overflow-x-auto">
            <table className="w-full table-auto whitespace-no-wrap">
                <thead>
                    <tr className="text-md font-semibold tracking-wide text-left text-gray-900 uppercase border-b-2 border-gray-400">
                        <th className="px-4 py-3">CTK</th>
                        <th className="px-4 py-3">Work Order</th>
                        <th className="px-4 py-3">Code</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {data?.workorder?.map((item) => {
                        const ctkList = item.ctk?.map((ctkItem) => (
                            <React.Fragment key={ctkItem.reference_number}>
                                <li className="text-gray-800 font-semibold">{ctkItem.reference_number}</li>
                                <li className="text-gray-400 text-sm">{ctkItem.status}</li>
                                <ul>
                                    {ctkItem.fp.map((fpItem) => (
                                        <li key={fpItem.id} className="text-gray-800 font-semibold">
                                            {fpItem.code}
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        ));

                        return (
                            <tr key={item.id}>
                                <td className="px-4 py-3 text-sm border">{ctkList}</td>
                                <td className="px-4 py-3 text-sm border">
                                    <ul>
                                        <li className="text-gray-800 font-semibold">{item.reference_number}</li>
                                        <li className="text-gray-400 text-sm">{item.status}</li>
                                    </ul>
                                </td>
                                <td className="px-4 py-3 text-sm border">
                                    <ul>
                                        {item.ctk?.map((ctkItem) => (
                                            <React.Fragment key={ctkItem.reference_number}>
                                                {ctkItem.fp.map((fpItem) => (
                                                    <li key={fpItem.id} className="text-gray-800 font-semibold">
                                                        {fpItem.code}
                                                    </li>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default OrderWorkOrderTable;
