import React, { useEffect, useState } from 'react'
import axiosClient from '../../../axios';

import { Header } from '../../../components';
import Spinner from '../../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import ListOrders from '../../../components/Orders/ListOrders';


const SalesOrder = () => {
    const [isLoading, setLoaded] = useState(false);
   const navigate = useNavigate();
    const goToNew = () => {
        navigate("/order/sales/add");
      }

      const goToOrderDetails = (orde) => {
        navigate(`/order/sales/${orde.id}`);
      }
    return (

        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            {isLoading ? <Spinner /> : <>
            <Header category="Page" title="Sales Orders" buttonName="New" openModal={goToNew}/>
            <ListOrders goToOrderDetails={goToOrderDetails} orderCategoryID="1" />
            </>
            }
        </div>
    )
}

export default SalesOrder