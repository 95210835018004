import { useContext } from "react"
import { ExpensesContext } from "../contexts/Expenses/ExpensesContext";
// import { ExpenseContext } from "

export const useExpenseContext = () => {
    const context = useContext(ExpensesContext);

    if (!context) {
        throw Error("useExpenseContext must be used inside a ExpenseContextProvider")
    }

    return context;
}