

const Loading = ({ isLoading }) => {
    return (
        // <div
        //     className={`${isLoading ? 'fixed' : 'hidden'
        //         } inset-0 flex items-center justify-center bg-gray-900 text-white`}
        // >
        //     <div className="text-4xl animate-pulse">
        //         <span className="inline-block mr-1">Loading</span>
        //         <span className="inline-block w-4 h-4 bg-white rounded-full"></span>
        //     </div>
        // </div>
        <>
            {
                isLoading && (
                    <div className="flex justify-center items-center h-screen">
                        <div className="text-gray-300 text-center text-5xl">


                            <div className="text-4xl animate-pulse">
                                <span className="inline-block mr-1">Loading</span>
                                <h2 className="inline-block w-4 h-4 bg-gray-900 rounded-full"></h2>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );
};

export default Loading;
