import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosClient from '../axios';
const CustomerContext = createContext();

export const CustomerProvider = ({children}) => {
    const [customer, setCustomer] = useState(); 

    const [viewCustomer, setViewCustomer] = useState();
    const [customerLoading, setCustomerLoading] = useState(false);

    useEffect(() => {
      checkLogin();
    }, []);

    const checkLogin = async () => {
        const token = localStorage.getItem("token");
        setCustomerLoading(true);

        if(token){
            axiosClient.get('/customer').then((response) => {
                const customerData = response.data;
                setCustomer(customerData);
                setCustomerLoading(false);
            });
        }else{
            setCustomer(null);
            setCustomerLoading(false); 
        }

        // if(token){
        //      axiosClient.get('/customer' , { headers: {"Authorization" : `Bearer ${token}`} }).then((response) => {
        //         const customerData = response.data;
        //         setCustomer(customerData);
        //         setCustomerLoading(false);
        //     });
        // }
    }
    

    return (
        <CustomerContext.Provider value={{customer, setCustomer, customerLoading}}>
            {children}
        </CustomerContext.Provider>
    );
};

export const useCustomerContext = () => useContext(CustomerContext);