
import { Header } from "../../components";
import { useEffect } from "react";
import ListOfProducts from "../../components/products/ListOfProducts";
import axiosClient from "../../axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useProductsContext }  from "../../hooks/useProductsContext";
import { useNavigate } from "react-router-dom";
// import { useProductContext } from "../../contexts/ProductProvider";
const ProductList = () => {
  const navigate = useNavigate();
  const createProduct = () => {
   navigate("/product/create");
  }
  const goToProduct = (productId) => {
    navigate(`/product/${productId}`);
  }

  const goToEditProduct = (id) => {
    navigate(`/product/edit/${id}`);
  }
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="Products"   buttonName="New"   openModal={createProduct}/>
            <ListOfProducts goToProduct={goToProduct} goToEditProduct={goToEditProduct}/>
        </div>
    )
}

export default ProductList