import { useEffect, useState } from "react";
import { FaEye, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCuttingTicketsContext } from "../../hooks/useCuttingTicket";
import StatusButton from "../Utils/StatusButton";
const commonCellClasses = "px-6 py-4 text-sm text-gray-900";
const CuttingTicketList = ({ goToCuttingTicket, setModalContent, setOpenEdit }) => {
    const { cuttingTickets, dispatch } = useCuttingTicketsContext();
    const { user } = useAuthContext();
    const [search, setSearch] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit] = useState(30); // Set default limit for pagination
    let navigate = useNavigate();

    const handleDeleteClick = async (cutting_ticket_id) => {
        try {
            const result = await axiosClient.delete(`cutting-ticket/delete/${cutting_ticket_id}`);
            if (result.status === 200) {
                toast.success("Cutting ticket deleted");
                dispatch({ type: "DELETE_CUTTING_TICKET", payload: result.data });
            }
        } catch (error) {
            toast.error("Something went wrong, please try again");
        }
    };

    const goToWorkOrderDetails = (id) => {
        id ? navigate(`/workorder/${id}`) : toast.error("No ID found");
    };

    const fetchCuttingTickets = async (page, search, status) => {
        try {
            const response = await axiosClient.get("cutting-ticket", {
                params: {
                    page,
                    limit,
                    reference_number: search, // Search by reference number (code)
                    status: statusFilter, // Filter by status
                }
            });
            if (response.status === 200) {
                setTotalPages(response.data.totalPages);
                dispatch({ type: "SET_CUTTING_TICKETS", payload: response.data.data });
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch data when component mounts or search and page changes
    useEffect(() => {
        if (user) {
            fetchCuttingTickets(page, search, statusFilter);
        }
    }, [user, page, search, statusFilter]);

    // Pagination handler
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <>
            <div className="flex flex-col">
                {/* Search and filter section */}
                <div className="flex justify-between items-center mb-4">
                    <input
                        type="text"
                        placeholder="Search by reference code"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="border px-4 py-2 rounded-md"
                    />
                    <select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        className="border px-4 py-2 rounded-md"
                    >
                        <option value="">All Statuses</option>
                        <option value="completed">Completed</option>
                        <option value="pending">Pending</option>
                        <option value="status">In Progress</option>
                    </select>
                </div>

                {/* <div className="overflow-x-auto">
                    <table className="min-w-full table-auto">
                        <thead className="border-b">
                            <tr>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">S/N</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">Cutting Ticket Reference</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">Product</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">Product Code</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">Purpose</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">Status</th>
                                <th className="px-6 py-4 text-center text-sm font-medium text-gray-900">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cuttingTickets?.map((cuttingTick, index) => (
                                <tr className="border-b" key={index}>
                                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                                        {index + 1 + (page - 1) * limit}
                                    </td>
                                    <td
                                        className="px-6 py-4 text-sm text-gray-900 cursor-pointer"
                                        onClick={() => goToWorkOrderDetails(cuttingTick?.workorder?.id)}
                                    >
                                        {cuttingTick?.reference_number}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{cuttingTick?.workorder?.productWorkOrder?.name}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">
                                    {
                                        cuttingTick?.fp?.map((item, itemIndex) => (
                                        <tr key={itemIndex}>
                                            <td>{item.code ?? "NOT GENERETeD"}</td>
                                        </tr>
                                    ))
                                    
                                    }
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{cuttingTick?.purpose}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">
                                        <StatusButton status={cuttingTick?.status} />
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900 flex justify-center space-x-4">
                                        <FaEye className="cursor-pointer" onClick={() => goToCuttingTicket(cuttingTick)} />
                                        <FaEdit
                                            className="cursor-pointer"
                                            onClick={() => { setOpenEdit(true); setModalContent(cuttingTick); }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}

                

<div className="overflow-x-auto">
    <table className="min-w-full table-auto">
        <thead className="border-b">
            <tr>
                {['S/N', 'Cutting Ticket Reference', 'Product', 'Product Code', 'Purpose', 'Status', 'Actions'].map((header) => (
                    <th key={header} className={`${commonCellClasses} font-medium text-left`}>{header}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {cuttingTickets?.map((cuttingTick, index) => {
                const { workorder, reference_number, fp, purpose, status } = cuttingTick;
                const productName = workorder?.productWorkOrder?.name;

                return (
                    <tr className="border-b" key={cuttingTick?.id || index}>
                        <td className={commonCellClasses}>
                            {index + 1 + (page - 1) * limit}
                        </td>
                        <td
                            className={`${commonCellClasses} cursor-pointer`}
                            onClick={() => goToWorkOrderDetails(workorder?.id)}
                        >
                            {reference_number}
                        </td>
                        <td className={commonCellClasses}>{productName}</td>
                        <td className={commonCellClasses}>
                            {fp?.length ? (
                                <ul>
                                    {fp.map((item, itemIndex) => (
                                        <li key={item?.id || itemIndex}>
                                            {item.code ?? "NOT GENERATED"}
                                        </li>
                                    ))}
                                </ul>
                            ) : "N/A"}
                        </td>
                        <td className={commonCellClasses}>{purpose}</td>
                        <td className={commonCellClasses}>
                            <StatusButton status={status} />
                        </td>
                        <td className={`${commonCellClasses} flex justify-center space-x-4`}>
                            <FaEye className="cursor-pointer" onClick={() => goToCuttingTicket(cuttingTick)} />
                            <FaEdit
                                className="cursor-pointer"
                                onClick={() => { setOpenEdit(true); setModalContent(cuttingTick); }}
                            />
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>
</div>


                {/* Pagination controls */}
                <div className="flex justify-between items-center mt-4">
                    <button
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1}
                        className="px-4 py-2 bg-gray-300 rounded-md disabled:bg-gray-200"
                    >
                        Previous
                    </button>
                    <span className="text-sm text-gray-700">Page {page} of {totalPages}</span>
                    <button
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page === totalPages}
                        className="px-4 py-2 bg-gray-300 rounded-md disabled:bg-gray-200"
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default CuttingTicketList;
