import React from "react";

function DateFormat({ date }) {
    const formattedDate = new Date(date).toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        // hour: "2-digit",
        // minute: "2-digit",
        // hour12: false,
    });

    return <span>{formattedDate}</span>;
}

export default DateFormat;
