import { useState } from "react";
import { Header } from "../../components";
import AddWareHouse from "../../components/Modals/WareHouse/AddWareHouse";
import EditWareHouse from "../../components/Modals/WareHouse/EditWareHouse";
import ListOfWareHouses from "../../components/WareHouse/ListOfWareHouses";
import { useAuthContext } from "../../hooks/useAuthContext";

const WareHousesList = () => {
  const {user} = useAuthContext();
  const [isOpen, setOpen] = useState(false);
  const [isOpenAddWareHouse, setOpenAddWareHouse] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const openModal = () => {
    setOpenAddWareHouse(true);
  }
  return (
    <>
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header  title="WareHouse" buttonName="New" openModal={openModal}/>
      <ListOfWareHouses  user={user} setOpen={setOpen} setModalContent={setModalContent}/>
      <AddWareHouse user={user} isOpenAddWareHouse={isOpenAddWareHouse} setOpenAddWareHouse={setOpenAddWareHouse}/>
      <EditWareHouse modalContent={modalContent} setOpen={setOpen} isOpen={isOpen} user={user}/>
    </div>
    </>
  )
}

export default WareHousesList