import React from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import Modal from '../../components/Modal';
import { useBanksContext } from '../../hooks/useBanksContext';

const AddBank = ({user,isOpenAddBank, setOpenAddBank }) => {
    const { dispatch } = useBanksContext();
    const [bank, setBank] = useState("");
    const [account_number, setAccountNumber] = useState("");
    const [holder, setHolder] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            toast.error("you need to log in")
            return;
        } 

        const data = {
            "bank": bank,
            "account_number": account_number,
            "holder": holder
        }

        axiosClient.post("/payment-account", data).then((response) => {
            if (response.status === 201) {
                setBank("");
                toast.success("Bank added");
                dispatch({
                    type: "CREATE_BANK",
                    payload: response.data
                })
                setOpenAddBank(false);
            }


        }).catch((error) => {
            toast.error("Something wrong, please try in few mins");
        })
    }

    return (
        <>
            <Modal isOpen={isOpenAddBank} close={() => setOpenAddBank(false)} modalhead="New Bank">
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Bank Name</label>
                                <input onChange={(e) => setBank(e.target.value)} value={bank} type="text" name="bank" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Account Number</label>
                                <input onChange={(e) => setAccountNumber(e.target.value)} value={account_number} type="text" name="account_number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Bank Holder Name</label>
                                <input onChange={(e) => setHolder(e.target.value)} value={holder} type="text" name="bank" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>

                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddBank