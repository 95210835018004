import React, { useState } from 'react'
import { Header } from '../../components'
import AddMaterial from '../../components/Materials/AddMaterial'
import MaterialLists from '../../components/Materials/MaterialLists'
import { useAuthContext } from '../../hooks/useAuthContext'

const ListMaterial = () => {
    const {user} = useAuthContext();
    const [isOpen, setOpen] = useState(false);
    const openModal = () => {
        setOpen(true);
      }
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="Materials" buttonName="Addd Materials" openModal={openModal}/>
            <MaterialLists user={user} />
            <AddMaterial isOpen={isOpen} setOpen={setOpen} user={user} />
        </div>
    )
}

export default ListMaterial