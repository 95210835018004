import { useReducer } from "react";
import { createContext } from "react";

export const FinishedProductsContext = createContext();

export const FinishedProductReducer = (state, action) => {
    switch (action.type) {
        case "SET_FINISHED_PRODUCTS":
            return {
                finished_Products: action.payload
            };
        case "SET_FINISHED_PRODUCTS_UNPAGINATED":
            return {
                finished_Products: action.payload
            }
            case "UPDATE_FINISHED_PRODUCTS":
                return {
                    stocks: state.finished_Products.map((fp) => {
                        if (fp.id === action.payload.id) {
                            return { ...fp, ...action.payload };
                        } else {
                            return fp;
                        }
                    })
                };

        default:
            return state;
    }
};


export const FinishedProductsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(FinishedProductReducer, {
        finished_Products: null
    });
    const contextValue = { finished_Products: state.finished_Products, dispatch };
    return (
        <FinishedProductsContext.Provider value={contextValue}>
            {children}
        </FinishedProductsContext.Provider>
    );
};