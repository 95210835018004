import { createContext, useReducer } from "react";

export const WebsiteOrdersContext = createContext();

export const WebsiteOrdersReducer = (state, action) => {
    switch (action.type) {
        case "SET_WEBSITE_ORDERS":
            return {
                websiteOrders: action.payload
            }

            break;

        default:
            return state;
    }
}

export const WebsiteOrdersContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(WebsiteOrdersReducer, {
        websiteOrders: null
    })

    return (
        <WebsiteOrdersContext.Provider value={{ ...state, dispatch }}>
            {children}
        </WebsiteOrdersContext.Provider>
    )
}