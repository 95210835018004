import { createContext, useReducer } from "react";

export const GiftCardContext = createContext();

export const GiftCardReducer = (state, action) => {
    switch (action.type) {
        case "SET_GIFTCARD":
            return {
                giftcards: action.payload
            }
        case "CREATE_GIFTCARD":
            return {
                giftcards: [...action.payload, ...state.giftcards]
            }
    }
}

export const GiftcardContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(GiftCardReducer, {
        giftcards: null
    });
    return (
        <GiftCardContext.Provider value={{ ...state, dispatch }}>
            {children}
        </GiftCardContext.Provider>
    )
}