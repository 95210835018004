const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const handlePrev = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <div className="flex justify-center items-center space-x-2 mt-4">
            <button onClick={handlePrev} disabled={currentPage === 1} className="p-2 border">
                Previous
            </button>
            <span>{currentPage} of {totalPages}</span>
            <button onClick={handleNext} disabled={currentPage === totalPages} className="p-2 border">
                Next
            </button>
        </div>
    );
};

export default Pagination;
