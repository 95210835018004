import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../../axios';
import { useFinishedProductContext } from '../../../hooks/useFinishedProductsContext';
import Spinner from '../../Spinner';
import Transfer from '../../Modals/FinishedProduct/Transfer';

const FinishedProductList2 = () => {
    const [isOpen, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNum, setCurrentNum] = useState(0);
    const [limit, setLimit] = useState(20);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { finished_Products, dispatch } = useFinishedProductContext();

    const fetchFinishedProducts = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data, status: responseStatus } = await axiosClient.get('/finished-products', {
                params: {
                    page: page,
                    limit: limit,
                    search: search,
                    status: status
                }
            });
            if (responseStatus === 200) {
                dispatch({
                    type: "SET_FINISHED_PRODUCTS",
                    payload: data.results
                });
                setTotalPages(data.totalPages);
                setCurrentNum((page - 1) * limit);
            } else {
                toast.error("An error occurred while fetching finished products");
            }
        } catch (error) {
            toast.error("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, page, limit, search, status]);

    useEffect(() => {
        fetchFinishedProducts();
    }, [fetchFinishedProducts]);

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debounceSearch(value);
    };

    const debounceSearch = useCallback(
        debounce((value) => {
            setPage(1);
            fetchFinishedProducts();
        }, 500),
        [fetchFinishedProducts]
    );

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
        setPage(1);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
        setPage(1);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        fetchFinishedProducts();
    };

    if (isLoading) {
        return <Spinner />;
    }

    const handleTransferClick = (fh) => {
     
        setModalContent(fh);
        setOpen(true);
    };

    return (
        <>
            <div className="flex flex-col">
                <div className="mb-4 flex justify-between items-center">
                    <form onSubmit={handleSearchSubmit} className="flex space-x-2">
                        <input
                            type="text"
                            placeholder="Search by code or name"
                            value={search}
                            onChange={handleSearchChange}
                            className="border border-gray-300 p-2 rounded-md"
                        />
                        <select
                            value={status}
                            onChange={handleStatusChange}
                            className="border border-gray-300 p-2 rounded-md"
                        >
                            <option value="">All Status</option>
                            <option value="pending">Available</option>
                            <option value="sold">Not Available</option>
                        </select>
                        <button type="submit" className="bg-blue-500 text-white p-2 rounded-md">
                            Search
                        </button>
                    </form>
                    <select
                        value={limit}
                        onChange={handleLimitChange}
                        className="border border-gray-300 p-2 rounded-md"
                    >
                        <option value={10}>10 per page</option>
                        <option value={25}>25 per page</option>
                        <option value={50}>50 per page</option>
                        <option value={100}>100 per page</option>
                    </select>
                </div>
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">s/n</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Image</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Product Name</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Product Code</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Warehouse</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Purpose</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Status</th>
                                        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {finished_Products && finished_Products.map((fh, index) => (
                                        <tr className="border-b" key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {currentNum + index + 1}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                <img className="w-12 h-12 rounded-full object-cover" src={fh?.item?.image} alt="imaged" />
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {fh?.item?.name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {fh?.code}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {fh?.item_warehouse?.name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {fh?.item_cutting_ticket?.purpose}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {fh?.status === "pending" ? (
                                                    <small className='py-1 px-2.5 rounded-r-lg bg-blue-300 cursor-pointer hover:bg-blue-500 hover:text-white'>Available</small>
                                                ) : (fh?.status === "sold" ? (
                                                    <small className='py-1 px-2.5 rounded-r-lg bg-red-300 cursor-pointer hover:bg-red-500 hover:text-white'>Not Available</small>
                                                ) : (
                                                    <small className='py-1 px-2.5 rounded-r-lg bg-yellow-300 cursor-pointer hover:bg-yellow-500 hover:text-white'>UNKNOWN</small>
                                                ))}
                                            </td>
                                            {/* <td>
                                                {(fh?.item_cutting_ticket?.purpose === "stock" && fh?.status === "pending") ? (
                                               
                                                    <small className='py-1 px-2.5 rounded-r-lg bg-blue-300 cursor-pointer hover:bg-blue-500 hover:text-white' onClick={() => { setModalContent(fh); setOpen(true);  }}>TRANSFER</small>
                                                ) : ((fh?.item_cutting_ticket?.purpose === "order" && fh?.status === "pending") ? (
                                                    <small className='py-1 px-2.5 rounded-r-lg bg-red-300 cursor-pointer hover:bg-red-500 hover:text-white'>ORDER</small>
                                                ) : (
                                                    <small className='py-1 px-2.5 rounded-r-lg bg-yellow-300 cursor-pointer hover:bg-yellow-500 hover:text-white'>UNKNOWN</small>
                                                ))}
                                            </td> */}
                                            <td>
                                            {(fh?.item_cutting_ticket?.purpose === "stock" && fh?.status === "pending") ? (
                                                <small className='py-1 px-2.5 rounded-r-lg bg-blue-300 cursor-pointer hover:bg-blue-500 hover:text-white' onClick={() => handleTransferClick(fh)}>TRANSFER</small>
                                            ) : ((fh?.item_cutting_ticket?.purpose === "order" && fh?.status === "pending") ? (
                                                <small className='py-1 px-2.5 rounded-r-lg bg-red-300 cursor-pointer hover:bg-red-500 hover:text-white'>ORDER</small>
                                            ) : (
                                                <small className='py-1 px-2.5 rounded-r-lg bg-yellow-300 cursor-pointer hover:bg-yellow-500 hover:text-white'>UNKNOWN</small>
                                            ))}
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* Pagination */}
            <div className="flex justify-center my-4">
                <button
                    className={`bg-gray-200 hover:bg-gray-300 rounded-md py-2 px-4 mr-2 ${page === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handlePrevPage}
                    disabled={page === 1}
                >
                    Prev
                </button>
                <button
                    className={`bg-gray-200 hover:bg-gray-300 rounded-md py-2 px-4 ${page === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                >
                    Next
                </button>
            </div>
            {/* Transfer Modal */}
            <Transfer isOpen={isOpen} setOpen={setOpen} modalContent={modalContent} fetchFinishedProducts={fetchFinishedProducts} />
        </>
    );
};

export default FinishedProductList2;
