import { useRef} from "react";
import Modal from '../../Modal'
import  axiosClient  from "../../../axios.js";
import { useCustomersContext } from "../../../hooks/useCustomersContext";
const EditCustomer = ({ modalContent, setOpen, isOpen}) => { 
  const { dispatch } = useCustomersContext();
  const refEmail = useRef(null);
  const refPhone = useRef(null);
  const refFirst = useRef(null);
  const refLast = useRef(null);
 
  const handleSubmit =  async(e, customer_id) => {
    e.preventDefault();
    const customerData = {

      "first_name": refFirst.current.value,
      "last_name": refLast.current.value,
      "email": refEmail.current.value,
      "mobile_number": refPhone.current.value,
    }
    // console.log("customerData", customerData);

     await axiosClient.put(`customer/${customer_id}`, customerData).then(
      async (response) => {

        if (response.status === 200) {
          setOpen(false);
          dispatch({
            type: "UPDATE_CUSTOMER",
            payload: response.data
        })
        } else Promise.reject();
      }
    ).catch((error) => {
      console.error("Order error", error);
    })

  }
  
  return (
        <>
         <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead={modalContent?.first_name}>
        <form onSubmit={e => handleSubmit(e, modalContent?.id)} >
          <div className="p-6 space-y-6">

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="first-name" className="text-sm font-medium text-gray-900 block mb-2">First Name</label>
                {/* <input type="text" name="first_name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.first_name} onChange={(e) => setfirstName(e.target.value)} /> */}
                <input type="text" name="first_name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refFirst} defaultValue={modalContent?.first_name} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="last_name" className="text-sm font-medium text-gray-900 block mb-2">Last Name</label>
                {/* <input type="text" name="last_name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.last_name} onChange={(e) => setLastName(e.target.value)} /> */}
                <input type="text" name="last_name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refLast} defaultValue={modalContent?.last_name} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="email" className="text-sm font-medium text-gray-900 block mb-2">Email</label>
                {/* <input type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.email} onChange={(e) => setEmail(e.target.value)} /> */}
                <input type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refEmail} defaultValue={modalContent?.email} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="phone-number" className="text-sm font-medium text-gray-900 block mb-2">Phone Number</label>
                {/* <input type="number" name="mobile_number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" defaultValue={modalContent?.mobile_number} required onChange={(e) => setMobileNumber(e.target.value)} /> */}
                <input type="number" name="mobile_number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" ref={refPhone} defaultValue={modalContent?.mobile_number} required />
              </div>

            </div>
          </div>
          <div className="items-center p-6 border-t border-gray-200 rounded-b">
            <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save all</button>
          </div>
        </form>
      </Modal>
        </>

    )
}

export default EditCustomer