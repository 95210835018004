import { useEffect, useRef } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { Header } from "../../components";
import { useAuthContext } from "../../hooks/useAuthContext";

const EditProduct = () => {
  const params = useParams();
  const { user } = useAuthContext();
  const [productDetails, setProductDetails] = useState(null);




  // const [productSize, setProductSize] = useState("");
  // const [productCode, setProductCode] = useState("");
  // const [productName, setProductName] = useState("");
  // const [productUSD, setProductUSD] = useState("");
  // const [productNGN, setProductNGN] = useState("");
  // const [productImage, setProductImage] = useState("");
  // const [productDescription, setProductDescription] = useState("");

  const productSize = useRef(null);
  const productCode = useRef(null);
  const productName = useRef(null);
  const productUSD = useRef(null);
  const productNGN = useRef(null);
  const productImage = useRef(null);
  const productDescription = useRef(null);





  const handleSubmit = async(e) => {
    e.preventDefault();
    const product = {
      name: productName.current.value,
      description: productDescription.current.value,
      code: productCode.current.value,
      size: productSize.current.value,
      image: productImage.current.value,
      price_usd: productUSD.current.value,
      price_ngn: productNGN.current.value
    }

    await axiosClient.put(`product/${params.id}`,product).then(
      async(response) => {
        if(response.status === 200){
          // setProductDetails(response.data)
          setProductDetails(product)
          toast.success("Product updated")
        }else Promise.reject();
      }
    ).catch((error) => {
      console.error("Product error", error);
    })
  }


  useEffect(() => {

    if (!user) {
      toast.error('you must be logged in');
      return
    }

    axiosClient.get(`product/${params.id}`)
      .then((response) => setProductDetails(response.data))
      .catch(() => toast.error("Something wrong"))

  }, [user])
  return (
    <>
      <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category="Edit Product" title={productDetails?.name} />
        <div className="block p-6 rounded-lg shadow-lg bg-white ">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group mb-6">
                <input type="text"
                  ref={productName} defaultValue={productDetails?.name}
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="Product Code" required />
              </div>
              <div className="form-group mb-6">
                <input type="text"
                ref={productCode} defaultValue={productDetails?.code}
                 
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
                  aria-describedby="" placeholder="Product Code" />
              </div>
            </div>
            {/* price */}
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group mb-6">
                <input type="number"
                  ref={productUSD} defaultValue={productDetails?.price_usd}
                  
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="NGN price" required />
              </div>
              <div className="form-group mb-6">
                <input type="number"
                 ref= {productNGN} defaultValue={productDetails?.price_ngn}
                  
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
                  aria-describedby="" placeholder="NGN price" />
              </div>
            </div>

            {/* size and image */}
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group mb-6">
                <input type="text"
                 ref= {productSize} defaultValue={productDetails?.size}
                  
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-describedby="" placeholder="Product Size" required />
              </div>
              <div className="form-group mb-6">
                <input type="url"
                ref= {productImage} defaultValue={productDetails?.image}
                 
                  className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput124"
                  aria-describedby="" placeholder="Product Image Link" />
              </div>
            </div>





            <div className="form-group mb-6">
              <textarea
              ref= {productDescription} defaultValue={productDetails?.description}
               
                className="form-control block
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput125"
                placeholder="description" />
            </div>
            {/* <div className="form-group mb-6">
<input type="password" className="form-control block
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput126"
  placeholder="Password"/>
</div> */}
            {/* <div className="form-group form-check text-center mb-6">
<input type="checkbox"
  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
  id="exampleCheck25" checked/>
<label className="form-check-label inline-block text-gray-800" for="exampleCheck25">Subscribe to our newsletter</label>
</div> */}
            <button type="submit" className="
w-full
px-6
py-2.5
bg-blue-600
text-white
font-medium
text-xs
leading-tight
uppercase
rounded
shadow-md
hover:bg-blue-700 hover:shadow-lg
focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
active:bg-blue-800 active:shadow-lg
transition
duration-150
ease-in-out">Update Product</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditProduct