import axios from "axios";



const axiosClient = axios.create({
    // baseURL: "http://localhost:8080/api/",
    baseURL: "https://erp.femihandbags.com/api/",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8"
    },
});
const user = JSON.parse(localStorage.getItem("user"));
if (user) {
    axiosClient.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${user.token}`
        return config;
    });
}



export default axiosClient;