import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image, Line } from '@react-pdf/renderer';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 25,
    },

    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },

    label: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 5,
        width: 150,
        // marginRight: 5,
        textAlign: 'right'
    },
    value: {
        marginBottom: 10,
        // flexGrow: 1,
        width: 150,
    },


    header: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10,
        textAlign: 'center',
    },

    value: {
        fontSize: 12,
        marginBottom: 5,
    },
    image: {
        width: "100%",
        height: "auto",
        marginBottom: 10,
    },
    smallText: {
        fontSize: 10,
    },
    row: {
        flexDirection: "row",
    },
    line: {
        marginTop: 0,
        borderWidth: 1,
        borderColor: '#000000',
    },
    space_up: {
        marginTop: 10,
    },
    textArea: {
        height: 50,
        fontSize: 10,
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        borderWidth: 1,
        borderColor: '#000000',
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    checkbox: {
        width: 10,
        height: 10,
        borderWidth: 1,
        borderColor: '#000',
        marginRight: 5,
    },
    labell: {
        fontSize: 12,
    },

    imageSpace: {
        marginTop: 20,
    }

});

const Checkbox = ({ checked, label }) => (
    <View style={styles.checkboxContainer}>
        <View style={styles.checkbox}>{checked && <View style={{ backgroundColor: '#000', flex: 1 }} />}</View>
        <Text style={styles.labell}>{label}</Text>
    </View>
);

const TwoColumnPdf = ({ data }) => {
    return (
        <Document>
            {/* <Page size="A4" style={styles.page} rotation="landscape"> */}
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={[styles.header]}>CUTTING TICKET - {data.reference_number}</Text>
                    <Text style={[styles.value]}> {data.workorder.productWorkOrder.name}</Text>
                    <Text style={[styles.value]}>{data.workorder.productWorkOrder.code} </Text>
                    <Image style={styles.image} resizeMode="contain" src={data.workorder.productWorkOrder.image} />

                    <View style={[styles.row, styles.imageSpace]}>
                        <Text style={[styles.label, styles.smallText]}>Category:</Text>
                        <Text style={[styles.value, styles.smallText]}>{data.purpose}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.label, styles.smallText]}>Status:</Text>
                        <Text style={[styles.value, styles.smallText]}>
                            {data.status == 'started' ? 'In Production' : data.status}
                        </Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={[styles.label, styles.smallText]}>Start Date:</Text>
                        <Text style={[styles.value, styles.smallText]}>{data.start_date}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.label, styles.smallText]}>Completion Date:</Text>
                        <Text style={[styles.value, styles.smallText]}>{data.complete_date}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.label, styles.smallText]}>Quantity:</Text>
                        <Text style={[styles.value, styles.smallText]}>{data.quantity}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.label, styles.smallText]}>Reference Number:</Text>
                        <Text style={[styles.value, styles.smallText]}>{data.reference_number}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.label, styles.smallText]}>Staff In Charge:</Text>
                        <Text style={[styles.value, styles.smallText]}>{`${data.user.first_name} ${data.user.last_name}`}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={[styles.label, styles.smallText]}>Customer:</Text>
                        <Text style={[styles.value, styles.smallText]}>{`${data?.workorder?.salesOrder?.customer?.first_name}`}  - {`${data?.workorder?.salesOrder?.customer?.last_name}`}</Text>
                    </View>
                    <View style={[styles.space_up]}>
                        <Text style={[styles.value]}> Comments/Remarks</Text>
                        <Line style={styles.line} />
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={[styles.label, styles.smallText]}>Required Materials: {data.reference_number}</Text>
                    <View style={[styles.space_up]}>
                        {/* <Text style={[styles.label, styles.smallText]}>Materials:</Text> */}
                        {data.workorder.productWorkOrder.productMaterial.length > 0 ? (
                            data.workorder.productWorkOrder.productMaterial.map((material, index) => (
                                <Text key={index} style={[styles.value, styles.smallText]}>
                                    {material.material.name}  _______
                                </Text>
                            ))
                        ) : (
                            <Text style={[styles.value, styles.smallText]}>Please add materials to the product</Text>
                        )}
                    </View>


                    <View style={[styles.space_up]}>
                        <Checkbox checked={false} label="BRASS" />
                        <Checkbox checked={false} label="NICKEL" />
                    </View>

                    <View style={[styles.space_up]}>
                        <Line style={styles.line} />
                        <Text style={styles.textArea}>
                            Lining
                        </Text>
                    </View>
                    <View style={[styles.space_up]}>
                        <Line style={styles.line} />
                        <Text style={styles.textArea}>
                            Aso-Oke
                        </Text>
                    </View>

                </View>
            </Page>
        </Document>
    );
};

export default TwoColumnPdf;
