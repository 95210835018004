import { useContext } from "react"
import { VendorsContext } from "../contexts/Expenses/VendorsContext";


export const useVendorContext = () => {
    const context = useContext(VendorsContext);

    if (!context) {
        throw Error("useVendorContext must be used inside a VendorContextProvider")
    }

    return context;
}