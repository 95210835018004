import React, { useState } from 'react'
import { Header } from '../../components';
import AddStaff from '../../components/Staff/AddStaff';
import ListOfStaff from '../../components/Staff/ListOfStaff';
import { useAuthContext } from '../../hooks/useAuthContext'

const FetchAllStaff = () => {
    const { user } = useAuthContext();
    const [openCreateStaff, setOpenCreateStaff] = useState(false);
    const openModal = () => {
        setOpenCreateStaff(true);
    }
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="Staff" buttonName="Create Staff" openModal={openModal}/>
            <ListOfStaff user={user} />
            <AddStaff user={user}  openCreateStaff={openCreateStaff} setOpenCreateStaff={setOpenCreateStaff}/>
        </div>
    )
}

export default FetchAllStaff