import { createContext, useReducer } from "react";

export const MaterialContext = createContext();

export const MaterialReducer = (state, action) => {
    switch (action.type) {
        case "SET_MATERIAL":
            return {
                materials: action.payload
            }
        case "CREATE_MATERIAL":
            return {
                materials: [...action.payload, ...state.materials]
            }

        case "DELETE_MATERIAL":
            return {
                materials: state.materials.filter((material) => material.id !== action.payload.id)
            }

        case "UPDATE_MATERIAL":
            return {
                materials: state.materials.filter((material) => material.id !== action.payload.id)
            }

        default:
            return state
    }
}

export const MaterialContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(MaterialReducer, {
        materials: null
    });
    return (
        <MaterialContext.Provider value={{ ...state, dispatch }}>
            {children}
        </MaterialContext.Provider>
    )
}