import React, { useEffect } from 'react'
import { Header } from '../../components'
import axiosClient from '../../axios';
import { toast } from 'react-toastify';
import { useGiftCardContext } from '../../hooks/useGiftCardContext';
import ListGiftcard from '../../components/Giftcard/ListGiftcard';
import AddGiftCard from '../../components/Giftcard/AddGiftCard';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useState } from 'react';

const GiftCard = () => {
    const [isOpen, setOpen] = useState(false);
    const { giftcards, dispatch } = useGiftCardContext();
    const { user } = useAuthContext();
    const openModal = () => {
        setOpen(true);
    }

    useEffect(() => {
        
        const fetchGiftCards = async () => {
       
            await axiosClient.get('gift-card').then(function (response) {
              
                if (response.status === 200) {
                    
                    dispatch({
                        type: "SET_GIFTCARD",
                        payload: response.data.data
                    })
                }
            }).catch(() => {
                toast.error("try again in seconds, could not fetch giftcards!");
            })


        }

        if (user) {
            fetchGiftCards();
        }
    }, [dispatch, user]);
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="Giftcards" buttonName="Add Giftcard" openModal={openModal} />
            <ListGiftcard giftcards={giftcards} />
            <AddGiftCard user={user} setOpen={setOpen} isOpen={isOpen}/>
        </div>
    )
}

export default GiftCard