import React, { useEffect, useState, Fragment, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import axiosClient from '../../../axios';
import { Menu, Transition } from '@headlessui/react';
import { FaChevronDown } from "react-icons/fa";
import Modal from '../../../components/Modal';
import { usePaymentAccountContext } from "../../../contexts/PaymentAccountProvider";
import { toast } from 'react-toastify';
import { useBanksContext } from '../../../hooks/useBanksContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import OrderWorkOrderTable from '../../../components/Orders/orderWorkOrderTable';
import MoneyFormat from '../../../components/Utils/MoneyFormat';
// import { useBanksContext} from "/"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const OrderDetails = () => {

    const params = useParams();
    const { banks, dispatch } = useBanksContext();
    const { user } = useAuthContext();
    const [order, setOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [isDelivery, setDelivery] = useState(false);
    const [modalDelivery, setModalDelivery] = useState('');
    const [modalContent, setModalContent] = useState('');
    const { paymentAccount } = usePaymentAccountContext();
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);



    const initialFormData = Object.freeze({
        amount: "",
        bank: "",
        payment_date: ""
    });

    const initialDelivery = Object.freeze({
        delivery_status: ""
    })

    const [deliveryData, updateDeliveryData] = useState(initialDelivery);

    const [formData, updateFormData] = useState(initialFormData);
    // const reload = () => window.location.reload();
    const handleChange = (e) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleDeliveryChange = (e) => {
        updateDeliveryData({
            ...deliveryData,
            [e.target.name]: e.target.value.trim()
        })
    }

    // start
    const fetchOrder = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axiosClient.get(`/order/sales/${params.id}`);
            setOrder(response.data);
        } catch (error) {
            toast.error('Failed to fetch order details');
        } finally {
            setIsLoading(false);
        }
    }, [params.id]);


    const fetchBanks = useCallback(async () => {
        try {
            const response = await axiosClient.get('/payment-account');
            if (response.status === 200) {
                dispatch({
                    type: "SET_BANKS",
                    payload: response.data
                })
            }
        } catch (error) {
            toast.error('Failed to fetch Bank details');
        } finally {
            setIsLoading(false);
        }
    },
        [],
    )



    // end

    useEffect(() => {
        fetchOrder();
        fetchBanks();
    }, [fetchOrder, fetchBanks]);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const sendMail = async (order) => {
        // e.preventDefault();
        await axiosClient.get(`/email/${order.id}`).then((response) => {
            if (response.status == 200) {
                toast.success('Email sent')
            }
        }).catch(() => {
            toast.error("Email could not be sent")
        })


    }

    const handleDeliverySubmit = async (e) => {
        e.preventDefault();
        const data = {
            "delivery_status": deliveryData.delivery_status
        }




        await axiosClient.put(`order/delivery_status/${params.id}`, data).then(
            async () => {

                toast.success("Delivery status Updated");
                fetchOrder();
                setDelivery(false)

            }
        ).catch((error) => {
            toast.error(error?.response?.data?.error)
        })


    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const customerData = {

            "amount": formData.amount,
            "payment_account_id": formData.bank,
            "payment_date": formData.payment_date
        }


        await axiosClient.post(`payment/${params.id}`, customerData).then(
            async () => {

                toast.success("payment recorded");
                fetchOrder();
                setOpen(false);
            }
        ).catch((error) => {
            console.error("Order error", error);
        })



    }

    const totalPayment = useMemo(
        () => order?.orderPayment?.reduce((acc, payment) => acc + Number(payment.amount), 0),
        [order]
    );
    const isPaid = useMemo(() => totalPayment >= order?.total, [order, totalPayment]);

    const amountDue = useMemo(
        () => isNaN(order?.total) || isNaN(totalPayment) ? NaN : Math.max(order?.total - totalPayment, 0),
        [order, totalPayment]
    );

    const sendReciept = async (payment) => {
        console.log("Payment", payment.id);

        await axiosClient.get(`email/generate-reciept/${payment.id}`).then((response) => {
            if (response.status == 200) {
                toast.success("Reciept has been sent");
            }

        }).catch(() => {
            toast.error("Reciept could not be generated")
        });

    }

    const makeWorkder = async () => {
        // console.log(1234567890);
        setIsLoading(true);
        await axiosClient.post(`/workorder/${params.id}`).then((response) => {
            if (response.status == 201) {
                // console.log(
                //     "response", response
                // )
                toast.success("Work Order Created");
                fetchOrder();
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message ?? "something Wrong");
            // console.log("ERROR", error);
        });


    }

    return (
        <div className='m-2 md:m-10 p-2 md:p-10  rounded-3xl'>
            <div className='mb-10'>
                <div className='flex items-start justify-between p-5'>
                    <div>
                        <p className='text-gray-400'>
                            {order?.status}
                        </p>
                        <p className='text-3xl font-extrabold tracking-tight text-slate-900'>{order?.customer?.first_name + " " + order?.customer?.last_name}</p>
                    </div>

                    <div>
                        <div className="items-center p-6  rounded-b">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                        Make
                                        <FaChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>


                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        onClick={() => { makeWorkder() }}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Work Order
                                                    </a>
                                                )}
                                            </Menu.Item>

                                            {
                                                order?.delivery_status !== "delivered" && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={() => {
                                                                    setDelivery(true);
                                                                    setModalDelivery(order);
                                                                }}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                Change Delivery Status
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )
                                            }

                                            <Menu.Item >
                                                {({ active }) => (



                                                    <a
                                                        href="#"
                                                        onClick={() => { setOpen(true); setModalContent(order) }}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Record Payment
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            {order?.status == "pending" && (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            onClick={() => {
                                                                sendMail(order);
                                                            }}
                                                            className={classNames(
                                                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                "block px-4 py-2 text-sm"
                                                            )}
                                                        >
                                                            Send Email
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            )}



                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

            </div>
            <div className="grid grid-cols-3 gap-6">
                <div className="bg-white shadow-md   p-2 md:p-10  rounded-2xl">
                    <div className="lg:col-span-2  lg:border-gray-200 lg:pr-8">
                        <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{order?.reference_number}</h1>
                        <div className='border-t border-gray-200 pt-4'>
                            <p className='font-medium text-gray-900'>Status</p>
                            <p className='mt-2 text-sm text-gray-500'>{order?.status}</p>
                        </div>
                        <div className="border-t border-gray-200 pt-4">
                            <p className="text-sm text-gray-900">
                                Delivery Status :
                                {order?.delivery_status === "pending" && (
                                    <small className="inline-block bg-blue-500 text-white px-2 py-1 rounded-md ml-2">
                                        pending
                                    </small>
                                )}
                                {order?.delivery_status === "delivered" && (
                                    <small className="inline-block bg-green-500 text-white px-2 py-1 rounded-md ml-2">
                                        delivered
                                    </small>
                                )}
                                {order?.delivery_status === "enroute" && (
                                    <small className="inline-block bg-yellow-500 text-white px-2 py-1 rounded-md ml-2">
                                        enroute
                                    </small>
                                )}
                                {order?.delivery_status === "lost" && (
                                    <small className="inline-block bg-red-500 text-white px-2 py-1 rounded-md ml-2">
                                        lost
                                    </small>
                                )}
                            </p>

                        </div>

                        <div className='border-t border-gray-200 pt-4'>
                            <p className='font-medium text-gray-900'>Order Type</p>
                            <p className='mt-2 text-sm text-gray-500'>{order?.category?.name}</p>
                            <p className='font-medium text-gray-900'>Warehouse</p>
                            <p className='mt-2 text-sm text-gray-500'>{order?.outlet?.name}</p>
                        </div>
                        <div className='border-t border-gray-200 pt-4'>
                            <p className='font-sm text-gray-600'>Order Date</p>
                            <p className='text-sm text-gray-500'>{formatDate(order?.orderDate)}</p>
                            <p className='font-sm text-gray-600'>Delivery Date</p>
                            <p className=' text-sm text-gray-500'>{formatDate(order?.deliveryDate)}</p>
                        </div>
                        <div className='border-t border-gray-200 pt-4'>
                            <p className='font-sm text-gray-600'>Discount : <MoneyFormat amount={order?.discount} /> </p>
                            <p className='font-sm text-gray-600'>VAT :<MoneyFormat amount={order?.vat} /></p>
                            <p className='font-sm text-gray-600'>Shipping Fee : <MoneyFormat amount={order?.shipping_fee} /> </p>
                            <p className='font-sm text-gray-600'>Extras : <MoneyFormat amount={order?.extras} /> </p>
                            <p className='font-sm text-gray-600'> Total: <MoneyFormat amount={order?.total} /></p>
                            {/* <p className='font-sm text-gray-600'> Payment Status: {isPaid ? "PAID" : "UNPAID"}</p> */}
                            <div>
                                <p className="font-sm text-gray-600">Payment Status:</p>
                                {isPaid ? (
                                    <small className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded">
                                        Paid
                                    </small>
                                ) : (
                                    <small className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                                        Unpaid
                                    </small>
                                )}
                            </div>
                            {isNaN(amountDue) ? null : (
                                <p className='font-sm text-gray-600'> Amount Due:  <MoneyFormat amount={amountDue} /></p>
                            )}

                        </div>
                    </div>

                </div>
                <div className="bg-white shadow-md   p-2 md:p-10  rounded-2xl col-span-2 overflow-hidden">

                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="min-w-full">
                                        <thead className="border-b">
                                            <tr>
                                                <th></th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    img
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Product
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Price
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Quantity
                                                </th>
                                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                    Total
                                                </th>


                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                order && order.orderItem && order?.orderItem?.map((orde, index) => (
                                                    <tr className="border-b" key={index}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                                                            <img className="w-12 h-12 rounded-full object-cover" src={orde?.product?.image} alt="imaged" />
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {orde?.product?.name}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {/* {orde?.currency == "NGN" ? orde?.product?.price_ngn : orde?.product?.price_usd} */}
                                                            <MoneyFormat amount={orde?.price} />

                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {orde?.quantity}
                                                        </td>
                                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            {/* {orde?.currency == "NGN" ? orde?.product?.price_ngn * orde?.quantity : orde?.product?.price_usd * orde?.quantity} */}
                                                            <MoneyFormat amount={orde?.price * orde?.quantity} />
                                                        </td>


                                                    </tr>
                                                ))
                                            }


                                        </tbody>
                                    </table>

                                </div>
                                <OrderWorkOrderTable data={order} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            {/* start  */}

            <div>
                <div className="border-t border-b py-4 mt-7 border-gray-200">
                    <div onClick={() => setShow(!show)} className="flex justify-between items-center cursor-pointer">
                        <p className="text-base leading-4 text-gray-800">Payment Status</p>
                        <button
                            className="
									cursor-pointer
									focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
									rounded
								"
                            aria-label="show or hide"
                        >
                            <svg className={"transform " + (show ? "rotate-180" : "rotate-0")} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 1L5 5L1 1" stroke="#4B5563" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className={"pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 " + (show ? "block" : "hidden")} id="sect">
                        {/* start  table */}
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Amount
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Reference
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Bank
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Date
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order?.orderPayment?.map((payment, index) => (
                                            <tr className="border-b" key={index}>
                                                {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td> */}
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {payment?.amount}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {payment?.reference_number}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {payment?.paymentAccount?.bank_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {/* {payment?.paymentAccount?.createdAt} */}
                                                    {formatDate(payment?.createdAt)} <br></br>
                                                    {formatDate(payment?.payment_date)}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {/* {
                                                        order?.status != "completed" && ( */}
                                                    <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="button" onClick={() => sendReciept(payment)}>Send Reciept</button>

                                                    {/* )
                                                    } */}

                                                </td>


                                            </tr>
                                        ))
                                    }

                                    <tr className="border-b">
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap font-bold">
                                            Total Payment
                                        </td>
                                        <td></td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap font-bold">
                                            {totalPayment}
                                        </td>
                                    </tr>


                                </tbody>
                            </table>

                        </div>
                        {/* end table */}
                    </div>
                </div>
            </div>
            {/* end  */}

            {/* start  Shipping Address */}

            <div>
                <div className="border-t border-b py-4 mt-7 border-gray-200">
                    <div onClick={() => setShowAdd(!showAdd)} className="flex justify-between items-center cursor-pointer">
                        <p className="text-base leading-4 text-gray-800">Shipping Adress</p>
                        <button
                            className="
									cursor-pointer
									focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
									rounded
								"
                            aria-label="show or hide"
                        >
                            <svg className={"transform " + (showAdd ? "rotate-180" : "rotate-0")} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 1L5 5L1 1" stroke="#4B5563" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className={"pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 " + (showAdd ? "block" : "hidden")} id="sect">
                        {/* start  table */}
                        <div className="overflow-hidden">


                            <div className='border-t border-gray-200 pt-4'>
                                <p className='text-sm text-gray-900'>Street : <span className="text-gray-700">{order?.shippingAddress?.street}</span></p>
                                <p className='text-sm text-gray-900'>Local Government: <span className="text-gray-700">{order?.shippingAddress?.local_government}</span></p>
                                <p className='text-sm text-gray-500'>City:  <span className="text-gray-700">{order?.shippingAddress?.city}</span></p>

                                <p className='text-sm text-gray-500'>state: <span className="text-gray-700">{order?.shippingAddress?.state}</span></p>
                                <p className='text-sm text-gray-500'>Country: <span className="text-gray-700">{order?.shippingAddress?.country}</span></p>

                            </div>
                            {/* <span>{order?.orders?.id}</span> */}
                        </div>
                        {/* end table */}
                    </div>
                </div>
            </div>
            {/* end  */}
            <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead={order?.customer?.first_name + " " + order?.customer?.last_name}>
                <form onSubmit={e => handleSubmit(e)} >
                    <div className="p-6 space-y-6">

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor="amount" className="text-sm font-medium text-gray-900 block mb-2">Amount</label>
                                <input type="number" name="amount" onChange={handleChange} placeholder='0.00' className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" />
                                {/* <input type="text" name="first_name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refFirst} defaultValue={modalContent?.first_name} /> */}
                            </div>
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor="amount" className="text-sm font-medium text-gray-900 block mb-2">Payment Date</label>
                                <input type="date" name="payment_date" onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" />
                            </div>
                            <div className="col-span-12 sm:col-span-12">
                                {/* start */}

                                <select name="bank" onChange={handleChange} className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" >
                                    <option disabled selected value="">Select One</option>
                                    {
                                        banks?.map((paymentAccoun, index) => (
                                            <option key={index} value={paymentAccoun?.id}>{paymentAccoun?.bank_name}</option>
                                        ))
                                    }
                                </select>

                                {/* end */}
                            </div>


                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save all</button>
                    </div>
                </form>
            </Modal>
            <Modal isOpen={isDelivery} close={() => setDelivery(false)} modalhead={order?.delivery_status}>
                <form onSubmit={e => handleDeliverySubmit(e)} >
                    <div className="col-span-12 sm:col-span-12">
                        <select name="delivery_status" onChange={handleDeliveryChange} className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" >
                            <option value="pending">Pending</option>
                            <option value="enroute">Enroute</option>
                            <option value="lost">Lost</option>
                            <option value="delivered">Delivered</option>

                        </select>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save all</button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default OrderDetails