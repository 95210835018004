import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosClient from '../axios';
const OrderCategoryContext = createContext();

export const OrderCategoryProvider = ({ children }) => {
    const [orderrCategory, setOrderCategory] = useState();
    const [orderCategoryLoading, setorderCategoryLoading] = useState(false);
    useEffect(() => {
        checkLogin();
    }, []);

    const checkLogin = async () => {
        const token = localStorage.getItem("token");
        setOrderCategory(true);

        if (token) {
            axiosClient.get('/order-category').then((response) => {
                const orderCategoryData = response.data;
                setOrderCategory(orderCategoryData);
                setorderCategoryLoading(false);
            });
        } else {
            setOrderCategory(null);
            setorderCategoryLoading(false);
        }
    }

    return (
        <OrderCategoryContext.Provider value={{ orderrCategory, setOrderCategory , orderCategoryLoading, setorderCategoryLoading}}>
            {children}
        </OrderCategoryContext.Provider>
    );
}

export const useOrderCategoryContext = () => useContext(OrderCategoryContext);