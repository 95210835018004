import { createContext, useReducer } from "react";

export const ExpensesContext = createContext();

export const ExpensesReducer = (state, action) => {
    switch (action.type) {
        case "SET_EXPENSES":
            return {
                expenses: action.payload
            }

            break;

        default:
            return state;
    }
}

export const ExpensesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ExpensesReducer, {
        expenses: []
    })

    return (
        <ExpensesContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ExpensesContext.Provider>
    )
}