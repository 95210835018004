
const getStatusColor = (status) => {
    switch (status) {
        case 'completed':
            return 'green';
        case 'pending':
            return 'red';
        case 'cancelled':
            return 'red';
        default:
            return 'blue';
    }
};

const StatusButton = ({ status }) => {
    const color = getStatusColor(status);

    return (
        <small
            className={`bg-${color}-500 hover:bg-${color}-700 text-white font-semibold py-2 px-4 rounded`}
        >
            {status === 'completed' || status === 'pending' || status === 'cancelled' || status === 'started'
                ? status
                : 'Unknown'}
        </small>
    );
};

export default StatusButton;
