import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useRoleContext } from '../../hooks/useRoleContext'
import Modal from '../Modal';

const AddRole = ({ user, createBank, setCreateBank }) => {

    const { dispatch } = useRoleContext();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [code, setCode] = useState('');

    useEffect(() => { 
        setTitle("");
        setDescription("");
        setCode("");
    }, []);

    const handleSubmit = useCallback( async(e) => {
        e.preventDefault();
        const data = {
            "title": title,
            "description": description,
            "code": code
        }

       await axiosClient.post("/role/add", data).then((response) => {
            if (response.status === 201) {
                toast.success("role created")
                setTitle(" ");
                setDescription(" ");
                setCode(" ");
                dispatch({
                    type: "CREATE_ROLE",
                    payload: response.data
                })
                setCreateBank(false)
            }
        }).catch(() => {
            toast.error("somethong wrong")
        })
    }, [title, description, code, dispatch, setCreateBank])

    return (
        <>
            <Modal isOpen={createBank} close={() => setCreateBank(false)} modalhead="CREATE ROLE">
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Title</label>
                                <input onChange={(e) => setTitle(e.target.value)} value={title} type="text" name="title" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Code</label>
                                <input onChange={(e) => setCode(e.target.value)} value={code} type="number" name="code" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>

                        </div>

                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Description</label>
                                <textarea onChange={(e) => setDescription(e.target.value)} value={description} name="description" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>

                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddRole