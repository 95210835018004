import React from 'react'
import ExpenseList from '../../components/Expenses/ExpenseList'
import { Header } from '../../components'

const Expenses = () => {
    return (
        <div>



            <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
                <Header title="Expenses" buttonName="Add Expenses" />
                <ExpenseList />

            </div>
        </div>
    )
}

export default Expenses