const CustomerOrderHistory = (props) => {
   
    const orderRows = props?.orders?.customer_orders?.map((order, index) => (
        <tr className="border-b" key={index}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{order?.reference_number}</td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{order?.status}</td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{order?.orderDate}</td>
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{order?.deliveryDate}</td>
        </tr>
    ));

    return (
        <table className="min-w-full">
            <thead className="border-b">
                <tr>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        s/n
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        CODE
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        STATUS
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Order Date
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Delivery Date
                    </th>
                </tr>
            </thead>
            <tbody>{orderRows}</tbody>
        </table>
    );
};

export default CustomerOrderHistory;
