
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Header } from '../components';
import { useState } from "react";
import axiosClient from "../axios";
import CustomerTable from "../components/Customer/CustomerTable";
import EditCustomer from "../components/Modals/Customer/EditCustomer";
import AddCustomer from "../components/Modals/Customer/AddCustomer";
import { useCustomersContext } from "../hooks/useCustomersContext";
import { useAuthContext } from "../hooks/useAuthContext";
const Customer = () => {

  let navigate = useNavigate();
  const { customers, dispatch } = useCustomersContext();
  const { user } = useAuthContext();



  const [isOpen, setOpen] = useState(false);
  const [isOpenAddcustomer, setOpenAddcustomer] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const goToCustomer = (customer) => {

    navigate(`/customer/${customer.id}`);
  }

  const openModal = () => {
    setOpenAddcustomer(true);
  }
  useEffect(() => {
    const fetchCustomers = async () => {
      await axiosClient.get('customer').then(function (response) {
        // handle success
        if (response.status === 200) {
          // setCustomers(response.data)
          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data
          })
        }
      })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    }

    if (user) {
      fetchCustomers()
    }
  }, [dispatch, user]);


  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Customers" buttonName="New" openModal={openModal} />
      <CustomerTable customers={customers} goToCustomer={goToCustomer} setOpen={setOpen} setModalContent={setModalContent} />
      <EditCustomer modalContent={modalContent} setOpen={setOpen} isOpen={isOpen} />
      <AddCustomer isOpenAddcustomer={isOpenAddcustomer} setOpenAddcustomer={setOpenAddcustomer} />
    </div>
  )
}

export default Customer