import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import Modal from '../Modal';

const AddProductMaterial = ({ isOpen, setOpen, materials, productId, showProduct }) => {
    // const { dispatch } = useMaterialContext();
    const [inputs, setInputs] = useState(['']);

    const handleChange = (e, index) => {
        const newInputs = [...inputs];
        newInputs[index] = e.target.value;
        setInputs(newInputs);
    };

    const handleAddInput = () => {
        setInputs([...inputs, '']);
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };


    useEffect(() => {
        setInputs(['']);
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Prepare form data
        const formData = inputs.map(input => ({ material_id: input, isPublish: 1, product_id: productId }));




        await axiosClient.post(`product-material/${productId}`, formData).then((response) => {
            if (response.status === 201) {
                setOpen(false)
                toast.success("Product Material created added")
                showProduct()

            }
        }).catch((error) => {
            toast.error("something wrong")
        })

    }


    return (
        <>
            <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead="Add Materials">
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        {inputs.map((input, index) => (
                            <div className="grid grid-cols-12 gap-6" key={index}>
                                {/* <div className="col-span-8 sm:col-span-8">
                                   <input value={input.name}
                                        onChange={e => handleChange(e, index)} type="text" name="wareHouseName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                </div> */}
                                <div className="col-span-8 sm:col-span-8">
                                    <select
                                        value={input.name} onChange={e => handleChange(e, index)} name="product"
                                        className="form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-input"
                                        aria-describedby="" required >
                                        <option value="choose" >
                                            -- Select Material --
                                        </option>
                                        {
                                            materials?.map((material) => (
                                                <option key={material?.id} value={material?.id}>{material?.name}</option>
                                            ))
                                        }
                                    </select>

                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                    {/* <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Warehouse</label> */}
                                    <input type="button" onClick={() => handleRemoveInput(index)} value="x" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" />
                                </div>
                            </div>
                        ))}
                        <div className="items-center p-6 border-t border-gray-200 rounded-b">
                            <button onClick={handleAddInput} className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="button">Add</button>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddProductMaterial