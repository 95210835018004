import { useContext } from "react";
import { OrdersContext } from "../contexts/Orders/OrderContext";

export const useOrdersContext = () => {
    const context = useContext(OrdersContext);

    if(!context){
        throw Error("useOrdersContext must be used inside a OrdersContextProvider")
    }

    return context
}