import { useReducer } from "react";
import { createContext } from "react";

export const CuttingTicketsContext = createContext();

export const CuttingTicketsReducer = (state, action) => {
    switch (action.type) {
        case "SET_CUTTING_TICKETS":
            return {
                cuttingTickets: action.payload
            }
        case "CREATE_CUTTING_TICKET" :
            return {
                cuttingTickets: [action.payload, ...state.cuttingTickets]
            }
        case "DELETE_CUTTING_TICKET":
            return {
                cuttingTickets: state.cuttingTickets.filter((cuttingTicket) => cuttingTicket.id !== action.payload.id)
            }
        default:
            return state
    }
}

export const CuttingTicketsContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(CuttingTicketsReducer, {
        cuttingTickets: null
    })

    return (
        <CuttingTicketsContext.Provider value={{...state, dispatch}}>
            {children}
        </CuttingTicketsContext.Provider>
    )
}