import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";


import App from './App.js';
import { ContextProvider } from './contexts/ContextProvider';
// import { CustomerProvider } from './contexts/CustomerProvider';
// import { OrderProvider } from './contexts/OrderProvider';
import { OrderCategoryProvider } from './contexts/OrderCategoryProvider';
// import { ProductProvider } from './contexts/ProductProvider';
import { AuthContextProvider } from './contexts/Auth/AuthContext';
// import { CuttingTicketProvider } from './contexts/CuttingTicketProvider';
// import { WorkOrderProvider } from './contexts/WorkOrder/WorkOrderProvider';
import { WareHouseProvider } from "./contexts/WareHouseProvider";
import { PaymentAccountProvider } from "./contexts/PaymentAccountProvider";
import { StaffProvider } from './contexts/Staff/StaffProvider';
import { CustomersContextProvider } from './contexts/CustomerContext';
import { ProductsContextProvider } from './contexts/Product/ProductContext';
import { OrdersContextProvider } from './contexts/Orders/OrderContext';
import { OrderCategoriesContextProvider } from './contexts/Orders/OrderCategoriesContext';
import { CuttingTicketsContextProvider } from "./contexts/CuttingTicket/CuttingTicketsContext";
import { WorkOrdersContextProvider } from './contexts/WorkOrder/WorkOrdersContext';
import { WareHousesContextProvider } from './contexts/WareHouse/WareHousesContext';
import { BanksContextProvider } from './contexts/Accounts/BanksContext';
import { StaffContextProvider } from './contexts/Staff/StaffContext';
import { RoleContextProvider } from './contexts/Role/RoleContext';
import { MaterialContextProvider } from './contexts/Material/MaterialContext';
import { StocksContextProvider } from './contexts/Accounts/Stocks/StockContext';
import { FinishedProductsContextProvider } from './contexts/Accounts/Stocks/FinishedProductContext';
import { WebsiteOrdersContextProvider } from './contexts/WebsiteOrder/WebsiteOrdersContext';
import { GiftcardContextProvider } from './contexts/GiftCard/GIftCardContext.js';
import { ExpensesContextProvider } from './contexts/Expenses/ExpensesContext.js';
import { VendorsContextProvider } from './contexts/Expenses/VendorsContext.js';
import { ExpenseCategoriesContextProvider } from './contexts/Expenses/ExpenseCategories.js';

// import  { Stock}
// import { store } from './app/store';


ReactDOM.render(
    <AuthContextProvider>
        <ContextProvider >
            {/* <CustomerProvider> */}
            {/* <OrderProvider> */}
            <OrderCategoryProvider>
                {/* <ProductProvider> */}
                {/* <CuttingTicketProvider> */}
                {/* <WorkOrderProvider> */}
                <WareHouseProvider>
                    <PaymentAccountProvider>
                        {/* <StaffProvider> */}
                        <ProductsContextProvider>
                            <CustomersContextProvider>
                                <OrdersContextProvider>
                                    <OrderCategoriesContextProvider>
                                        <CuttingTicketsContextProvider>
                                            <WorkOrdersContextProvider>
                                                <WareHousesContextProvider>
                                                    <BanksContextProvider>
                                                        <RoleContextProvider>
                                                            <StaffContextProvider>
                                                                <MaterialContextProvider>
                                                                    <StocksContextProvider>
                                                                        <FinishedProductsContextProvider>

                                                                            <WebsiteOrdersContextProvider>
                                                                                <GiftcardContextProvider>
                                                                                    <ExpensesContextProvider>
                                                                                        <VendorsContextProvider>
                                                                                            <ExpenseCategoriesContextProvider>


                                                                                                <App />
                                                                                            </ExpenseCategoriesContextProvider>
                                                                                        </VendorsContextProvider>
                                                                                    </ExpensesContextProvider>
                                                                                </GiftcardContextProvider>

                                                                            </WebsiteOrdersContextProvider>
                                                                        </FinishedProductsContextProvider>
                                                                    </StocksContextProvider>
                                                                </MaterialContextProvider>
                                                            </StaffContextProvider>
                                                        </RoleContextProvider>
                                                    </BanksContextProvider>
                                                </WareHousesContextProvider>
                                            </WorkOrdersContextProvider>

                                        </CuttingTicketsContextProvider>
                                    </OrderCategoriesContextProvider>

                                </OrdersContextProvider>
                            </CustomersContextProvider>
                        </ProductsContextProvider>
                        {/* </StaffProvider> */}
                    </PaymentAccountProvider>
                </WareHouseProvider>
                {/* </WorkOrderProvider> */}
                {/* </CuttingTicketProvider> */}
                {/* </ProductProvider> */}
            </OrderCategoryProvider>
            {/* </OrderProvider> */}
            {/* </CustomerProvider> */}
        </ContextProvider>
    </AuthContextProvider>,
    document.getElementById('root'));


