import { useReducer } from "react";
import { createContext } from "react";

export const ProductsContext = createContext();

export const ProductsReducer = (state, action) => {
    switch (action.type) {
        case "SET_PRODUCTS":
            return {
                products: action.payload
            }
        case "CREATE_PRODUCT":
            return {
                products: [action.payload, ...state.products]
            }
        case "DELETE_PRODUCT":
            return {
                products: state.products.filter((product) => product.id !== action.payload.id)
            }
        case "UPDATE_PRODUCT":
            return {
                products: state.products.filter((product) => product.id == action.payload.id)
            }
        default:
            return state
    }
}

export const ProductsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ProductsReducer, {
        products: null
    })
    return (
        <ProductsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ProductsContext.Provider>
    )
}