import { useContext } from "react"
import { CustomersContext } from "../contexts/CustomerContext";


export const useCustomersContext = () => {
    const context = useContext(CustomersContext);

    if (!context) {
        throw Error("useCustomersContext must be used inside a CustomersContextProvider")
    }

    return context
}