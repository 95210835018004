
import { useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useWorkOrdersContext } from "../../hooks/useWorkOrdersContext";


const WorkOrderList = ({ user, setModalContent, setOpenWorkorder, goToWorkOrderDetails }) => {
  const { workOrders, dispatch } = useWorkOrdersContext();
  let navigate = useNavigate();

  const handleDeleteClick = async (work_order_id) => {
    await axiosClient.delete('workorder/delete/' + work_order_id).then((result) => {
      if (result.status === 200) {
        toast.success("Work order deleted");
        dispatch({
          type: "DELETE_WORK_ORDER", payload: result.data
        })
      }
    }).catch(() => {
      toast.error("something wrong");
    })

  }

  const goToOrder =  (id) => {
   
    id ? navigate(`/order/sales/${id}`) : toast.error("no id found");
  }

  useEffect(() => {
    const fetchWorkOrders = async () => {
      await axiosClient.get('workorder').then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "SET_WORK_ORDERS",
            payload: response.data
          })
        }
      }).catch(() => {
        toast.error("Something wrong, please try in a minute")
      })
    }

    if (user) {
      fetchWorkOrders()
    }
  }, [dispatch, user])
  return (
    <>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      s/n
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      Product
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      Warehouse
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      Reference
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      Quantity
                    </th>
                    {/* <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      Warehouse
                    </th> */}
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                      status
                    </th>

                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    workOrders?.map((wd, index) => (
                      <tr className="border-b" key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                        {/* <td> <img className="w-12 h-12 rounded-full object-cover" src={prod?.image} alt="imaged" /></td> */}
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          <span onClick={() => { goToOrder(wd?.salesOrder?.id) }}>{wd?.reference_number}</span>
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {wd?.productWorkOrder?.name}

                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {wd?.wareHouse?.name}

                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {wd?.quantity}
                        </td>
                        {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        { wd?.wareHouse ?.name}
                        </td> */}
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {wd?.status}
                        </td>

                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          <ul className="flex items-center justify-center">
                            <li className='py-1 px-2.5' onClick={() => goToWorkOrderDetails(wd?.id)} ><FaEye /></li>
                            <li className='py-1 px-2.5' onClick={() => { setOpenWorkorder(true); setModalContent(wd) }}><FaEdit /></li>
                            {/* <li className='py-1 px-2.5' onClick={() => handleDeleteClick(wd?.id)}><FaTrash /></li> */}
                          </ul>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkOrderList