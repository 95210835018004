import { useReducer } from "react";
import { createContext } from "react";

export const StocksContext = createContext();

export const StocksReducer = (state, action) => {
    switch (action.type) {
        case "SET_STOCKS":
            return {
                stocks: action.payload
            };
        case "CREATE_STOCK":
            return {
                stocks: [action.payload, ...state.stocks]
            };
        case "DELETE_STOCK":
            return {
                stocks: state.stocks.filter((stock) => stock.id !== action.payload.id)
            };
        case "UPDATE_STOCK":
            return {
                stocks: state.stocks.map((stock) => {
                    if (stock.id === action.payload.id) {
                        return { ...stock, ...action.payload };
                    } else {
                        return stock;
                    }
                })
            };
        default:
            return state;
    }
};



export const StocksContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(StocksReducer, {
        stocks: null
    });
    const contextValue = { stocks: state.stocks, dispatch };
    return (
        <StocksContext.Provider value={contextValue}>
            {children}
        </StocksContext.Provider>
    );
};
