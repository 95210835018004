import { createContext, useContext, useEffect, useState } from "react";
import axiosClient from "../axios";

const ProductContext = createContext();

export const ProductProvider = ({children}) => {
    const [product, setProduct] = useState();

    const [productLoading, setProductLoading] = useState(false);

    useEffect(() => {
        checkLogin();
    }, []);


    const checkLogin = async () =>{
        const token = localStorage.getItem("token");
        setProductLoading(true);

        if(token){
            axiosClient.get('/product').then((response) => {
                const productData = response.data;
                setProduct(productData);
                setProductLoading(false);
            });
        }else{
            setProduct(null);
            setProductLoading(false);
        }
    }

    return (
        <ProductContext.Provider value={{product, setProduct, productLoading, setProductLoading}}>
            {children}
        </ProductContext.Provider>
    );
};

export const useProductContext = () => useContext(ProductContext);