import React, { useState } from 'react'
// import ExpenseList from '../../components/Expenses/ExpenseList'
import { Header } from '../../components'
import VendorsList from '../../components/vendors/vendorsList';

const Vendors = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);

    return (
        <div>



            <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
                <Header title="Vendors" buttonName="Add Vendors" openModal={openModal} />
                <VendorsList
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen} />


            </div>
        </div>
    )
}

export default Vendors;