import { useRef } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../../axios";
import { useWareHousesContext } from "../../../hooks/useWareHouseContext"
import Modal from "../../Modal"


const EditWareHouse =  ({ modalContent, setOpen, isOpen, user})  => {
    const { dispatch } = useWareHousesContext();
    const refWareHouse = useRef(null);

    const handleSubmit = async (e, warehouse_id) => {
        e.preventDefault();
        if(!user){
            toast.error('you must be logged in');
            return
        }
        const data = {
            "name": refWareHouse.current.value
        }

        try {
            const response = await axiosClient.put(`warehouse/${warehouse_id}`, data);
            if (response.status === 200) {
                toast.success("WareHouse updated");
                dispatch({
                    type: "UPDATE_WARE_HOUSE",
                    payload: response.data
                })
                setOpen(false)
            } else {
                Promise.reject();
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong");
        }
        

        // await axiosClient.put(`warehouse/${warehouse_id}`, data).then(
        //     async (response) => {
        //         if (response.status === 200) {
        //             toast.success("WareHouse updated");
        //             dispatch({
        //                 type: "UPDATE_WARE_HOUSE",
        //                 payload: response.data
        //             })
        //             setOpen(false)
        //         } else Promise.reject();
        //     }
        // ).catch(() => {
        //     toast.error("something wrong")
        // })
    }

    return (
        <>
             <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead={modalContent?.name}>
                <form onSubmit={e => handleSubmit(e, modalContent?.id)} >
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Warehouse Name</label>
                                <input type="text" name="wareHouseName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refWareHouse} defaultValue={modalContent?.name} />
                            </div>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Update</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default EditWareHouse