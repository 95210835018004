import { useEffect, useState } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useOrdersContext } from "../../hooks/useOrderContext";

const ListOrders = ({ goToOrderDetails, orderCategoryID }) => {
    const { orders, dispatch } = useOrdersContext();
    const { user } = useAuthContext();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalOrders, setTotalOrders] = useState(0);

    const handleDeleteClick = async (orderID) => {
        try {
            const result = await axiosClient.delete(`order/delete/${orderID}`);
            if (result.status === 200) {
                toast.success("Order deleted");
                dispatch({
                    type: "DELETE_ORDER",
                    payload: orderID,
                });
            }
        } catch {
            toast.error("Something went wrong");
        }
    };

    const fetchSalesOrders = async () => {
        try {
            const response = await axiosClient.get(`/order/${orderCategoryID}?page=${currentPage}&limit=${itemsPerPage}`);
            console.log(111, response.data)
            if (response.status === 200) {
                setTotalOrders(response.data.totalRecords);
                dispatch({
                    type: "SET_ORDERS",
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch orders");
        }
    };

    useEffect(() => {
        if (user) {
            fetchSalesOrders();
        }
    }, [dispatch, user, currentPage]);

    const handlePageChange = (direction) => {
        if (direction === "next" && currentPage < Math.ceil(totalOrders / itemsPerPage)) {
            setCurrentPage((prev) => prev + 1);
        } else if (direction === "prev" && currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    return (
        <div className="p-4">
            <div className="overflow-x-auto shadow rounded-lg">
                <table className="min-w-full bg-white">
                    <thead className="bg-gray-800 text-white">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase">S/N</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase">Reference</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase">Customer</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase">Source</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase">Warehouse</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase">Status</th>
                            {/* <th className="px-6 py-3 text-left text-xs font-medium uppercase">Delivery</th> */}
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase">Delivery Date</th>
                            <th className="px-6 py-3 text-center text-xs font-medium uppercase">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders?.map((order, index) => (
                            <tr key={index} className="border-b hover:bg-gray-100">
                                <td className="px-6 py-4 text-sm text-gray-900">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">{order?.reference_number}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">
                                    {order?.customer?.first_name} {order?.customer?.last_name}
                                </td>
                                <td className="px-6 py-4 text-sm text-gray-900">{order?.source}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">{order?.outlet?.name}</td>
                                <td className="px-6 py-4 text-sm text-gray-900">{order?.status}</td>
                                {/* <td className="px-6 py-4 text-sm text-gray-900">{order?.delivery_status}</td> */}
                                <td className="px-6 py-4 text-sm text-gray-900">{order?.deliveryDate}</td>
                                <td className="px-6 py-4 text-sm text-center">
                                    <div className="flex items-center justify-center gap-2">
                                        <button
                                            onClick={() => goToOrderDetails(order)}
                                            className="text-blue-500 hover:text-blue-700"
                                        >
                                            <FaEye />
                                        </button>
                                        {/* <button className="text-green-500 hover:text-green-700">
                                            <FaEdit />
                                        </button> */}
                                        {/* <button
                                            onClick={() => handleDeleteClick(order?.id)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <FaTrash />
                                        </button> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center p-4 bg-gray-100">
                    <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange("prev")}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
                    >
                        Previous
                    </button>
                    <span className="text-sm text-gray-600">
                        Page {currentPage} of {Math.ceil(totalOrders / itemsPerPage)}
                    </span>
                    <button
                        disabled={currentPage === Math.ceil(totalOrders / itemsPerPage)}
                        onClick={() => handlePageChange("next")}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ListOrders;
