import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useMaterialContext } from '../../hooks/useMaterialContext'
import Spinner from '../Spinner';

const  MaterialLists = ({ user }) => {
    const { dispatch, materials } = useMaterialContext();
    const [isLoading, setIsLoading] = useState(false);
    const fetchMaterials = useCallback(async () => {
        setIsLoading(true);

        await axiosClient.get("/material").then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: "SET_MATERIAL",
                    payload: response.data
                })
            } else {
                toast.error("an error occurred while fetching materials")
            }
        }).catch(() => {
            toast.error("something wrong")
        }).finally(() => {
            setIsLoading(false);
        })

    }, [dispatch, user]);


    useEffect(() => {
        if (user) {
            fetchMaterials();
        }
    }, [dispatch, user, fetchMaterials]);

    if (isLoading) {
        return <Spinner />;
    }


    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Role
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        materials && materials?.map((material, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {material?.name}
                                                </td>



                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MaterialLists