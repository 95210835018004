import { createContext, useReducer } from "react";


export const ExpenseCategoriesContext = createContext();
export const ExpenseCategoriesReducer = (state, action) => {
    switch (action.type) {
        case "SET_EXPENSE_CATEGORIES":
            return {
                expenseCategories: action.payload
            }

            break;

        default:

            return state;
            break;
    }
}

export const ExpenseCategoriesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ExpenseCategoriesReducer, {
        expenseCategories: []
    })

    return (
        <ExpenseCategoriesContext.Provider value={{ ...state, dispatch }}>
            {children}
        </ExpenseCategoriesContext.Provider>
    )
}