import { createContext } from "react"
import { useReducer } from "react"

export const StaffContext = createContext();

export const StaffReducer = (state, action) => {
    switch (action.type) {
        case "SET_STAFF":
            return {
                staff: action.payload
            }

        case "CREATE_STAFF":
            return {
                staff: [action.payload, ...state.staff]
            }
        case "DELETE_STAFF":
            return {
                staff: state.staff.filter((staf) => staf.id !== action.payload.id)
            }
        default:
            return state

    }
}

export const StaffContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(StaffReducer, {
        staff: null
    })
    return (
        <StaffContext.Provider value={{ ...state, dispatch }}>
            {children}
        </StaffContext.Provider>
    )
}