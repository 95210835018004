import React, { useState } from 'react'
import { Header } from '../../../components';
import Spinner from '../../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import ListOrders from '../../../components/Orders/ListOrders';

const SalesInvoice = () => {
  const navigate = useNavigate();
  const [isLoading, setLoaded] = useState(false);

  const goToNew = () => {
    // navigate("/order/invoice/add");
    navigate("/order/sales/add");
  }
  const goToOrderDetails = (orde) => {
    navigate(`/order/sales/${orde.id}`);
  }
  return (

    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      {isLoading ? <Spinner /> : <>
        <Header category="Page" title="Sales Invoice" buttonName="New" openModal={goToNew} />
        <ListOrders goToOrderDetails={goToOrderDetails} orderCategoryID="5" />
      </>
      }
    </div>
  )
}

export default SalesInvoice