import { useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useProductsContext }  from "../../hooks/useProductsContext";
import MoneyFormat from "../Utils/MoneyFormat";
const ListOfProducts = ({goToProduct, goToEditProduct}) => { 
    const { products, dispatch } = useProductsContext();
    const { user } = useAuthContext();

    const ActionColumn = user.role === "999" && (
        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
            Action
        </th>
    );

    const handleDeleteClick = async (productID) => {
        await axiosClient.delete('product/delete/'+productID).then((result) => {
            if (result.status === 200) {
                toast.success("product deleted");
                dispatch({
                    type: "DELETE_PRODUCT",payload: result.data
                })
            }
        }).catch((err) => {
            toast.error("something wrong");
        })
    }

    const renderActions = (productId) => {
        if (user.role === "999") {
            return (
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <ul className="flex items-center justify-center">
                        <li className='py-1 px-2.5' onClick={() => goToProduct(productId)}><FaEye /></li>
                        <li className='py-1 px-2.5' onClick={() => goToEditProduct(productId)}><FaEdit /></li>
                        <li className='py-1 px-2.5' onClick={() => handleDeleteClick(productId)}><FaTrash /></li>
                    </ul>
                </td>
            );
        }
        return null;
    };
    useEffect(() => { 
        const fetchProducts = async () => {
            await axiosClient.get('product').then(function (response) {
                if(response.status === 200){
                   
                    dispatch({
                        type: "SET_PRODUCTS",
                        payload: response.data
                    })
                }
            }).catch(function (error) {
                // handle error
                console.log(error);
              })
        }
        if(user){
            fetchProducts()
          }
        }, [dispatch, user]);
  return (
    <>
        <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Name
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Code
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Price
                                        </th>
                                      {ActionColumn}
                                      
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        products?.map((prod, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                <td> <img className="w-12 h-12 rounded-full object-cover" src={prod?.image} alt="imaged" /></td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {prod?.name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {prod?.code}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <p><MoneyFormat amount={prod?.price_usd} currency="USD" /></p>
                                                    <p> <MoneyFormat amount={prod?.price_ngn} /></p>
                                                </td>
                                                {renderActions(prod?.id)}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ListOfProducts