// src/components/SubscriberManager.jsx
import React, { useState, useEffect } from 'react';
import axiosClient from '../../axios';
import { Header } from '../../components';


const SubscriberManager = () => {
    const [email, setEmail] = useState('');
    const [subscribers, setSubscribers] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    // Function to handle email subscription
    const handleSubscribe = async () => {
        try {
            setLoading(true);
            const response = await axiosClient.post('subscriber', { email });

            if (response.data.success) {
                setMessage(response.data.message);
                fetchSubscribers(page);
            } else {
                setMessage(response.data.message);
            }
            setEmail('');
        } catch (error) {
            setMessage('Failed to subscribe: ' + error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch subscribers with pagination
    const fetchSubscribers = async (pageNumber) => {
        try {
            setLoading(true);
            const response = await axiosClient.get('subscriber', {
                params: { page: pageNumber, limit: 10 },
            });

       

            if (response?.data.data.success == true) {
                setSubscribers(response.data.data.data.subscribers);
                setTotalPages(response.data.data.data.pagination.totalPages);
            }
        } catch (error) {
            setMessage('Failed to fetch subscribers: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch subscribers on component mount and when page changes
    useEffect(() => {
        fetchSubscribers(page);
    }, [page]);

    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Subscribers"  />
        <div className="max-w-xl mx-auto p-4">
            {/* <h2 className="text-2xl font-bold mb-4">Subscribe to Newsletter</h2> */}
            {/* <div className="mb-4">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="border p-2 rounded w-full"
                />
                <button
                    onClick={handleSubscribe}
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-2 w-full"
                    disabled={loading}
                >
                    {loading ? 'Subscribing...' : 'Subscribe'}
                </button>
            </div> */}
            {message && <p className="text-red-500 mb-4">{message}</p>}
            <h3 className="text-xl font-semibold mb-2">Subscribers List</h3>
            {subscribers.length > 0 ? (
                <ul className="border rounded p-4 mb-4">
                    {subscribers.map((subscriber) => (
                        <li key={subscriber?.id} className="mb-2">
                            {subscriber?.email}
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-gray-500 mb-4">No subscribers found.</p>
            )}
            <div className="flex justify-between">
                <button
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded"
                    disabled={page === 1}
                >
                    Previous
                </button>
                <span className="p-2">Page {page} of {totalPages}</span>
                <button
                    onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded"
                    disabled={page === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
        </div>
    );
};

export default SubscriberManager;
