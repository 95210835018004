import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { useOrderCategoriesContext } from "../../hooks/useOrderCategoriesContext";
import { useEffect } from "react";
import axiosClient from "../../axios";
import { toast } from "react-toastify";
const OrderCayegoriesList = ({user, setOpen, setModalContent}) => {
    const {orderCategories, dispatch} = useOrderCategoriesContext();
    

    useEffect(() => {
        const fetchOrderCategories = async () => {
            // await axiosClient
            await axiosClient.get('order-category').then((response) => {
                if(response.status === 200){
                    dispatch({
                        type: "SET_ORDER_CATEGORIES",
                        payload: response.data
                    })
                }
            }).catch(() => {
                toast.error("order categories  not loading")
            })
        }

        if(user){
            fetchOrderCategories()
        }


    }, [dispatch, user])
  return (
    <>
        <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b">
                  <tr>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      s/n
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      Title
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                      Code
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orderCategories?.map((orderCat, index) => (
                      <tr className="border-b" key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {orderCat?.name}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {orderCat?.code}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          <ul className="flex items-center justify-center">
                            {/* <li className='py-1 px-2.5'><FaEye /></li> */}
                            <li className='py-1 px-2.5' onClick={() => { setOpen(true); setModalContent(orderCat)}}><FaEdit /></li>
                            <li className='py-1 px-2.5'><FaTrash /></li>
                          </ul>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>  
    </>
  )
}

export default OrderCayegoriesList