import React from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useStaffContext } from '../../hooks/useStaffContext';
// import { useStaffContext } from '../../contexts/Staff/StaffProvider'

const ListOfStaff = ({ user }) => {
    const { dispatch, staff } = useStaffContext();
    useEffect(() => {
        const fetchStaff = async () => {
            await axiosClient.get("/user").then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "SET_STAFF",
                        payload: response.data
                    })
                }
            }).catch(() => {
                toast.error("Staff not loading")
            })
        }

        if (user) {
            fetchStaff()
        }
    }, [dispatch, user])
    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Name
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Role
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Email
                                        </th>
                                       

                                
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        staff && staff?.map((staf, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {staf?.first_name} {staf?.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {staf?.role?.title}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {staf?.email}
                                                </td>
                                               


                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListOfStaff