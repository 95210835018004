import React from 'react'
import { Header } from '../../components'
import FinishedProductList from '../../components/products/Finished/FinishedProductList'
import FinishedProductList2 from '../../components/products/Finished/FinishedProductList2'

const FinishedProducts = () => {
  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Finished Products" />
      {/* <FinishedProductList /> */}
      <FinishedProductList2 />
    </div>
  )
}

export default FinishedProducts