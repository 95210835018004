import { useContext } from "react";
import { CuttingTicketsContext } from "../contexts/CuttingTicket/CuttingTicketsContext";

export const  useCuttingTicketsContext = () => {
    const context = useContext(CuttingTicketsContext);

    if (!context) {
        throw Error("useCuttingTicketsContext must be used inside a CuttigTicketsContextProvider")
    }

    return context
}