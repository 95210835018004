import { useContext } from "react"
import { ExpenseCategoriesContext } from "../contexts/Expenses/ExpenseCategories";


export const useExpenseCategory = () => {
    const context = useContext(ExpenseCategoriesContext); 

    if (!context) {
        throw Error("useExpenseCategory must be used inside a ExpenseCategoryProvider")
    }

    return context;
}