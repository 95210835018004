import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosClient from '../axios';
const OrderContext = createContext();

export const OrderProvider = ({children}) => {
    const [order, setOrder] = useState(); 

    const [viewOrder, setViewOrder] = useState();
    const [orderLoading, setOrderLoading] = useState(false);

    useEffect(() => {
      checkLogin();
    }, []);

    const checkLogin = async () => {
        const token = localStorage.getItem("token");
        setOrderLoading(true);

        if(token){
            axiosClient.get('/order').then((response) => {
                const orderData = response.data;
                setOrder(orderData);
                setOrderLoading(false);
            });
        }else{
            setOrder(null);
            setOrderLoading(false); 
        }

        // if(token){
        //      axiosClient.get('/order' , { headers: {"Authorization" : `Bearer ${token}`} }).then((response) => {
        //         const orderData = response.data;
        //         setOrder(orderData);
        //         setOrderLoading(false);
        //     });
        // }
    }
    

    return (
        <OrderContext.Provider value={{order, setOrder, orderLoading}}>
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => useContext(OrderContext);