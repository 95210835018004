import React, { useState, useEffect } from 'react';
import axiosClient from '../axios';
import { useAuthContext } from "../hooks/useAuthContext"
import Femi from '../components/Dashboard/Femi';
import FactoryHome from '../components/Dashboard/FactoryHome';
import GuestHome from '../components/Dashboard/GuestHome';
import ShopHome from '../components/Dashboard/ShopHome';
import AnalystHome from '../components/Dashboard/AnalystHome';

const Home = () => {
  const [data, setData] = useState(null);
  const { user } = useAuthContext();

  const fetchOverview = async () => {
    try {
      const response = await axiosClient.get("overview");
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOverview();
  }, []);

  const renderComponent = () => {
    if (user && user?.role == 999) {
      return <Femi data={data} />;
    } else if (user && user?.role == 667) {
      return <FactoryHome />;
    } else if (user && user?.role == 805) {
      return <ShopHome data={data} />;
    }  else if (user && user?.role == "009") {
      return <AnalystHome data={data} />;}

      else if ( user && user?.role == 7467){
        return <Femi data={data} />;
      }
    
    else {
      return <GuestHome />;
    }
  };

  return (
    <div className='mt-12'>
      <div className="w-full flex flex-wrap lg:flex-nowrap justify-center">
        <div className=' w-full flex m-3 flex-wrap justify-center gap-1 items-center'>
          {renderComponent()}

        </div>
      </div>
    </div>
  );
};

export default Home;
