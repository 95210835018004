import React, { useEffect, useRef } from 'react'
import { FaWindowClose } from 'react-icons/fa'
import styled from 'styled-components'
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
const portalRoot = document.getElementById("portal-root");

const Modal = ({ isOpen, close, children, modalhead }) => {
    const contentRef = useRef();

    useEffect(() => {
        if (!isOpen) return;

        function listener(evt) {
            if (contentRef.current?.contains(evt.target)) return;
            close();
        }

        window.addEventListener("click", listener)
        return () => window.removeEventListener("click", listener);
    }, [isOpen]);

    if (!isOpen) return null;
    return (
        ReactDOM.createPortal(
            <>
                <Background>
                    <Content ref={contentRef}>
                        <div className="bg-white rounded-lg shadow relative">
                            {/* <HeaderRow> */}
                            <div className="flex items-start justify-between p-5 border-b rounded-t">
                                {/* <h5></h5> */}
                                <h3 className="text-xl font-semibold">{modalhead}</h3>
                                {/* <CloseTag onClick={close}> <FaWindowClose /> </CloseTag> */}
                                <button type="button" onClick={close} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="user-modal">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </button>
                                {/* </HeaderRow> */}
                            </div>
                            {children}
                        </div>
                    </Content>
                </Background>
                <ScrollDisabler />
            </>
            , portalRoot
        )

    )
}

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right:0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
margin: auto;
width: 90%;
max-width: 30rem;
background-color: white;
border-radius: 1.5rem;
padding: 20px;
max-height: 60vh;
overflow-y: scroll;
overflow-x: hidden;
// box-shadow: 0 3px 5px 5px rgba(0,0,0,0.1);

`;

const HeaderRow = styled.div`
display: flex;
justify-content: space-between;
background-color: hsla(300, 80%, 90%, 0.2);
  border-radius: 1.5rem 1.5rem 0 0;
  padding: 1.2rem;
`
const CloseTag = styled.p`
    cursor: pointer;
`;

const ScrollDisabler = createGlobalStyle`
    body{
        overflow-y : hidden;
    }
`;

export default Modal