import React, { useEffect, useState } from 'react';
import axiosClient from '../../axios';

import BestSellersChart from '../Analytics/BestSellerChart';
import ExpenseChart from '../Analytics/ExpenseChart';

const AnalystHome = ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const [bestSellersData, setBestSellersData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get('overview/analytics');
        const response_best_sellers = await axiosClient.get('overview/analytics/best-sellers');

        const arrayResponse = response?.data?.data;
        const arrayBestSellersResponse = response_best_sellers?.data?.data;

        if (response?.status === 200 && Array.isArray(arrayResponse)) {
          const transformedData = arrayResponse.map(item => ({
            period: item.period,
            totalAmount: item.totalAmount,
          }));

          setChartData(transformedData);
        } else {
          console.error('Unexpected API response format or status');
        }
        if (response_best_sellers?.status === 200 && Array.isArray(arrayBestSellersResponse)) {
          const transformedBestSellData = arrayBestSellersResponse.map(item => ({
            total_amount: item.total_amount,
            item_count: item.item_count,
            product_name: item.product_name,
          }));

          setBestSellersData(transformedBestSellData);
        } else {
          console.error('Unexpected API response format or status');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div className="p-4 bg-white rounded-lg shadow">
        <h2 className="text-lg font-medium mb-2">PAYMENT COUNT</h2>
        {/* <p className="text-2xl font-bold text-green-500">{data?.my_warehouse_Pos_count}</p> */}
      </div>

      <div className="p-4 bg-white rounded-lg shadow">
        <h2 className="text-lg font-medium mb-2">ALL PRODUCTS COUNT</h2>
        {/* <p className="text-2xl font-bold text-green-500">{data?.my_warehouse_finished_count?.all_product_count}</p> */}
      </div>
      <div className="p-4 bg-white rounded-lg shadow">
        <h2 className="text-lg font-medium mb-2">SOLD PRODUCT COUNT</h2>
        {/* <p className="text-2xl font-bold text-green-500">{data?.my_warehouse_finished_count?.sold_product_count}</p> */}
      </div>
      <div className="p-4 bg-white rounded-lg shadow">
        <h2 className="text-lg font-medium mb-2">AVAILABLE PRODUCT COUNT</h2>
        {/* <p className="text-2xl font-bold text-green-500">{data?.my_warehouse_finished_count?.available_product_count}</p> */}
      </div>

      <div className="col-span-1 sm:col-span-2 md:col-span-4 p-4 bg-white rounded-lg shadow">
        <h2 className="text-lg font-medium mb-4">Expense Chart</h2>
        <ExpenseChart />
      </div>

      <div className="col-span-1 sm:col-span-2 md:col-span-4 p-4 bg-white rounded-lg shadow">
        <h2 className="text-lg font-medium mb-4">Best Sellers Chart</h2>
        <BestSellersChart data={bestSellersData} />
      </div>
    </div>
  );
};

export default AnalystHome;
