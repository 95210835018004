import { useState } from "react";
import Modal from "../../Modal"
import  axiosClient  from "../../../axios.js";
import { toast } from "react-toastify";
import { useCustomersContext } from "../../../hooks/useCustomersContext";
import { useAuthContext } from "../../../hooks/useAuthContext";
// continue from here 2moro



const AddCustomer = ({ isOpenAddcustomer, setOpenAddcustomer,  }) => {
    const { user} = useAuthContext();
    const { dispatch} = useCustomersContext();
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [mobile_number, setMobileNumber] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [local_government, setLocalGovernment] = useState("");
    const [stateCountry, setStateCountry] = useState("");
    const [country, setCountry] = useState("");
    const [postal_code, setPostalCode] = useState("");
    const [error, setError] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!user){
            toast.error('you must be logged in');
            return
        }

        const customer = {
            first_name,
            last_name,
            email, gender,
            mobile_number,
            address: {
                street,
                city,
                local_government,
                stateCountry,
                country,
                postal_code
            }



        }

        const response =  axiosClient.post('/customer', customer).then(function (response) {
            console.log(response);
            if(response.status === 201){
                setError(null);
                setFirstName("");
                setLastName("");
                setEmail("");
                setGender("");
                setMobileNumber("");
                setStreet("");
                setCity("");
                setLocalGovernment("");
                setStateCountry("");
                setCountry("");
                setPostalCode("");
                setOpenAddcustomer(false);
                toast.success("Customer added!!!");
                dispatch({
                    type: "CREATE_CUSTOMER",
                    payload: response.data
                })
               
            }
          })
          .catch(function (error) {
            // console.log(error);
            // setError(error);
            toast.error("there is a problem, try later");
            // console.log("BIG TIME ERROR",error);
            setError(error);
          });


    }
    return (
        <>
            <Modal isOpen={isOpenAddcustomer} close={() => setOpenAddcustomer(false)} modalhead="New Customer" >
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="text-sm font-medium text-gray-900 block mb-2">First Name</label>
                                {/* <input type="text" name="first_name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.first_name} onChange={(e) => setfirstName(e.target.value)} /> */}
                                <input onChange={(e) => setFirstName(e.target.value)} value={first_name} type="text" name="first_name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="last_name" className="text-sm font-medium text-gray-900 block mb-2">Last Name</label>
                                {/* <input type="text" name="last_name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.last_name} onChange={(e) => setLastName(e.target.value)} /> */}
                                <input onChange={(e) => setLastName(e.target.value)} value={last_name} type="text" name="last_name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"  />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="email" className="text-sm font-medium text-gray-900 block mb-2">Email</label>
                                {/* <input type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.email} onChange={(e) => setEmail(e.target.value)} /> */}
                                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" name="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"  />
                            </div>
                            <div className="col-span-6 sm:col-span-3">

                                <label htmlFor="mobile_number" className="text-sm font-medium text-gray-900 block mb-2">Phone Number</label>
                                {/* <input type="number" name="mobile_number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" defaultValue={modalContent?.mobile_number} required onChange={(e) => setMobileNumber(e.target.value)} /> */}
                                <input onChange={(e) => setMobileNumber(e.target.value)} value={mobile_number} type="number" name="mobile_number" id="mobile_number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                                <select required className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="gender" onChange={(e) => setGender(e.target.value)} value={gender}   >
                                    <option value="none">Choose a Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="none">Both</option>
                                    <option value="none">Gay</option>
                                    <option value="none">Lesbian</option>
                                </select>
                            </div>

                        </div>

                        <div className="items-center  border-t border-gray-200 rounded-b">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="street" className="text-sm font-medium text-gray-900 block mb-2">Street</label>
                                    {/* <input type="text" name="first_name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.first_name} onChange={(e) => setfirstName(e.target.value)} /> */}
                                    <input onChange={(e) => setStreet(e.target.value)} value={street} type="text" name="street" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="city" className="text-sm font-medium text-gray-900 block mb-2">City</label>
                                    {/* <input type="text" name="last_name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.last_name} onChange={(e) => setLastName(e.target.value)} /> */}
                                    <input onChange={(e) => setCity(e.target.value)} value={city} type="text" name="city" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="local_government" className="text-sm font-medium text-gray-900 block mb-2">Local Government</label>
                                    {/* <input type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required defaultValue={modalContent?.email} onChange={(e) => setEmail(e.target.value)} /> */}
                                    <input onChange={(e) => setLocalGovernment(e.target.value)} value={local_government} type="text" name="local_government" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"  />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="state" className="text-sm font-medium text-gray-900 block mb-2">State</label>
                                    {/* <input type="number" name="mobile_number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" defaultValue={modalContent?.mobile_number} required onChange={(e) => setMobileNumber(e.target.value)} /> */}
                                    <input onChange={(e) => setStateCountry(e.target.value)} value={stateCountry} type="text" name="stateCountryr" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="country" className="text-sm font-medium text-gray-900 block mb-2">Country</label>
                                    {/* <input type="number" name="mobile_number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" defaultValue={modalContent?.mobile_number} required onChange={(e) => setMobileNumber(e.target.value)} /> */}
                                    <input onChange={(e) => setCountry(e.target.value)} value={country} type="text" name="country" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="postal_code" className="text-sm font-medium text-gray-900 block mb-2">Postal Code</label>
                                    {/* <input type="number" name="mobile_number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" defaultValue={modalContent?.mobile_number} required onChange={(e) => setMobileNumber(e.target.value)} /> */}
                                    <input onChange={(e) => setPostalCode(e.target.value)} value={postal_code} type="text" name="postal_code" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"  />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save all</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddCustomer