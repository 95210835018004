import React from 'react'
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useState } from 'react';

const AddExpenseCategory = ({ isOpen, closeModal, onExpenseCategoryCreated }) => {

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!name) newErrors.name = 'Name is required';
        if (!code) newErrors.code = 'Code is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const expenseCategoryData = { 
            name,
            code
        };

        try {
            const response = await axiosClient.post('/expense/category', expenseCategoryData);
            if (response.status === 201) {
                onExpenseCategoryCreated();
                closeModal(); 
            }
        } catch (error) {
            toast.error('Failed to add expense Category');
        }
    };

    if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg w-full max-w-lg">
        <h2 className="text-xl font-semibold mb-4">Add Expense Category Name</h2>

        <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Name</label>
            <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                className={`border p-2 w-full ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Enter the Expense Category name"
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Code</label>
            <input
                type="text"
                value={code}
                onChange={e => setCode(e.target.value)}
                className={`border p-2 w-full ${errors.code ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="001"
            />
            {errors.code && <p className="text-red-500 text-sm">{errors.code}</p>}
        </div>

       

      

       
        <div className="flex justify-end space-x-2">
            <button
                onClick={handleSubmit}
                className={`bg-blue-500 text-white p-2 rounded hover:bg-blue-600 ${Object.keys(errors).length > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={Object.keys(errors).length > 0}
            >
                Submit
            </button>
            <button
                onClick={closeModal}
                className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
            >
                Close
            </button>
        </div>
    </div>
</div>
  )
}

export default AddExpenseCategory