import { useReducer } from "react";
import { createContext } from "react";

export const BanksContext = createContext();

export const BanksReducer = (state, action) => {
    switch (action.type) {
        case "SET_BANKS":
            return {
                banks: action.payload
            }
        case "CREATE_BANK":
            return {
                banks: [action.payload, ...state.banks]
            }
        case "DELETE_BANK":
            return {
                banks: state.banks.filter((bank) => bank.id !== action.payload.id)
            }
        case "UPDATE_BANK":
            return {
                banks: state.banks.filter((bank) => bank.id !== action.payload.id)
            }
        default:
            return state
    }
}

export const BanksContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(BanksReducer, {
        banks: null
    })
    return (
        <BanksContext.Provider value={{ ...state, dispatch }}>
            {children}
        </BanksContext.Provider>
    )
}