import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useStockContext } from '../../hooks/useStockContext';
import Spinner from '../Spinner';

const StockList = () => {
    const { stocks, dispatch } = useStockContext();
    const [isLoading, setIsLoading] = useState(false);

    const fetchStocks = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data, status } = await axiosClient.get('/stock');
            if (status === 200) {
                dispatch({
                    type: "SET_STOCKS",
                    payload: data
                });
            } else {
                toast.error("An error occurred while fetching stock");
            }
        } catch (error) {
            toast.error("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchStocks();
    }, [dispatch, fetchStocks]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Image
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Product
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Quanity
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">

                                        </th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        stocks && stocks?.map((stock, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    <img className="w-12 h-12 rounded-full object-cover" src={stock?.stock_product?.image} alt="imaged" />
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {stock?.stock_product?.name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {stock?.quantity}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <ul className="flex items-center justify-center">
                                                        <li className='py-1 px-2.5'></li>
                                                    </ul>
                                                </td>



                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StockList