import { useEffect, useReducer, useState } from "react"
import axiosClient from "../../../axios";
// import { useOrderContext } from "../../../contexts/OrderProvider";
import { useStaffContext } from "../../../contexts/Staff/StaffProvider";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "../../Modal"
import { useOrdersContext } from "../../../hooks/useOrderContext";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useWorkOrdersContext } from "../../../hooks/useWorkOrdersContext";
import { useCuttingTicketsContext } from "../../../hooks/useCuttingTicket";

const formReducer = (state, event) => {

    if (event.reset) {
        return {
            start_date: '',
            complete_date: "",
            order_id: "",
            purpose: "",
            staff_id: "",
        }
    }
    return {
        ...state,
        [event.name]: event.value
    }
}
const AddCuttingTicket = ({ isOpen, setOpen }) => {
    const { orders, dispatch: setOrders } = useOrdersContext();
    const { workOrders, dispatch: setWorkOrders } = useWorkOrdersContext();
    const { user } = useAuthContext();
    function refreshPage() {
        window.location.reload(false);
    }
    // const { order, setOrder } = useOrderContext();
    const { cuttingTickets, dispatch } = useCuttingTicketsContext();
    // const { staff } = useStaffContext();
    const [staffs, setStaffs] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [formData, setFormData] = useReducer(formReducer, {});

    const purposes = [
        {
            name: "stock",
        },
        {
            name: "order"
        }
    ];



    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        const cuttingTicketData = {
            "start_date": formData.start_date,
            "complete_date": formData.complete_date,
            "work_order_id": formData.work_order_id,
            "purpose": formData.purpose,
            // "quantity": formData.quantity,
            "user_id": formData.staff_id
        }

        // start processing the form
        axiosClient.post("cutting-ticket", cuttingTicketData).then(
            async (response) => {
                if (response.status === 201) {
                    setSubmitting(false);
                    toast.success("cutting ticket created");
                    // setFormData({
                    //     reset: true
                    // })
                    // dispatch ({
                    //     type: "CREATE_CUTTING_TICKET",
                    //     payload: response.data
                    // });
                    window.location.reload(false);
                    // console.log("DATAAAAAAA",response.data)
                    setOpen(false);
                    // refreshPage();
                }
            }
        ).catch((error) => {
            // toast.error(error.response.data.msg);
            toast.error("something wrong");
        });

    }

    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    useEffect(() => {
        const fetchStaffs = async () => {
            await axiosClient.get('/user').then((response) => {
                if (response.status === 200) {
                    setStaffs(response.data)
                }
            }).catch(() => {
                toast.error("staff not loading...");
            })
        }
        // const fetchSalesOrders = async () => {
        //     await axiosClient.get(`/order`).then(function (response){
        //         if (response.status === 200) {
        //             setOrders({
        //                 type: "SET_ORDERS",
        //                 payload: response.data
        //             })
        //         }
        //     }).catch((error) => {
        //         console.error(error);
        //     })
        // }

        const fetchWorkOrders = async () => {
            await axiosClient.get(`/workorder/wo/unpaginatedWorkorder`).then(function (response) {
                if (response.status === 200) {
                    setWorkOrders({
                        type: "SET_WORK_ORDERS",
                        payload: response.data
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }

        if (user) {
            // fetchSalesOrders()
            fetchWorkOrders();
            fetchStaffs();
        }
    }, [setOrders, user])
    return (
        <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead="Add Cutting Ticket">
            <form onSubmit={handleSubmit}>
                {submitting &&
                    <div>Submtting Form...</div>
                }
                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label htmlFor="start_date" className="text-sm font-medium text-gray-900 block mb-2">Start Date</label>
                        <input type="date" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required name="start_date" onChange={handleChange} />
                    </div>
                </div>
                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label htmlFor="complete_date" className="text-sm font-medium text-gray-900 block mb-2">Complete Date</label>
                        <input type="date" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required name="complete_date" onChange={handleChange} />
                    </div>
                </div>
                {/* cutting Ticket */}
                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label className="text-sm font-medium text-gray-900 block mb-2">Order</label>
                        <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                        </svg>
                        <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="work_order_id" onChange={handleChange}  >
                            <option>Select</option>
                            {
                                workOrders && workOrders?.map((workOder, index) => (
                                    <option key={index} className="block w-full" value={workOder?.id}>{workOder?.reference_number}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                {/* Purpose */}
                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label className="text-sm font-medium text-gray-900 block mb-2">Purpose</label>
                        <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                        </svg>
                        <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="purpose" required onChange={handleChange}  >
                            <option>Choose a purpose</option>
                            {
                                purposes?.map((purpose, index) => (
                                    <option key={index} className="block w-full" value={purpose?.name}>{purpose?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {/* Quanttiy */}
                {/* <div className="w-full md:w-3/3 p-3">
                    <label htmlFor="quantity" className="text-sm font-medium text-gray-900 block mb-2">Quanity</label>
                    <input type="number" name="quantity" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" placeholder="quantity" required onChange={handleChange} step="1" />
                </div> */}

                {/* Staff */}
                <div className="w-full md:w-3/3 p-3">
                    <div className="relative">
                        <label className="text-sm font-medium text-gray-900 block mb-2">Staff In charge</label>
                        <svg className="absolute right-4 top-1/2 transform -translate-y-1/2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.47329L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#8896AB"></path>
                        </svg>
                        <select className="appearance-none w-full py-2.5 px-4 text-coolGray-900 text-base font-normal bg-white border outline-none border-coolGray-200 focus:border-green-500 rounded-lg shadow-input" name="staff_id" required onChange={handleChange}  >
                            <option>Choose a staff</option>
                            {
                                staffs?.map((staf, index) => (
                                    <option key={index} className="block w-full" value={staf?.id}>{staf?.first_name + " " + staf?.last_name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {!submitting &&
                    <div className="w-full md:w-3/3 p-3">
                        <div className="relative">
                            <button type="submit" disabled={submitting} className="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button">
                                <p>Save</p></button>
                        </div>
                    </div>
                }
            </form>
        </Modal>
    )
}

export default AddCuttingTicket