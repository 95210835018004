import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';



const BestSellersChart = ({data}) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart
      data={data}
      margin={{
        top: 20, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="product_name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="total_amount" fill="#8884d8" name="Total Amount" />
      <Bar dataKey="item_count" fill="#82ca9d" name="Item Count" />
    </BarChart>
  </ResponsiveContainer>
);

export default BestSellersChart;
