import { useContext } from "react";
import { toast } from "react-toastify";
import { MaterialContext } from "../contexts/Material/MaterialContext";


export const useMaterialContext = () => {
    const context = useContext(MaterialContext);

    if (!context) {
        toast.error("UseMaterialContext must be used");
    }

    return context;
}