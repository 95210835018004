import React, { forwardRef } from 'react';
import Barcode from 'react-barcode';
import { toast } from 'react-toastify';

const BarcodeToPrint = forwardRef(({ barcodeData }, ref) => {

  
  return (
    <div
      ref={ref}
      style={{
        width: '50mm', /* 5cm */
        height: '25mm', /* 2.5cm */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // border: '1px solid black',
        /* Ensure no unwanted scaling or rotation */
        transform: 'none',
        overflow: 'hidden',
      }}
    >
      <Barcode width={1} height={40} value={barcodeData} />
    </div>
  );
});

export default BarcodeToPrint;
