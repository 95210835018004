import React, { useState } from 'react'
import { Header } from '../../components'
import BanksList from '../../components/Accounts/Bank/BanksList';
import { useAuthContext } from '../../hooks/useAuthContext';
import AddBank from './AddBank';

const Bank = () => {
  const { user } = useAuthContext();
  const [isOpenAddBank, setOpenAddBank] = useState(false);
  const openModal = () => {
    setOpenAddBank(true)

  }
  return (
    <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Banks" buttonName="New" openModal={openModal} />
      <BanksList user={user} />
      <AddBank user={user} isOpenAddBank={isOpenAddBank} setOpenAddBank={setOpenAddBank} />
    </div>
  )
}

export default Bank