import { createContext, useReducer } from "react";

export const RoleContext = createContext();

export const RoleReducer = (state, action) => {
    switch (action.type) {
        case "SET_ROLE":
            return {
                roles: action.payload
            }
        case "CREATE_ROLE":
            return {
                roles: [action.payload, ...state.roles]
            }
        case "DELETE_ROLE":
            return {
                roles: state.roles.filter((role) => role.id !== action.payload.id)
            }
        default:
            return state
    }
}

export const RoleContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(RoleReducer, {
        roles: null
    })
    return (
        <RoleContext.Provider value={{ ...state, dispatch }}>
            {children}
        </RoleContext.Provider>
    )
}