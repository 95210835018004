import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../axios';

const AddVendorModal = ({ isOpen, closeModal, onVendorsCreated }) => {
    const [title, setTitle] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!title) newErrors.title = 'Title is required';
        if (!businessName) newErrors.businessName = 'Business name is required';
        if (!address) newErrors.address = 'Address is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const vendorData = { 
            name: title,
            business_name: businessName,
            email,
            phone,
            address,
        };

        try {
            const response = await axiosClient.post('/expense/vendor', vendorData);
            if (response.status === 201) {
                onVendorsCreated();
                closeModal();
            }
        } catch (error) {
            toast.error('Failed to add vendor');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-lg">
                <h2 className="text-xl font-semibold mb-4">Add Vendor</h2>

                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Name</label>
                    <input
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        className={`border p-2 w-full ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Enter the vendor name"
                    />
                    {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Business Name</label>
                    <input
                        type="text"
                        value={businessName}
                        onChange={e => setBusinessName(e.target.value)}
                        className={`border p-2 w-full ${errors.businessName ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Enter the business name"
                    />
                    {errors.businessName && <p className="text-red-500 text-sm">{errors.businessName}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className={`border p-2 w-full ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="example@example.com"
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Phone</label>
                    <input
                        type="text"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        className={`border p-2 w-full ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="07054245880"
                    />
                    {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Address</label>
                    <textarea
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        className={`border p-2 w-full ${errors.address ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Enter the address"
                    />
                    {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
                </div>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={handleSubmit}
                        className={`bg-blue-500 text-white p-2 rounded hover:bg-blue-600 ${Object.keys(errors).length > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={Object.keys(errors).length > 0}
                    >
                        Submit
                    </button>
                    <button
                        onClick={closeModal}
                        className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddVendorModal;
