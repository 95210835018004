import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../../axios";
import Loading from "../../../components/Utils/Loading";
import MoneyFormat from "../../../components/Utils/MoneyFormat";
import DateFormat from "../../../components/Utils/DateFormat";
const WebsiteOrderDetails = () => {

    const params = useParams();
    const [order, setOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchWebsiteOrders = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data, status } = await axiosClient.get(`fh/website-order/${params.id}`);
            if (status == 200) {
                setOrder(data)
                setIsLoading(false);
            } else {
                toast.error("An error occurred while fetching website orders");
            }
        } catch (error) {
            toast.error("An error occurred while fetching website orders");
        }
    },
        [params])
    useEffect(() => {
        fetchWebsiteOrders();
    }, [])


    const validatePayment = async (e) => {
        e.preventDefault();

        const trxId = order?.transaction_id;

        if (order?.manual_validation === true) {
            return toast.error("Transaction Validated already");
        }

        if (!trxId) {
            return toast.error("Transaction ID does not exist.");
        }
        setIsLoading(true);
        try {
            const response = await axiosClient.post('fh/validate', { transaction_id: trxId });

            if (response.status === 200) {
                fetchWebsiteOrders();
                toast.success("Status updated");
                setIsLoading(false);
            }

        } catch (error) {

            toast.error("Failed to validate payment", error.message);
            setIsLoading(false);
        }
    };

    const sendPaymentReciept = async () => {
        await axiosClient.get(`/email/website-order/${order.id}`).then((response) => {
            if (response.status == 200) {
                toast.success('Email sent')
            }
        }).catch((err) => {
            console.error("ERRROR", err)
            toast.error("Email could not be sent")
        })
    }




    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Loading isLoading={isLoading} />
            {
                !isLoading && (
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <div className="text-xl font-bold mb-4">Order Details -
                            <span className='font-medium text-green-600 cursor-pointer'>
                                {order?.manual_validation == false ? (
                                    <span onClick={validatePayment}>VERIFY</span>
                                ) : (
                                    'VALIDATED'
                                )}
                            </span>

                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="md:w-1/2">
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Customer Name:</h2>
                                    <p className="font-semibold">{order?.customer_name}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Customer Email:</h2>
                                    <p className="font-semibold">{order?.customer_email}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Customer Phone:</h2>
                                    <p className="font-semibold">{order?.customer_phone}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Customer Address:</h2>
                                    <p className="font-semibold">{order?.customer_address}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Charged Amount:</h2>
                                    <p className="font-semibold"><MoneyFormat amount={order?.charged_amount} currency={order?.currency} /></p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Note</h2>
                                    <p className="font-semibold">{order?.note}</p>
                                </div>
                            </div>
                            <div className="md:w-1/2">
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Transaction:</h2>
                                    <p className="font-semibold">{order?.transaction_id}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Order Reference:</h2>
                                    <p className="font-semibold">{order?.reference}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Order Date:</h2>
                                    <p className="font-semibold"><DateFormat date={order?.createdAt} /></p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Delivery Date:</h2>
                                    <p className="font-semibold"><DateFormat date={order?.delivery_date} /></p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Status:</h2>
                                    <p className="font-semibold">{order?.status}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Currency:</h2>
                                    <p className="font-semibold">{order?.currency}</p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Shipping Fee:</h2>
                                    <p className="font-semibold"><MoneyFormat amount={order?.shipping_fee} currency={order?.currency} /></p>
                                </div>
                                <div className="mb-4">
                                    <h2 className="text-gray-600">Total:</h2>
                                    <p className="font-semibold"><MoneyFormat amount={order?.total} currency={order?.currency} /></p>
                                </div>
                                <div className="mb-4">
                                    <span className='font-medium text-green-600 cursor-pointer'>
                                        {order?.manual_validation == false ? (
                                            'PLEASE VALIDATE'
                                        ) : (
                                            <span onClick={sendPaymentReciept}>SEND RECIEPT</span>
                                        )}
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div className="mt-8">
                            <h2 className="text-xl font-bold mb-4">Web Orders</h2>
                            {order?.web_orders.map((webOrder) => (
                                <div
                                    key={webOrder?.id}
                                    className="bg-gray-100 rounded-md p-4 mb-4"
                                >
                                    <h3 className="text-lg font-semibold">
                                        Product Name: {webOrder?.product_name}
                                    </h3>
                                    <p className="text-gray-600">Product Code: {webOrder?.product_code}</p>
                                    <p className="text-gray-600">Amount: <MoneyFormat amount={webOrder?.amount} currency={order?.currency} /></p>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default WebsiteOrderDetails

