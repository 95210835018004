import React from 'react'
import { Header } from '../../components'
import PosPaymentList from '../../components/Payments/PosPaymentList'
import { ReactToPrint } from 'react-to-print';
import { ComponentToPrint } from '../../components/Utils/ComponentToPrint';
import { useRef } from 'react';

const PosPayments = () => {
   
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="POS Payments" />
            <PosPaymentList />

        </div>
    )
}

export default PosPayments