import React, { useEffect, useState } from 'react'
import axiosClient from '../../axios'
import { toast } from 'react-toastify';

const PaymentList = () => {
    const [payments, setPayments] = useState(null);
    const fetchPayments = async () => {
        await axiosClient.get("/payment").then((response) => {
            if (response.status == 200) {
                setPayments(response.data);
            }
        }).catch(() => {
            toast.error("could not fetch payments")
        })
    }

    useEffect(() => {
        fetchPayments();

    }, [])

    return (
        <div className="w-full overflow-hidden rounded-lg shadow-lg">
            <div className="w-full overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">Reference Number</th>
                            <th className="py-3 px-6 text-left">Amount</th>
                            <th className="py-3 px-6 text-left">Payment Account</th>
                            <th className="py-3 px-6 text-left">Payment Date</th>
                            <th className="py-3 px-6 text-left">Order</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        { payments && payments?.map((payment) => (
                            <tr
                                key={payment?.id}
                                className="border-b border-gray-200 hover:bg-gray-100"
                            >
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {payment?.reference_number}
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {payment?.amount}
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {payment?.paymentAccount?.bank_name}
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {payment?.payment_date}
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {`${payment?.order?.customer?.first_name} ${payment?.order?.customer?.last_name
                                        } - ${payment?.order?.reference_number}`}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PaymentList