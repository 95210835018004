
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../axios';
import { useRoleContext } from '../../hooks/useRoleContext';
import { useStaffContext } from '../../hooks/useStaffContext'
import Modal from '../Modal';

const AddStaff = ({ user, setOpenCreateStaff, openCreateStaff }) => {
    const { dispatch } = useStaffContext();
    const { roles, dispatch: setRoleDispatch } = useRoleContext();
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile_number, setMobileNumber] = useState("");
    const [role_id, setRole] = useState("");

    useEffect(() => {
        const listRoles = async () => {
            await axiosClient.get("/role").then((response) => {
                if (response.status === 200) {
                    setRoleDispatch({
                        type: "SET_ROLE",
                        payload: response.data
                    })
                }
            })
        }
        listRoles();
    }, [setRoleDispatch])
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            toast.error("you must log in");
            return;
        }

        const data = {
            "first_name": first_name,
            "last_name": last_name,
            "email": email,
            "mobile_number": mobile_number,
            "role_id":role_id ,
        }





        axiosClient.post("/user/add", data).then((response) => {
            if (response.status === 201) {
                setFirstName("");
                setLastName("");
                setEmail("");
                setMobileNumber(" ");
                setRole(" ");

                dispatch({
                    type: "CREATE_STAFF",
                    payload: response.data
                })
                toast.success("Staff created.")
                setOpenCreateStaff(false);
            }
        }).catch(() => {
            toast.error("something wrong")
        })
    }
    return (
        <>
            <Modal isOpen={openCreateStaff} close={() => setOpenCreateStaff(false)} modalhead="New Staff">
                <form onSubmit={handleSubmit}>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">First Name</label>
                                <input onChange={(e) => setFirstName(e.target.value)} value={first_name} type="text" name="first_name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Last Name</label>
                                <input onChange={(e) => setLastName(e.target.value)} value={last_name} type="text" name="last_name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                        </div>
                    </div>

                    {/*  */}
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Email</label>
                                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" name="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                        </div>
                    </div>
                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label htmlFor=" wareHouseName" className="text-sm font-medium text-gray-900 block mb-2">Mobile Number</label>
                                <input onChange={(e) => setMobileNumber(e.target.value)} value={mobile_number} type="text" name="mobile_number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required />
                            </div>
                        </div>
                    </div>

                    <div className="p-6 space-y-6">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 sm:col-span-12">
                                <label>ROLE</label>
                                <select
                                    name="role"
                                    value={role_id} onChange={(e) => setRole(e.target.value)}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                                    aria-describedby="" placeholder="Product Image Link" >
                                    <option disabled>SELECT A ROLE</option>
                                    {
                                        roles?.map((role) => (
                                            <option key={role?.id} value={role?.id}>{role?.title}</option>
                                        ))
                                    }
                                </select>

                            </div>
                        </div>
                    </div>
                    <div className="items-center p-6 border-t border-gray-200 rounded-b">
                        <button className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center" type="submit">Save</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddStaff