import { Header } from "../../../components"
import ListOfWebsiteOrders from "../../../components/WebsiteOrders/ListOfWebsiteOrders"


const WebsiteOrders = () => {
    return (
        <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
            <Header title="Website Orders" buttonName=" " />
            <ListOfWebsiteOrders />
        </div>
    )
}

export default WebsiteOrders