import React, { useState } from 'react'
import { Header } from '../../components'
import ExpenseCategoryList from '../../components/ExpenseCategory/ExpenseCategoryList'

const ExpenseCategory = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    return (
        <div>



            <div className=' m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
                <Header title="Expense Category" buttonName="Add Expense Category" openModal={openModal} />
            
           

                    <ExpenseCategoryList  isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />


            </div>
        </div>
    )
}

export default ExpenseCategory