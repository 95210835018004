import { useRef } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../../axios";
import Modal from '../../Modal'

const EditOrderCategory = ({ modalContent, setOpen, isOpen }) => {
  const refName = useRef(null);
  const refCode = useRef(null);

  const handleSubmit = async (e, order_category_id) => {
    e.preventDefault();
    const data = {
      "name": refName.current.value,
      "code": refCode.current.value,
    }

    await axiosClient.put(`order-category/${order_category_id}`, data).then(
      async (response) => {
        if (response.status === 200) {
          setOpen(false);
          toast.success("Order category updted")
        }
      }
    ).catch((error) => {
      toast.error("Order Category could not be updated");
    })
  }
  return (
    <>
      <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead={modalContent?.name}>
        <form onSubmit={e => handleSubmit(e, modalContent?.id)} >
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="first-name" className="text-sm font-medium text-gray-900 block mb-2">First Name</label>
              <input type="text" name="first_name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refName} defaultValue={modalContent?.name} />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="last_name" className="text-sm font-medium text-gray-900 block mb-2">Last Name</label>
              <input type="text" name="last_name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5" required ref={refCode} defaultValue={modalContent?.code} />
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default EditOrderCategory