import { useContext } from "react"
import { BanksContext } from "../contexts/Accounts/BanksContext";


export const useBanksContext = () => {
    const context = useContext(BanksContext);

    if (!context) {
        throw Error("useBanksContext must be used inside a BanksContextProvider")
    }

    return context
}